/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import _ from 'lodash';
import React, { useState, useEffect } from "react";
import { Flex, View, Text, Grid,  Link, Image } from '@aws-amplify/ui-react';
import { MdPhone, MdEmail } from 'react-icons/md';
import { useWindowDimensions } from "react-native";

export default function Footer(props) {

    const { height, width } = useWindowDimensions();
    const [isMobile, setIsMobile] = useState(() => {
        if(width < 768) return true
        else return false;
    });
    useEffect(() => {
        if(width < 768) setIsMobile(true)
        else return setIsMobile(false);
    },[width]);

    return (
        <View backgroundColor={_.get(process.env, 'REACT_APP_COLOR_FOOTER', '')} >
            <Grid
                justifyContent={'center'}
                minHeight={height / 3.1}
                paddingTop={60}
                //paddingBottom={30}
                paddingLeft={170}
                paddingRight={170}                
                width={width}
                templateColumns={{ base: '1fr', large: '2fr 2fr 2fr 1fr'}}
                templateRows={{ large: '2rem'}}
                gap="var(--amplify-space-small)">
                    <View>
                        <Flex direction={'column'}>
                            <Text
                                fontFamily="Inter-Regular"
                                fontSize={isMobile ? '17px' : '17px'}
                                fontWeight="700"                                    
                                color="white"
                                left={30}
                                marginBottom={0}
                                children={`Contact Info`} />
                            <Text
                                fontFamily="Inter-Regular"
                                fontSize={isMobile ? '17px' : '17px'}
                                fontWeight="500"                                    
                                color="white"
                                left={30}
                                lineHeight="10px"
                                children={_.get(process.env, 'REACT_APP_CLIENT_ADDRESS_1', '')} />
                            <Text
                                fontFamily="Inter-Regular"
                                fontSize={isMobile ? '17px' : '17px'}
                                fontWeight="500"                                    
                                color="white"
                                left={30}
                                lineHeight="10px"
                                children={`${_.get(process.env, 'REACT_APP_CLIENT_ADDRESS_CITY', '')} ${_.get(process.env, 'REACT_APP_CLIENT_ADDRESS_STATE', '')} ${_.get(process.env, 'REACT_APP_CLIENT_ADDRESS_ZIP', '')}`} />
                            <Flex direction={'row'} alignItems={'center'}>
                                <MdPhone color='white' size={15} />
                                <Link
                                    target={'_blank'}
                                    href={`tel:${_.get(process.env, 'REACT_APP_CLIENT_PHONE', '').replace('(', '').replace(')', '-')}`}
                                    fontFamily="Inter-Regular"
                                    fontSize={isMobile ? '17px' : '17px'}
                                    fontWeight="500"                                    
                                    color="white"
                                    left={30}
                                    lineHeight="10px"
                                    children={_.get(process.env, 'REACT_APP_CLIENT_PHONE', '')} />
                            </Flex>
                            <Flex direction={'row'} alignItems={'center'}>
                                <MdEmail color='white' size={15} />
                                <Link
                                    target={'_blank'}
                                    href={`mailto:${_.get(process.env, 'REACT_APP_CLIENT_EMAIL_SVC', '')}`}
                                    fontFamily="Inter-Regular"
                                    fontSize={isMobile ? '17px' : '17px'}
                                    fontWeight="500"                                    
                                    color="white"
                                    left={30}
                                    lineHeight="10px"
                                    children={_.get(process.env, 'REACT_APP_CLIENT_EMAIL_SVC', '')} />
                            </Flex>
                        </Flex>
                    </View>
                    {/* <View>
                        <Flex direction={'column'}>
                            <Text
                                fontFamily="Inter-Regular"
                                fontSize={isMobile ? '17px' : '17px'}
                                fontWeight="700"                                    
                                color="white"
                                left={30}
                                marginBottom={0}
                                children={`Site Links`} />
                            <Link
                                href={_.get(process.env, 'REACT_APP_CLIENT_URL', '')}
                                fontFamily="Inter-Regular"
                                fontSize={isMobile ? '17px' : '17px'}
                                fontWeight="500"                                    
                                color="white"
                                left={30}
                                lineHeight="10px"
                                children={`Home`} 
                            />
                        </Flex>
                    </View> */}
                    <View>
                        <Link href={_.get(process.env, 'REACT_APP_CLIENT_URL', '')}>
                            <Image width={300} src={_.get(process.env, 'REACT_APP_CLIENT_LOGO', '')} />
                        </Link>
                    </View>

            </Grid>
        </View>
    );
}

import _ from 'lodash';
import axios from 'axios';
import { getUserEmail } from './user';

const restAPI = axios.create({
  baseURL: _.get(process.env, 'REACT_APP_REST_URL'),
  headers: { 'x-api-key': _.get(process.env, 'REACT_APP_REST_KEY')}
});

const EMAIL_URL = _.get(process.env, 'EMAIL_URL','https://xc673bghr6.execute-api.us-east-2.amazonaws.com/dev/email');
const EMAIL_API_KEY = _.get(process.env, 'EMAIL_API_KEY','7a60eeef48764a1880557499c4ffeef8');

export const customerExists = async (email) => {

  const customerExistsResponse = await restAPI.get('/customers', { params: { email }});

  const customers = _.get(customerExistsResponse, 'data.customers');

  const customer = _.first(customers);

  console.log({ customer})

  let customerExists = false;

  if(customer && (customer.email === email)) customerExists = true;

  console.log({ customerExists });
  
  return customerExists;
};

export const loadCustomer = async (setCustomer) => {
  const email = await getUserEmail();

  const customersByEmailResponse = await restAPI.get('/customers', { params: { email }});

  const customers = _.get(customersByEmailResponse, 'data.customers');

  const customer = _.first(customers);

  console.log({ customer });
  
  const callback = _.isFunction(setCustomer) ? setCustomer : (customer) => customer;

  return callback(customer);
};

export const loadCustomerById = async (customerId, setCustomer) => {
  const customerByIdResponse = await restAPI.get(`/customers/${customerId}`);

  const customer = _.get(customerByIdResponse, 'data.getCustomer');

  console.log({ customer });
  
  const callback = _.isFunction(setCustomer) ? setCustomer : (customer) => customer;

  return callback(customer);
};

export const loadInvoiceTransactions = async (externalId, setTransactions) => {

  const transactionsResponse = await restAPI.get('/transactions', { params: { externalId }});

  const transactions = _.get(transactionsResponse, 'data.getTransactionsByExternalId', []);

  console.log({ transactions });
  
  return transactions;
};

export const loadSubscriptions = async (customer, setSubscriptions) => {
  const customerId = _.get(customer, 'id');

  const subscriptionsResponse = await restAPI.get('/subscriptions', { params: { customerId }});

  console.log({ subscriptionsResponse });

  const subscriptions = _.get(subscriptionsResponse, 'data.getSubscriptionsByCustomerId');

  console.log({ subscriptions });

  const callback = _.isFunction(setSubscriptions) ? setSubscriptions : (subscriptions) => subscriptions;

  return callback(subscriptions);
};

export const loadProducts = async (setProducts) => {
  console.log( 'Loading Products ...')
  const productsResponse = await restAPI.get('/products');

  console.log({ productsResponse });

  const allProducts = _.get(productsResponse, 'data.products');

  console.log({allProducts})

  const products = _.filter(allProducts, { type: 'base', status: 'active' });

  console.log( 'Products Loaded', products );

  const callback = _.isFunction(setProducts) ? setProducts : (products) => products;

  return callback(products);
};

export const loadProduct = async (productId, setProduct) => {
  console.log( 'Loading Product ...')
  
  const productResponse = await restAPI.get(`/products/${productId}`);

  console.log({ productResponse });

  const product = _.get(productResponse, 'data.getProduct');

  console.log({ product });

  const callback = _.isFunction(setProduct) ? setProduct : (product) => product;

  return callback(product);
};

export const createCustomer = async (customer, setCustomer) => {
  const created = await restAPI.post(`/customers`, { input: customer });

  const createdCustomer = _.get(created, 'data.createCustomer');

  console.log({ createdCustomer });

  const options = {
    headers: { 'x-api-key': EMAIL_API_KEY },
  };
  const params = {
      subject: 'Welcome User Email',
      type: 'html',
      data: 'Welcome User Email Body',
      toEmail: _.get(createdCustomer, 'email'),
      fromEmail: 'no-reply@rebartechnology.io'
  };
  const response = await axios.post(EMAIL_URL, params, options);

  console.log(response);

  const callback = _.isFunction(setCustomer) ? setCustomer : (customerData) => customerData;

  return callback(createdCustomer);
};

export const updateCustomer = async (customer, setCustomer) => {
  const customerId = _.get(customer, 'id');

  const updated = await restAPI.post(`/customers/${customerId}`, { input: customer });

  const updatedCustomer = _.get(updated, 'data.editCustomer');

  console.log({ updatedCustomer });

  const callback = _.isFunction(setCustomer) ? setCustomer : (customer) => customer;

  return callback(updatedCustomer);
};

export const loadCustomerInvoice = async ({ customerId, invoiceId }, setInvoice ) => {
  const invoiceResponse = await restAPI.get(`/customers/${customerId}/invoices/${invoiceId}`);
  
  console.log({ invoiceResponse });

  const invoice = _.get(invoiceResponse, 'data');

  console.log({ invoice });

  const callback = _.isFunction(setInvoice) ? setInvoice : (invoice) => invoice;

  return callback(invoice);
};

export const payInvoice = async (invoiceId, input) => {
  const invoiceResponse = await restAPI.post(`/invoices/${invoiceId}/pay`, { input: input});
  
  console.log({ invoiceResponse });

  const invoice = _.get(invoiceResponse, 'data');

  console.log({ invoice });

  return invoice
};

export const loadCustomerInvoices = async (customerId, setInvoices) => {
  const invoicesResponse = await restAPI.get(`/invoices?customerId=${customerId}`);
  
  console.log({ invoicesResponse });

  const invoices = _.get(invoicesResponse, 'data.getInvoiceByCustomerId');

  console.log({ invoices });

  const callback = _.isFunction(setInvoices) ? setInvoices : (invoices) => invoices;

  return callback(invoices);
};

export const loadCustomerPaymentMethods = async (customerId, setPaymentMethods) => {
  const paymentMethodsResponse = await restAPI.get(`/customers/${customerId}/paymentMethods`);

  console.log({ paymentMethodsResponse });

  const paymentMethods = _.get(paymentMethodsResponse, 'data.getPaymentMethods', []);

  // TODO filter payment methods by status (or whatever) here

  console.log({ paymentMethods });

  const callback = _.isFunction(setPaymentMethods) ? setPaymentMethods : (paymentMethods) => paymentMethods;

  return callback(paymentMethods);
};

export const loadCustomerPaymentMethod = async (customerId, paymentMethodId, setPaymentMethod) => {
  const paymentMethodResponse = await restAPI.get(`/customers/${customerId}/paymentMethods/${paymentMethodId}`);

  console.log({ paymentMethodResponse });

  const paymentMethod = _.get(paymentMethodResponse, 'data', {});

  // TODO filter payment methods by status (or whatever) here

  console.log({ paymentMethod });

  const callback = _.isFunction(setPaymentMethod) ? setPaymentMethod : (paymentMethod) => paymentMethod;

  return callback(paymentMethod);
};

export const createDDAPaymentMethod = async (paymentMethod, setPaymentMethod) => {
  const input = {
    // issuer_id: String TODO REMOVE THIS -> NOT USED FOR DDA
    // expiration: String TODO REMOVE THIS -> NOT USED FOR DDA
    last: _.get(paymentMethod, 'last'),
    token: _.get(paymentMethod, 'token'),
    brand: _.get(paymentMethod, 'brand'),
    category: _.get(paymentMethod, 'category'),
    type: _.toUpper(_.get(paymentMethod, 'type')),
    customerId: _.get(paymentMethod, 'customerId'),
    primary: _.get(paymentMethod, 'primary', false),
    routingTransitNumber: _.get(paymentMethod, 'routing'),
    subscriptionId:  _.get(paymentMethod, 'subscriptionId'),
    accountHolderLastName: _.get(paymentMethod, 'billingDetails.accountHolder.lastName'),
    accountHolderFirstName: _.get(paymentMethod, 'billingDetails.accountHolder.firstName')
  };

  const created = await restAPI.post(`/paymentMethodsDDA`, { input });

  console.log({ createPaymentMethodResponse: created });

  const createdPaymentMethod = _.get(created, 'data', {});

  console.log({ createdPaymentMethod });

  const callback = _.isFunction(setPaymentMethod) ? setPaymentMethod : (paymentMethod) => paymentMethod;

  return callback(createdPaymentMethod);
};

export const createPaymentMethod = async (paymentMethod, setPaymentMethod) => {
  if( _.toUpper(_.get(paymentMethod, 'category')) === 'DDA') return createDDAPaymentMethod(paymentMethod, setPaymentMethod);
  
  const created = await restAPI.post(`/paymentMethods`, { input: paymentMethod });

  console.log({ createPaymentMethodResponse: created });

  const createdPaymentMethod = _.get(created, 'data', {});

  console.log({ createdPaymentMethod });

  const callback = _.isFunction(setPaymentMethod) ? setPaymentMethod : (paymentMethod) => paymentMethod;

  return callback(createdPaymentMethod);
};

export const updateCustomerPaymentMethod = async (paymentMethod, setPaymentMethod) => {
  const updated = await restAPI.post(`/paymentMethods/boom`, { input: paymentMethod });

  console.log({ updatePaymentMethodResponse: updated });

  const updatedPaymentMethod = _.first(_.get(updated, 'data.updatePaymentMethodDetails', []));

  console.log({ updatedPaymentMethod });

  const callback = _.isFunction(setPaymentMethod) ? setPaymentMethod : (paymentMethod) => paymentMethod;

  return callback(updatedPaymentMethod);
};

export const createSubscription = async (subscriptionPayload) => {
  console.log({ subscriptionCreateRequest: subscriptionPayload });
  
  const subscriptionCreatedResponse = await restAPI.post(`/subscriptions`, { input: subscriptionPayload });

  console.log({ subscriptionCreatedResponse });

  const subscription = _.get(subscriptionCreatedResponse, 'data');

  console.log({ subscription });

  const options = {
    headers: { 'x-api-key': EMAIL_API_KEY },
  };
  const params = {
      subject: 'Welcome Email Subscription',
      type: 'html',
      data: 'Welcome Email Body Subscription',
      toEmail: _.get(subscriptionPayload, 'customer.email'),
      fromEmail: 'no-reply@rebartechnology.io'
  };
  const response = await axios.post(EMAIL_URL, params, options);

  console.log(response);

  return subscription;
};

export const cancelCustomerSubscription = async (subscriptionId) => {
  console.log({ cancelCustomerSubscriptionRequest: subscriptionId });
  
  const cancelCustomerSubscriptionResponse = await restAPI.post(`/subscriptions/${subscriptionId}/cancel`);

  console.log({ cancelCustomerSubscriptionResponse });

  const canceledSubscription = _.get(cancelCustomerSubscriptionResponse, 'data.cancelSubscription');

  console.log({ canceledSubscription });

  return canceledSubscription;
};


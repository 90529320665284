/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import _ from 'lodash'
import Loader from 'react-loading'
import { useEffect, useState } from "react";
import { Flex, Text, Image } from "@aws-amplify/ui-react";

import { Colors } from '../../../constants';
import { loadVaultTokenDetails } from "../../../utils/vault";
import { loadCustomerPaymentMethod } from '../../../utils/rest';

import DDALogo from '../../../assets/images/payment-icons/dda.svg';
import AmexLogo from '../../../assets/images/payment-icons/amex.svg';
import VisaLogo from '../../../assets/images/payment-icons/visa.svg';
import DefaultLogo from '../../../assets/images/payment-icons/default.svg';
import DiscoverLogo from '../../../assets/images/payment-icons/discover.svg';
import MastercardLogo from '../../../assets/images/payment-icons/mastercard.svg';

export default function PaymentType(props) {
  const { customerId, paymentMethodId, pm } = props;
  const [paymentMethod, setPaymentMethod] = useState(pm || {})

  const [tokenData, setTokenData] = useState({})

  const getLogo = (paymentMethod) => {
    console.log('getLogo - Input:', { paymentMethod });
    
    const brand = _.toLower(_.get(paymentMethod, 'brand'));
    
    if (_.isUndefined(brand)) return DefaultLogo;

    console.log('getLogo - "brand":', { paymentMethod, brand });
    
    switch (brand) {
      case 'amex':
        return AmexLogo;
      case 'discover':
        return DiscoverLogo;
      case 'mastercard':
        return MastercardLogo;
      case 'visa':
        return VisaLogo;
      case 'electroniccheck':
        return DDALogo;
      default:
        return DefaultLogo;
    }
  };

  const getScheme = (paymentMethod) => _.toLower(_.get(paymentMethod, 'scheme', _.get(paymentMethod, 'category')));

  useEffect(() => {
    if (!paymentMethod.id) loadCustomerPaymentMethod(customerId, paymentMethodId, setPaymentMethod)
  }, [customerId, paymentMethodId])

  useEffect(() => {
    if (paymentMethod.id) loadVaultTokenDetails(paymentMethod.token, setTokenData);
  }, [paymentMethod])

  return (
    <Flex direction="row" gap={20} width={'100%'}>
      {!paymentMethod.id || !tokenData.token 
        ? <Loader type='bubbles' color={Colors.primary} width={25} height={25} />
        : <>
            <Flex direction={'row'} alignItems='center'>
              <Image src={getLogo(paymentMethod)} width={50} />
              <Text children={`***${tokenData.last}`} />
            </Flex>
            
            {getScheme(paymentMethod) === 'credit' && 
              <Flex gap={5} direction={'row'} alignItems='center'>
                <Text fontWeight={800} children={`Expiration`} />
                <Text children={_.get(tokenData, 'expiration', '').substr(0, 2) + '/' + _.get(tokenData, 'expiration', '').substr(2, 2)} />
              </Flex>
            }

            {getScheme(paymentMethod) === 'dda' && 
              <Flex gap={5} direction={'row'} alignItems='center'>
                <Text fontWeight={800} children={`Routing:`} />
                <Text children={_.get(paymentMethod, 'routingTransitNumber', _.get(paymentMethod, 'routing', ''))} />
              </Flex>
            }
        </>
      }

    </Flex>
  );
}

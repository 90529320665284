import _ from 'lodash';

export const Colors = {
  black: '#000',
  primary: _.get(process.env, 'REACT_APP_COLOR_PRIMARY', '#EE3139'),
  secondary: _.get(process.env, 'REACT_APP_COLOR_SECONDARY', '#2E3138'),
  secondaryHover: _.get(process.env, 'REACT_APP_COLOR_SECONDARY_HOVER', '#2E3138'),
  container: _.get(process.env, 'REACT_APP_COLOR_CONTAINER', '#f7f7f7'),
  background: _.get(process.env, 'REACT_APP_COLOR_BACKGROUND', '#E5E5E5'),
  header: _.get(process.env, 'REACT_APP_COLOR_HEADER', '#EE3139'),
  headerText: _.get(process.env, 'REACT_APP_COLOR_HEADER_TEXT', '#000000'),
  footer: _.get(process.env, 'REACT_APP_COLOR_FOOTER', '#2E3138'),
  footerText: _.get(process.env, 'REACT_APP_COLOR_FOOTER_TEXT', '#FFFFFF'),
  loader: _.get(process.env, 'REACT_APP_COLOR_LOADER', '#2E3138'),
  active: _.get(process.env, 'REACT_APP_COLOR_ACTIVE', '#EE3139'),
  activeText: _.get(process.env, 'REACT_APP_COLOR_ACTIVE_TEXT', '#2E3138')
}

export default Colors;
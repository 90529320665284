import _ from 'lodash';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from '../aws-exports';

Amplify.configure(awsconfig);

export const getUser = async () => {
  try{
    const currentSession = await Auth.currentSession();

    return _.get(currentSession, 'idToken.payload');
  } catch(error){
    console.log({ message: 'Authentication Error', error });
  }
};

export const getUserEmail = async () => {
  const user = await getUser();

  return _.get(user, 'email');
};
import _ from 'lodash';
import * as dayjs from 'dayjs';
import Loader from 'react-loading';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Modal, useWindowDimensions } from 'react-native';
import {
  useAuthenticator,
  Flex,
  View,
  Text,
  Button,
  SelectField
} from '@aws-amplify/ui-react';

import Footer from '../../components/Footer';
import Error404Screen from '../ErrorScreen/404';
import { Colors, Images, Strings, ScreenNames } from '../../constants';
import PaymentType from '../../components/Modals/CreditCard/PaymentType';
import {
  loadCustomer,
  loadSubscriptions,
  cancelCustomerSubscription
} from '../../utils/rest';
import {
  AppNavBar,
  SideBar,
  BlankOverviewBox,
  SubscriptionLineItemInset,
  ChevronDown,
  ChevronUp
} from '../../ui-components';

export default function SubsciptionsScreen() {
  const navigate = useNavigate();

  const { height, width } = useWindowDimensions();
  const [isMobile, setIsMobile] = useState(() => {
    if (width < 768) return true;
    else return false;
  });
  useEffect(() => {
    if (width < 768) setIsMobile(true);
    else return setIsMobile(false);
  }, [width]);

  const [loading, setLoading] = useState(true);
  const { user, signOut } = useAuthenticator((context) => [context.user]);

  const [modalView, setModalView] = useState(false);
  const [cancelSubscriptionVisible, setCancelSubscriptionVisible] =
    useState(false);
  const [showSubcription, setShowSubscription] = useState('');
  const [customer, setCustomer] = useState({});

  const [subscriptionStatus, setSubscriptionStatus] = useState('active');
  const [subscriptionId, setSubscriptionId] = useState('');

  const containerWidth = isMobile ? width / 1.1 : width / 1.2;

  const toggleSubscriptionItem = (item) => {
    if (item.id === showSubcription) setShowSubscription('');
    else setShowSubscription(item.id);
  };

  //SUBSCRIPTIONS
  const [subscriptions, setSubscriptions] = useState([]);

  const cancelSubscription = (item) => {
    setSubscriptionId(item.id);
    setModalView(true);
    setCancelSubscriptionVisible(true);
  };

  const [subscriptionFormLoading, setSubscriptionFormLoading] = useState(false);
  const cancelSubscriptionSubmit = async () => {
    //Submit form
    setSubscriptionFormLoading(true);

    //API
    try {
      cancelCustomerSubscription(subscriptionId);
      setSubscriptions(() => {
        return _.filter(subscriptions, (i) => i.id === subscriptionId);
      });
      setCancelSubscriptionVisible(false);
      setModalView(false);
      setSubscriptionFormLoading(false);
      successToast();
    } catch (e) {
      setSubscriptionFormLoading(false);
      errorToast();
    }
  };

  const cancelSubscriptionForm = () => {
    return (
      <View alignSelf="center" width={'90%'}>
        <Flex direction={'column'}>
          <Text
            fontFamily="Inter"
            fontSize="32px"
            fontWeight="700"
            color="rgba(13,26,38,1)"
            lineHeight="40px"
            children={Strings.CANCEL_SUBSCRIPTION}
          />

          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            lineHeight="24px"
            letterSpacing="0.01px"
            children={Strings.CANCEL_SUBSCRIPTION_SUBTITLE}
          />

          <Flex
            direction={'row'}
            marginTop={35}
            justifyContent="flex-end"
            width={'100%'}
          >
            <Button
              variation="link"
              onClick={() => {
                setCancelSubscriptionVisible(false);
                setModalView(false);
              }}
            >
              {Strings.CLOSE}
            </Button>
            <Button
              variation="primary"
              onClick={() => cancelSubscriptionSubmit()}
              isLoading={subscriptionFormLoading}
            >
              {Strings.CONFIRM}
            </Button>
          </Flex>
        </Flex>
      </View>
    );
  };

  const condenseProducts = (items) => {
    // console.log('condenseProducts')

    var newArray = [];
    _.map(items, (item) => {
      const index = _.findIndex(newArray, (i) => i.id === item.id);
      if (index === -1) newArray.push({ id: item.id, products: [item] });
      else newArray[index].products.push(item);
    });

    return newArray.map((i) => {
      return (
        <SubscriptionLineItemInset
          key={i.id}
          overrides={{
            SubscriptionLineItemInset: { width: '100%', marginBottom: 15 },
            Title: { children: i.products[0].description },
            Description: {
              children: `Qty. x${_.size(i.products)}`,
              width: '100%',
              marginLeft: 2
            },
            Amount: {
              children: `$${i.products[0].amount} / ${i.products[0].frequency}`,
              right: 0,
              textAlign: 'right'
            }
          }}
        />
      );
    });
  };

  const renderSubscriptionItem = (idx, item) => {
    let title = _.get(item, 'products.0.description', null);
    console.log(item.metadata);
    if (_.size(item?.metadata) > 0) {
      const service_place = _.find(
        item.metadata,
        (i) => i.key === 'service_place'
      );
      const service_address = _.find(
        item.metadata,
        (i) => i.key === 'service_address'
      );
      const service_address2 = _.find(
        item.metadata,
        (i) => i.key === 'service_address2'
      );
      const service_locality = _.find(
        item.metadata,
        (i) => i.key === 'service_locality'
      );
      const service_region = _.find(
        item.metadata,
        (i) => i.key === 'service_region'
      );
      const service_postal = _.find(
        item.metadata,
        (i) => i.key === 'service_postal'
      );

      if (service_place) title = service_place.value;
      else {
        if (service_address2)
          title = `${service_address.value} ${service_address2.value}, ${service_locality.value}, ${service_region.value}, ${service_postal.value}`;
        else
          title = `${service_address?.value}, ${service_locality?.value}, ${service_region?.value}, ${service_postal?.value}`;
      }
    }

    let lastItem = false;
    if (idx === _.size(subscriptions) - 1) lastItem = true;
    return (
      <>
        <BlankOverviewBox
          key={item.id}
          overrides={{
            BlankOverviewBox: {
              width: '100%',
              borderRadius: lastItem ? '0px 0px 5px 5px' : '0px 0px 0px 0px',
              marginTop: 0,
              backgroundColor: Colors.container
            }
          }}
        >
          <Flex
            direction={'column'}
            marginLeft={40}
            marginRight={40}
            width={'100%'}
          >
            <Flex
              padding={'15px 0px 15px 0px'}
              direction={'row'}
              height={10}
              width={'100%'}
              alignItems="center"
              onClick={() => toggleSubscriptionItem(item)}
              style={{ cursor: 'pointer' }}
            >
              <Text
                fontFamily="Inter"
                fontSize="15px"
                fontWeight="600"
                lineHeight="20px"
                letterSpacing="1px"
                children={title}
              ></Text>

              {showSubcription === item.id ? (
                <ChevronUp
                  overrides={{
                    ChevronUp: {
                      position: 'absolute',
                      right: 30,
                      marginTop: 15
                    }
                  }}
                />
              ) : (
                <ChevronDown
                  overrides={{
                    ChevronDown: {
                      position: 'absolute',
                      right: 30,
                      marginTop: 15
                    }
                  }}
                />
              )}
            </Flex>

            {showSubcription === item.id && (
              <Text
                fontFamily="Inter"
                color={item?.nextBill?.date ? 'green' : 'gray'}
                fontStyle={!item?.nextBill?.date && 'italic'}
                fontSize="14px"
                fontWeight="700"
                lineHeight="18px"
                letterSpacing="0.41px"
                marginTop={-15}
                children={
                  item?.nextBill?.date && _.isEqual(item.status, 'active')
                    ? _.capitalize(item.status)
                    : 'Set to cancel next period'
                }
                width={'100%'}
              ></Text>
            )}

            {showSubcription === item.id && (
              <>
                <Flex
                  direction={isMobile ? 'column' : 'row'}
                  justifyContent={'space-between'}
                  rowSpan={3}
                  marginTop={15}
                >
                  <View>
                    <Text
                      fontFamily="Inter"
                      fontSize="16px"
                      fontWeight="700"
                      lineHeight="20px"
                      children={Strings.BILLING_FREQUENCY}
                    />
                    <Text
                      fontFamily="Inter"
                      fontSize="14px"
                      fontWeight="400"
                      lineHeight="24px"
                      children={_.capitalize(item.frequency)}
                      marginTop={10}
                    />
                  </View>
                  <View>
                    <Text
                      fontFamily="Inter"
                      fontSize="16px"
                      fontWeight="700"
                      lineHeight="20px"
                      marginBottom={10}
                      children={Strings.PAYMENT_METHOD}
                    ></Text>
                    <PaymentType
                      customerId={customer.id}
                      paymentMethodId={item.paymentMethodIds[0]}
                    />
                  </View>
                  <View>
                    <Text
                      fontFamily="Inter"
                      fontSize="16px"
                      fontWeight="700"
                      lineHeight="20px"
                      children={'Next Bill'}
                    ></Text>
                    <Text
                      fontFamily="Inter"
                      fontSize="14px"
                      fontWeight="400"
                      lineHeight="24px"
                      fontStyle={!item?.nextBill?.date && 'italic'}
                      children={
                        item?.nextBill?.date
                          ? dayjs(item.nextBill.date).format('MM/DD/YYYY')
                          : 'Cancelled'
                      }
                      marginTop={10}
                    />
                  </View>
                  <View>
                    <Text
                      fontFamily="Inter"
                      fontSize="18px"
                      fontWeight="700"
                      lineHeight="20px"
                      children={`${item.currency.symbol}${item.total}`}
                    />
                  </View>
                </Flex>
                <View
                  backgroundColor={'#e5e5e5'}
                  height={1}
                  opacity={0.5}
                  marginTop={20}
                  marginBottom={20}
                />
                {showSubcription === item.id && (
                  <Flex
                    direction={'column'}
                    width={'100%'}
                    alignItems={'flex-end'}
                  >
                    {condenseProducts(item.products)}
                  </Flex>
                )}

                {item?.nextBill?.date && (
                  <>
                    <View
                      backgroundColor={'#e5e5e5'}
                      height={1}
                      opacity={0.5}
                      marginTop={10}
                    />
                    <Text
                      fontFamily="Inter"
                      color={Colors.activeText}
                      fontSize="14px"
                      fontWeight="700"
                      lineHeight="18px"
                      letterSpacing="0.41px"
                      style={{ cursor: 'pointer' }}
                      children={Strings.CANCEL_SUBSCRIPTION}
                      width={'100%'}
                      marginTop={10}
                      onClick={() => cancelSubscription(item)}
                    ></Text>
                  </>
                )}
              </>
            )}
          </Flex>
        </BlankOverviewBox>
      </>
    );
  };

  //ALERTS
  const successToast = () =>
    toast.success(Strings.SUCCESSFULLY_UPDATED, {
      position: 'top-center',
      theme: 'colored'
    });
  const errorToast = () =>
    toast.error(Strings.ERROR_OCCURED, {
      position: 'top-center',
      theme: 'colored'
    });

  //ROUTES
  const goToOverview = () => navigate(ScreenNames.OVERVIEW);
  const gotToSubscriptions = () => navigate(ScreenNames.SUBSCRIPTIONS);
  const goToPaymentMethods = () => navigate(ScreenNames.PAYMENT_METHODS);
  const goToBillingHistory = () => navigate(ScreenNames.BILLING_HISTORY);
  const goToSubscribe = () => navigate(`${ScreenNames.SUBSCRIBE}`);

  useEffect(() => {
    document.title = Strings.SUBSCRIPTIONS;
  }, []);

  useEffect(() => {
    setLoading(true);
    loadCustomer(setCustomer);
  }, [user]);

  useEffect(() => {
    if (!_.has(customer, 'id')) return;

    async function loadData() {
      await loadSubscriptions(customer, setSubscriptions);
      setLoading(false);
    }

    loadData();
  }, [customer]);

  const reloadSubscriptions = async (status) => {
    setLoading(true);
    await loadSubscriptions(customer, (subscriptions) => {
      setSubscriptions(subscriptions);
      if (status === 'active') {
        setSubscriptions(() => {
          return _.filter(subscriptions, (i) => i.status === 'active');
        });
      }
      if (status === 'cancelled') {
        setSubscriptions(() => {
          return _.filter(subscriptions, (i) => i.status === 'cancelled');
        });
      }
      setLoading(false);
    });
  };

  if (_.get(process.env, 'REACT_APP_ALLOW_SIGN_IN') === 'false')
    return <Error404Screen />;

  return (
    <Flex
      direction="column"
      alignItems={'center'}
      height={modalView ? '100vh' : null}
      minHeight="100vh"
      backgroundColor={Colors.surface}
      width={'100%'}
      overflow={'hidden'}
    >
      <AppNavBar
        overrides={{
          AppNavBar: { width: '100%', backgroundColor: Colors.header },
          user: {
            fontFamily: 'Inter-Regular',
            color: Colors.headerText,
            children: 'Sign out',
            onClick: signOut,
            style: { cursor: 'pointer' }
          },
          Logo30873174: { src: process.env.REACT_APP_LOGO_URL },
          image: { src: Images.USER_PLACEHOLDER, style: { display: 'none' } }
        }}
      />

      <Flex
        direction={isMobile ? 'column' : 'row'}
        justifyContent={'space-evenly'}
        flex={1}
        width={containerWidth}
        marginTop={isMobile ? 10 : 25}
        marginBottom={30}
      >
        <Flex direction={'column'} width={isMobile ? '100%' : 313}>
          <SideBar
            overrides={{
              SideBar: { width: '100%', backgroundColor: Colors.container },
              Overview: {},
              OverviewFrame: {
                onClick: goToOverview,
                style: { cursor: 'pointer' }
              },
              Subscriptions: { fontWeight: '600', color: Colors.activeText },
              SubscriptionsFrame: {
                onClick: gotToSubscriptions,
                style: { cursor: 'pointer' }
              },
              'Payment Methods': {},
              PaymentMethodsFrame: {
                onClick: goToPaymentMethods,
                style: { cursor: 'pointer' }
              },
              'Billing History': {},
              BillingHistoryFrame: {
                onClick: goToBillingHistory,
                style: { cursor: 'pointer' }
              }
            }}
          />
        </Flex>
        <Flex
          direction={'column'}
          gap={'0.1rem'}
          width={isMobile ? '100%' : '80%'}
        >
          <BlankOverviewBox
            overrides={{
              BlankOverviewBox: {
                width: '100%',
                borderRadius: '5px 5px 0px 0px',
                marginBottom: 0,
                height: 100,
                alignItems: 'center',
                backgroundColor: Colors.container
              }
            }}
          >
            <Flex
              direction={isMobile ? 'column' : 'row'}
              marginLeft={40}
              marginRight={40}
              width={'100%'}
              alignItems="center"
            >
              <View width={isMobile ? '100%' : '50%'}>
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="700"
                  lineHeight="20px"
                  letterSpacing="1px"
                  children={Strings.SUBSCRIPTIONS.toUpperCase()}
                ></Text>
              </View>
              <Flex
                width={isMobile ? '100%' : '50%'}
                direction="row"
                justifyContent={'flex-end'}
                alignItems={!isMobile && 'flex-end'}
              >
                <SelectField
                  width={isMobile ? '100%' : 125}
                  size="small"
                  placeholder={'Active or Inactive'}
                  value={subscriptionStatus}
                  onChange={(e) => {
                    setSubscriptionStatus(e.target.value);
                    reloadSubscriptions(e.target.value);
                  }}
                >
                  <option value={'active'}>{'Active'}</option>
                  <option value={'cancelled'}>{'Inactive'}</option>
                </SelectField>
                <Button
                  variation="primary"
                  children={Strings.ADD_SUBSCRIPTION}
                  size="small"
                  onClick={() => goToSubscribe()}
                />
              </Flex>
            </Flex>
          </BlankOverviewBox>

          {/* LOADING TEXT */}
          {loading && (
            <BlankOverviewBox
              overrides={{
                BlankOverviewBox: {
                  width: '100%',
                  borderRadius: '0px 0px 5px 5px',
                  marginTop: 0,
                  backgroundColor: Colors.container
                }
              }}
            >
              <Flex
                direction={'column'}
                width={'100%'}
                justifyContent="center"
                alignItems={'center'}
              >
                <Loader
                  type="bubbles"
                  color={Colors.loader}
                  width={75}
                  height={75}
                />
              </Flex>
            </BlankOverviewBox>
          )}

          {/* NO SUBSCRIPTIONS TEXT */}
          {!loading && _.size(subscriptions) === 0 && (
            <BlankOverviewBox
              overrides={{
                BlankOverviewBox: {
                  width: '100%',
                  borderRadius: '0px 0px 5px 5px',
                  marginTop: 0,
                  backgroundColor: Colors.container
                }
              }}
            >
              <Flex marginLeft={40} marginRight={40} width={'100%'}>
                <Text
                  fontFamily="Inter"
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="20px"
                  letterSpacing="1px"
                  children={Strings.NO_SUBSCRIPTIONS_USER}
                ></Text>
              </Flex>
            </BlankOverviewBox>
          )}

          {/* SUBSCRIPTIONS */}
          {!loading &&
            _.map(subscriptions, (item, idx) => {
              return renderSubscriptionItem(idx, item);
            })}
        </Flex>
      </Flex>

      <Modal visible={modalView} animationType="slide" transparent>
        <BlankOverviewBox
          overrides={{
            BlankOverviewBox: {
              width: isMobile ? width / 1.1 : width / 3,
              alignSelf: 'center',
              marginTop: isMobile ? 20 : 50,
              alignContent: 'center',
              justifyContent: 'center'
            }
          }}
        >
          {cancelSubscriptionVisible && cancelSubscriptionForm()}
        </BlankOverviewBox>
      </Modal>

      {modalView && (
        <View
          height={'100%'}
          width={width}
          backgroundColor={'rgba(0,0,0,0.5)'}
          top={0}
          left={0}
          bottom={0}
          position="absolute"
        />
      )}

      <ToastContainer />

      <Footer />
    </Flex>
  );
}

import _ from 'lodash';
import axios from 'axios';

const vaultAPI = axios.create({
  baseURL: _.get(process.env, 'REACT_APP_IFRAME_URL'),
  headers: { 'x-api-key': _.get(process.env, 'REACT_APP_IFRAME_KEY')}
});

export const loadVaultToken = async (setVaultToken) => {
    const vaultTokenResponse = await vaultAPI.get('/authorize');

    console.log({ vaultTokenResponse });

    const vaultToken = _.get(vaultTokenResponse, 'data.data.token');

    console.log({ vaultToken });

    const callback = _.isFunction(setVaultToken) ? setVaultToken : (token) => token;

    return callback(vaultToken);
};

export const loadVaultTokenDetails = async (token, setPaymentMethod) => {
    const vaultTokenResponse = await vaultAPI.get(`/review/${token}`);

    console.log({ vaultTokenResponse });

    const paymentMethod = _.get(vaultTokenResponse, 'data.data');

    console.log({ paymentMethod });

    const callback = _.isFunction(setPaymentMethod) ? setPaymentMethod : (paymentMethod) => paymentMethod;

    return callback(paymentMethod);
};

export const storeVaultToken = async (token, setCardData) => {
    const cardDataResponse = await vaultAPI.get(`/finalize/${token}`);

    console.log({ cardDataResponse });

    const cardData = _.get(cardDataResponse, 'data');

    console.log({ cardData });

    const callback = _.isFunction(setCardData) ? setCardData : (cardData) => cardData;

    callback(cardData);

    return cardData;
};
import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { useWindowDimensions } from 'react-native';
import { Flex, View } from '@aws-amplify/ui-react';

import Footer from '../../components/Footer';
import { Colors, Images, Strings } from '../../constants';
import TermsConditions from '../../components/TermsConditions';
import { AppNavBar, BlankOverviewBox } from '../../ui-components';

export default function TermsScreen() {
  const { height, width } = useWindowDimensions();
  const [isMobile, setIsMobile] = useState(() => {
    if (width < 768) return true;
    else return false;
  });

  useEffect(() => {
    if (width < 768) setIsMobile(true);
    else return setIsMobile(false);
  }, [width]);

  const containerWidth = isMobile ? width / 1.1 : width / 1.2;

  useEffect(() => {
    document.title = Strings.TERMS_CONDITIONS;
  }, []);

  return (
    <Flex
      direction="column"
      alignItems={'center'}
      height={null}
      minHeight="100vh"
      backgroundColor={Colors.surface}
      width={'100%'}
      overflow={'hidden'}
    >
      <View
        backgroundColor={Colors.background}
        width={'100%'}
        position={'absolute'}
        height={'30%'}
      />

      <AppNavBar
        overrides={{
          AppNavBar: { width: '100%', backgroundColor: Colors.secondary },
          user: {
            fontFamily: 'Inter-Regular',
            children: Strings.SUPPORT,
            color: Colors.headerText
          },
          Logo30873174: { src: process.env.REACT_APP_LOGO_URL },
          image: { src: Images.USER_PLACEHOLDER, style: { display: 'none' } }
        }}
      />

      <Flex
        direction={'column'}
        width={isMobile ? containerWidth : containerWidth / 2}
        flex={1}
        marginTop={isMobile ? 30 : 30}
        marginBottom={30}
      >
        <BlankOverviewBox
          overrides={{
            BlankOverviewBox: {
              width: '100%',
              borderRadius: '5px 5px 5px 5px',
              padding: '40px 40px 40px 40px',
              marginTop: 0,
              backgroundColor: Colors.container
            }
          }}
        >
          <TermsConditions />
        </BlankOverviewBox>
      </Flex>

      <ToastContainer />

      <Footer />
    </Flex>
  );
}

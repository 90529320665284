/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import { useState, useEffect } from "react";
import { Flex, View, Text, Grid,  Link, Image } from '@aws-amplify/ui-react';
import { MdPhone, MdEmail } from 'react-icons/md';
import { useWindowDimensions } from "react-native";

import { Colors } from '../../constants';

export default function Footer(props) {

    const { height, width } = useWindowDimensions();
    const [isMobile, setIsMobile] = useState(() => {
        if(width < 768) return true
        else return false;
    });
    useEffect(() => {
        if(width < 768) setIsMobile(true)
        else return setIsMobile(false);
    },[width]);

    return (
        <View backgroundColor={Colors.footer} >
            <Grid
                justifyContent={'center'}
                minHeight={height / 3.1}
                paddingTop={60}
                //paddingBottom={30}
                paddingLeft={170}
                paddingRight={170}                
                width={width}
                templateColumns={{ base: '1fr', large: '3fr 2fr 2fr 2fr'}}
                templateRows={{ large: '2rem'}}
                gap="var(--amplify-space-small)">
                    <Flex direction={'column'} alignItems='center'>
                        <Link href={process.env.REACT_APP_CLIENT_URL}>
                            <Image width={300} src={process.env.REACT_APP_LOGO_URL} />
                        </Link>
                        <Link target='_blank' href={'https://www.angi.com/companylist/us/il/barrington/handy-men-and-mrs-helper-kitchen-and-bath-reviews-120856.htm'}>
                            <Image width={150} src={'https://hmmhkb.com/wp-content/uploads/2020/04/angies-list-super-service-award-2019-handy-men-mrs-helper-kitchen-bath-4.png'} />
                        </Link>
                    </Flex>
                    <View>
                        <Flex direction={'column'}>
                            <Text
                                fontFamily="Inter-Regular"
                                fontSize={isMobile ? '17px' : '17px'}
                                fontWeight="700"                                    
                                color={Colors.footerText}
                                left={30}
                                marginBottom={0}
                                children={`Service Index`} />
                            <Link
                            href='https://hmmhkb.com/bathroom-remodeling-contractors-chicago-suburbs/'
                                fontFamily="Inter-Regular"
                                fontSize={isMobile ? '17px' : '17px'}
                                fontWeight="500"                                    
                                color={Colors.footerText}
                                left={30}
                                lineHeight="10px"
                                children={`Bathroom Remodels`} />
                            <Link
                                href='https://hmmhkb.com/kitchen-remodeling-contractors-chicago-suburbs/'
                                fontFamily="Inter-Regular"
                                fontSize={isMobile ? '17px' : '17px'}
                                fontWeight="500"                                    
                                color={Colors.footerText}
                                left={30}
                                lineHeight="10px"
                                children={`Kitchen Remodels`} />
                            <Link
                                href='https://hmmhkb.com/finished-basements-remodeling-chicago-suburbs/'
                                fontFamily="Inter-Regular"
                                fontSize={isMobile ? '17px' : '17px'}
                                fontWeight="500"                                    
                                color={Colors.footerText}
                                left={30}
                                lineHeight="10px"
                                children={`Basement Finishing`} />
                            <Link
                            href='https://hmmhkb.com/mudrooms-home-improvement-chicago-suburbs/'
                                fontFamily="Inter-Regular"
                                fontSize={isMobile ? '17px' : '17px'}
                                fontWeight="500"                                    
                                color={Colors.footerText}
                                left={30}
                                lineHeight="10px"
                                children={`Mudrooms`} />
                            <Link
                            href='https://hmmhkb.com/fireplace-installation-company-chicago-suburbs/'
                                fontFamily="Inter-Regular"
                                fontSize={isMobile ? '17px' : '17px'}
                                fontWeight="500"                                    
                                color={Colors.footerText}
                                left={30}
                                lineHeight="10px"
                                children={`Fireplaces`} />
                            <Link
                            href='https://hmmhkb.com/handyman-contractor-services-chicago-suburbs/'
                                fontFamily="Inter-Regular"
                                fontSize={isMobile ? '17px' : '17px'}
                                fontWeight="500"                                    
                                color={Colors.footerText}
                                left={30}
                                lineHeight="10px"
                                children={`Handyman Services`} />
                            <Link
                            href='https://hmmhkb.com/painters-and-remodeling-chicago-suburbs/'
                                fontFamily="Inter-Regular"
                                fontSize={isMobile ? '17px' : '17px'}
                                fontWeight="500"                                    
                                color={Colors.footerText}
                                left={30}
                                lineHeight="10px"
                                children={`Painting Contractors`} />
                        </Flex>
                    </View>
                    <View>
                        <Flex direction={'column'}>
                            <Text
                                fontFamily="Inter-Regular"
                                fontSize={isMobile ? '17px' : '17px'}
                                fontWeight="700"                                    
                                color={Colors.footerText}
                                left={30}
                                marginBottom={0}
                                children={`Address`} />
                            <Text
                                fontFamily="Inter-Regular"
                                fontSize={isMobile ? '17px' : '17px'}
                                fontWeight="500"                                    
                                color={Colors.footerText}
                                left={30}
                                lineHeight="10px"
                                children={`Handy Man & Mrs. Helper`} />
                            <Text
                                fontFamily="Inter-Regular"
                                fontSize={isMobile ? '17px' : '17px'}
                                fontWeight="500"                                    
                                color={Colors.footerText}
                                left={30}
                                lineHeight="10px"
                                children={`Kitchen & Bath`} />
                            <Text
                                fontFamily="Inter-Regular"
                                fontSize={isMobile ? '17px' : '17px'}
                                fontWeight="500"                                    
                                color={Colors.footerText}
                                left={30}
                                lineHeight="10px"
                                children={`117 S. Cook Street, #246`} />
                            <Text
                                fontFamily="Inter-Regular"
                                fontSize={isMobile ? '17px' : '17px'}
                                fontWeight="500"                                    
                                color={Colors.footerText}
                                left={30}
                                lineHeight="10px"
                                children={`Barrington, IL 60010`} />
                        </Flex>
                    </View>
                    <View>
                        <Flex direction={'column'}>
                            <Text
                                fontFamily="Inter-Regular"
                                fontSize={isMobile ? '17px' : '17px'}
                                fontWeight="700"                                    
                                color={Colors.footerText}
                                left={30}
                                marginBottom={0}
                                children={`Contact Us`} />
                            <Flex direction={'row'} alignItems={'center'}>
                                <MdPhone color='white' size={15} />
                                <Link
                                    target={'_blank'}
                                    href={`tel${process.env.REACT_APP_CLIENT_TEL}`}
                                    fontFamily="Inter-Regular"
                                    fontSize={isMobile ? '17px' : '17px'}
                                    fontWeight="500"                                    
                                    color={Colors.footerText}
                                    left={30}
                                    lineHeight="10px"
                                    children={process.env.REACT_APP_CLIENT_PHONE} />
                            </Flex>
                            <Flex direction={'row'} alignItems={'center'}>
                                <MdEmail color='white' size={15} />
                                <Link
                                    target={'_blank'}
                                    href='mailto:customerservice@pmcomfort.com'
                                    fontFamily="Inter-Regular"
                                    fontSize={isMobile ? '17px' : '17px'}
                                    fontWeight="500"                                    
                                    color={Colors.footerText}
                                    left={30}
                                    lineHeight="10px"
                                    children={process.env.REACT_APP_CLIENT_EMAIL} />
                            </Flex>
                        </Flex>
                    </View>
                    
                    

            </Grid>
            {/* <Flex paddingBottom={10} paddingLeft={170} paddingRight={200} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Text
                    fontFamily="Inter-Regular"
                    fontSize={isMobile ? '17px' : '17px'}
                    fontWeight="500"                                    
                    color={Colors.footerText}
                    left={30}
                    lineHeight="10px"
                    children={`@ Paragon Mechanical`} />
                
            </Flex> */}

        </View>
    );
}

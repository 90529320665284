import { Flex, View, Text } from '@aws-amplify/ui-react';

export default function TermsConditions() {
    return (
        <View alignSelf='center' width={'100%'}>                

            <Flex direction={'column'}>
                
                <View>

                    <Text
                    marginBottom={20}
                    fontFamily="Inter"
                    fontSize="32px"
                    fontWeight="700"
                    color="rgba(13,26,38,1)"
                    lineHeight="40px"
                    textDecoration={'underline'}
                    children={'Section I: Terms and Conditions'} />
                   
                    <Text
                    marginBottom={10}
                    fontFamily="Inter"
                    fontSize="24px"
                    fontWeight="700"
                    lineHeight="24px"
                    letterSpacing="0.01px"                    
                    children={'1. Introduction'} />
                    
                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    children={'The use of our website is subject to the following terms and conditions of use, as amended from time to time (the “Terms”). The Terms are to be read together by you with any terms, conditions or disclaimers provided in the pages of our website. Please review the Terms carefully. The Terms apply to all users of our website, including without limitation, users who are browsers, customers, merchants, vendors and/or contributors of content. If you access and use this website, you accept and agree to be bound by and comply with the Terms and our Privacy Policy. If you do not agree to the Terms or our Privacy Policy, you are not authorized to access our website, use any of our website’s services or place an order on our website.'} />

                    <Text
                    marginTop={20}
                    marginBottom={10}
                    fontFamily="Inter"
                    fontSize="24px"
                    fontWeight="700"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    children={'2. Use of our Website'} />
                    
                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    children={'You agree to use our website for legitimate purposes and not for any illegal or unauthorized purpose, including without limitation, in violation of any intellectual property or privacy law. By agreeing to the Terms, you represent and warrant that you are at least the age of majority in your state or province of residence and are legally capable of entering into a binding contract.'} />
                    
                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    marginTop={10}
                    children={'You agree to not use our website to conduct any activity that would constitute a civil or criminal offence or violate any law. You agree not to attempt to interfere with our website’s network or security features or to gain unauthorized access to our systems.'} />
                    
                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    marginTop={10}
                    children={'You agree to provide us with accurate personal information, such as your email address, mailing address and other contact details in order to complete your order or contact you as needed. You agree to promptly update your account and information. You authorize us to collect and use this information to contact you in accordance with our Privacy Policy.'} />
                    
                    <Text
                    marginTop={20}
                    marginBottom={10}
                    fontFamily="Inter"
                    fontSize="24px"
                    fontWeight="700"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    children={'3. General Conditions'} />
                    
                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    children={'We reserve the right to refuse service to anyone, at any time, for any reason. We reserve the right to make any modifications to the website, including terminating, changing, suspending or discontinuing any aspect of the website at any time, without notice. We may impose additional rules or limits on the use of our website. You agree to review the Terms regularly and your continued access or use of our website will mean that you agree to any changes.'} />
                    
                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    marginTop={10}
                    children={'You agree that we will not be liable to you or any third party for any modification, suspension or discontinuance of our website or for any service, content, feature or product offered through our website.'} />

                    <Text
                    marginTop={20}
                    marginBottom={10}
                    fontFamily="Inter"
                    fontSize="24px"
                    fontWeight="700"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    children={'4. Products or Services'} />
                    
                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    children={'All purchases through our website are subject to product availability. We may, in our sole discretion, limit or cancel the quantities offered on our website or limit the sales of our products or services to any person, household, geographic region or jurisdiction.'} />

                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    marginTop={10}
                    children={'Prices for our products are subject to change, without notice. Unless otherwise indicated, prices displayed on our website are quoted in US dollars.'} />
                    
                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    marginTop={10}
                    children={'We reserve the right, in our sole discretion, to refuse orders, including without limitation, orders that appear to be placed by distributors or resellers. If we believe that you have made a false or fraudulent order, we will be entitled to cancel the order and inform the relevant authorities.'} />

                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    marginTop={10}
                    children={'We do not guarantee the accuracy of the color or design of the products on our website. We have made efforts to ensure the color and design of our products are displayed as accurately as possible on our website.'} />

                    <Text
                    marginTop={20}
                    marginBottom={10}
                    fontFamily="Inter"
                    fontSize="24px"
                    fontWeight="700"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    children={'5. Links to Third-Party Websites'} />
                    
                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    children={'Links from or to websites outside our website are meant for convenience only. We do not review, endorse, approve or control, and are not responsible for any sites linked from or to our website, the content of those sites, the third parties named therein, or their products and services. Linking to any other site is at your sole risk and we will not be responsible or liable for any damages in connection with linking. Links to downloadable software sites are for convenience only and we are not responsible or liable for any difficulties or consequences associated with downloading the software. Use of any downloaded software is governed by the terms of the license agreement, if any, which accompanies or is provided with the software.'} />

                    
                    <Text
                    marginTop={20}
                    marginBottom={10}
                    fontFamily="Inter"
                    fontSize="24px"
                    fontWeight="700"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    children={'6. Use Comments, Feedback, and Other Submissions'} />
                    
                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    children={'You acknowledge that you are responsible for the information, profiles, opinions, messages, comments and any other content (collectively, the “Content”) that you post, distribute or share on or through our website or services available in connection with our website. You further acknowledge that you have full responsibility for the Content, including but limited to, with respect to its legality, and its trademark, copyright and other intellectual property ownership.'} />

                    
                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    marginTop={10}
                    children={'You agree that any Content submitted by you in response to a request by us for a specific submission may be edited, adapted, modified, recreated, published, or distributed by us. You further agree that we are under no obligation to maintain any Content in confidence, to pay compensation for any Content or to respond to any Content.'} />

                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    marginTop={10}
                    children={'You agree that you will not post, distribute or share any Content on our website that is protected by copyright, trademark, patent or any other proprietary right without the express consent of the owner of such proprietary right. You further agree that your Content will not be unlawful, abusive or obscene nor will it contain any malware or computer virus that could affect our website’s operations. You will be solely liable for any Content that you make and its accuracy. We have no responsibility and assume no liability for any Content posted by you or any third-party.'} />
                    
                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    marginTop={10}
                    children={'We reserve the right to terminate your ability to post on our website and to remove and/or delete any Content that we deem objectionable. You consent to such removal and/or deletion and waive any claim against us for the removal and/or deletion of your Content.'} />
                    
                    <Text
                    marginTop={20}
                    marginBottom={10}
                    fontFamily="Inter"
                    fontSize="24px"
                    fontWeight="700"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    children={'7. Your Personal Information'} />

                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    children={'Please see our Privacy Policy to learn about how we collect, use, and share your personal information.'} />

                    <Text
                    marginTop={20}
                    marginBottom={10}
                    fontFamily="Inter"
                    fontSize="24px"
                    fontWeight="700"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    children={'8. Errors and Omissions'} />

                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    children={'Please note that our website may contain typographical errors or inaccuracies and may not be complete or current. We reserve the right to correct any errors, inaccuracies or omissions and to change or update information at any time, without prior notice (including after an order has been submitted). Such errors, inaccuracies or omissions may relate to product description, pricing, promotion and availability and we reserve the right to cancel or refuse any order placed based on incorrect pricing or availability information, to the extent permitted by applicable law.'} />
                    
                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    marginTop={10}
                    children={'We do not undertake to update, modify or clarify information on our website, except as required by law.'} />
                    
                    <Text
                    marginTop={20}
                    marginBottom={10}
                    fontFamily="Inter"
                    fontSize="24px"
                    fontWeight="700"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    children={'9. Disclaimer and Limitation of Liability'} />

                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    children={'You assume all responsibility and risk with respect to your use of our website, which is provided “as is” without warranties, representations or conditions of any kind, either express or implied, with regard to information accessed from or via our website, including without limitation, all content and materials, and functions and services provided on our website, all of which are provided without warranty of any kind, including but not limited to warranties concerning the availability, accuracy, completeness or usefulness of content or information, uninterrupted access, and any warranties of title, non-infringement, merchantability or fitness for a particular purpose. We do not warrant that our website or its functioning or the content and material of the services made available thereby will be timely, secure, uninterrupted or error-free, that defects will be corrected, or that our websites or the servers that make our website available are free of viruses or other harmful components.'} />
                    
                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    marginTop={10}
                    children={'The use of our website is at your sole risk and you assume full responsibility for any costs associated with your use of our website. We will not be liable for any damages of any kind related to the use of our website.'} />
                    
                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    children={'In no event will we, or our affiliates, our or their respective content or service providers, or any of our or their respective directors, officers, agents, contractors, suppliers or employees be liable to you for any direct, indirect, special, incidental, consequential, exemplary or punitive damages, losses or causes of action, or lost revenue, lost profits, lost business or sales, or any other type of damage, whether based in contract or tort (including negligence), strict liability or otherwise, arising from your use of, or the inability to use, or the performance of, our website or the content or material or functionality through our website, even if we are advised of the possibility of such damages.'} />

                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    marginTop={10}
                    children={'Certain jurisdictions do not allow limitation of liability or the exclusion or limitation of certain damages. In such jurisdictions, some or all of the above disclaimers, exclusions, or limitations, may not apply to you and our liability will be limited to the maximum extent permitted by law.'} />

                    <Text
                    marginTop={20}
                    marginBottom={10}
                    fontFamily="Inter"
                    fontSize="24px"
                    fontWeight="700"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    children={'10. Indemnification'} />

                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    children={'You agree to defend and indemnify us, and hold us and our affiliates harmless, and our and their respective directors, officers, agents, contractors, and employees against any losses, liabilities, claims, expenses (including legal fees) in any way arising from, related to or in connection with your use of our website, your violation of the Terms, or the posting or transmission of any materials on or through the website by you, including but not limited to, any third party claim that any information or materials provided by you infringe upon any third party proprietary rights.'} />
                    
                    <Text
                    marginTop={20}
                    marginBottom={10}
                    fontFamily="Inter"
                    fontSize="24px"
                    fontWeight="700"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    children={'11. Entire Agreement'} />

                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    children={'The Terms and any documents expressly referred to in them represent the entire agreement between you and us in relation to the subject matter of the Terms and supersede any prior agreement, understanding or arrangement between you and us, whether oral or in writing. Both you and we acknowledge that, in entering into these Terms, neither you nor we have relied on any representation, undertaking or promise given by the other or implied from anything said or written between you and us prior to such Terms, except as expressly stated in the Terms.'} />
                    
                    <Text
                    marginTop={20}
                    marginBottom={10}
                    fontFamily="Inter"
                    fontSize="24px"
                    fontWeight="700"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    children={'12. Waiver'} />

                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    children={'Our failure to exercise or enforce any right or provision of the Terms will not constitute a waiver of such right or provision. A waiver by us of any default will not constitute a waiver of any subsequent default. No waiver by us is effective unless it is communicated to you in writing.'} />
                    
                    <Text
                    marginTop={20}
                    marginBottom={10}
                    fontFamily="Inter"
                    fontSize="24px"
                    fontWeight="700"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    children={'13. Headings'} />

                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    children={'Any headings and titles herein are for convenience only.'} />

                    <Text
                    marginTop={20}
                    marginBottom={10}
                    fontFamily="Inter"
                    fontSize="24px"
                    fontWeight="700"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    children={'14. Severability'} />

                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    children={'If any of the provisions of the Terms are determined by any competent authority to be invalid, unlawful or unenforceable, such provision will to that extent be severed from the remaining Terms, which will continue to be valid and enforceable to the fullest extent permitted by law.'} />
                    
                    <Text
                    marginBottom={20}
                    marginTop={20}
                    fontFamily="Inter"
                    fontSize="32px"
                    fontWeight="700"
                    color="rgba(13,26,38,1)"
                    lineHeight="40px"
                    textDecoration={'underline'}
                    children={'Section II: Privacy Policy'} />

                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    marginBottom={20}
                    children={'We are committed to maintaining the accuracy, confidentiality, and security of your personally identifiable information ("Personal Information"). As part of this commitment, our privacy policy governs our actions as they relate to the collection, use and disclosure of Personal Information. '} />
                   
                    <Text
                    marginBottom={10}
                    fontFamily="Inter"
                    fontSize="24px"
                    fontWeight="700"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    children={'1. Introduction'} />
                    
                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    marginBottom={20}
                    children={'We are responsible for maintaining and protecting the Personal Information under our control. We have designated an individual or individuals who is/are responsible for compliance with our privacy policy.'} />

                    <Text
                    marginBottom={10}
                    fontFamily="Inter"
                    fontSize="24px"
                    fontWeight="700"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    children={'2. Identifying Purposes'} />
                    
                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    marginBottom={20}
                    children={'We collect, use and disclose Personal Information to provide you with the product or service you have requested and to offer you additional products and services we believe you might be interested in. The purposes for which we collect Personal Information will be identified before or at the time we collect the information. In certain circumstances, the purposes for which information is collected may be clear, and consent may be implied, such as where your name, address and payment information is provided as part of the order process.'} />

                    <Text
                    marginBottom={10}
                    fontFamily="Inter"
                    fontSize="24px"
                    fontWeight="700"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    children={'3. Consent'} />
                    
                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    marginBottom={20}
                    children={'Knowledge and consent are required for the collection, use or disclosure of Personal Information except where required or permitted by law. Providing us with your Personal Information is always your choice. However, your decision not to provide certain information may limit our ability to provide you with our products or services. We will not require you to consent to the collection, use, or disclosure of information as a condition to the supply of a product or service, except as required to be able to supply the product or service.'} />
                    
                    <Text
                    marginBottom={10}
                    fontFamily="Inter"
                    fontSize="24px"
                    fontWeight="700"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    children={'4. Limiting Collection'} />
                    
                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    marginBottom={20}
                    children={'The Personal Information collected will be limited to those details necessary for the purposes identified by us. With your consent, we may collect Personal Information from you in person, over the telephone or by corresponding with you via mail, facsimile, or the Internet.'} />

                    <Text
                    marginBottom={10}
                    fontFamily="Inter"
                    fontSize="24px"
                    fontWeight="700"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    children={'5. Limiting Use, Disclosure and Retention'} />
                    
                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    marginBottom={20}
                    children={'Personal Information may only be used or disclosed for the purpose for which it was collected unless you have otherwise consented, or when it is required or permitted by law. Personal Information will only be retained for the period of time required to fulfill the purpose for which we collected it or as may be required by law. '} />

                    <Text
                    marginBottom={10}
                    fontFamily="Inter"
                    fontSize="24px"
                    fontWeight="700"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    children={'6. Accuracy'} />
                    
                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    marginBottom={20}
                    children={'Personal Information will be maintained in as accurate, complete and up-to-date form as is necessary to fulfill the purposes for which it is to be used.'} />

                    <Text
                    marginBottom={10}
                    fontFamily="Inter"
                    fontSize="24px"
                    fontWeight="700"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    children={'7. Safeguarding Customer Information'} />
                    
                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    marginBottom={20}
                    children={'Personal Information will be protected by security safeguards that are appropriate to the sensitivity level of the information. We take all reasonable precautions to protect your Personal Information from any loss or unauthorized use, access or disclosure.'} />
                    
                    <Text
                    marginBottom={10}
                    fontFamily="Inter"
                    fontSize="24px"
                    fontWeight="700"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    children={'8. Openness'} />
                    
                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    marginBottom={20}
                    children={'We will make information available to you about our policies and practices with respect to the management of your Personal Information.'} />
                    
                    <Text
                    marginBottom={10}
                    fontFamily="Inter"
                    fontSize="24px"
                    fontWeight="700"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    children={'9. Customer Access'} />
                    
                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    marginBottom={20}
                    children={'Upon request, you will be informed of the existence, use and disclosure of your Personal Information, and will be given access to it. You may verify the accuracy and completeness of your Personal Information, and may request that it be amended, if appropriate. However, in certain circumstances permitted by law, we will not disclose certain information to you. For example, we may not disclose information relating to you if other individuals are referenced or if there are legal, security or commercial proprietary restrictions.'} />
                    
                    <Text
                    marginBottom={20}
                    fontFamily="Inter"
                    fontSize="24px"
                    fontWeight="700"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    children={'Additional Information'} />

                    <Text
                    marginBottom={10}
                    fontFamily="Inter"
                    fontSize="24px"
                    fontWeight="700"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    fontStyle={'italic'}
                    children={'Cookies'} />
                    
                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    marginBottom={20}
                    children={`A cookie is a small computer file or piece of information that may be stored in your computer's hard drive when you visit our websites. We may use cookies to improve our website’s functionality and in some cases, to provide visitors with a customized online experience.`} />
                    
                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    marginBottom={20}
                    children={`Cookies are widely used and most web browsers are configured initially to accept cookies automatically. You may change your Internet browser settings to prevent your computer from accepting cookies or to notify you when you receive a cookie so that you may decline its acceptance. Please note, however, if you disable cookies, you may not experience optimal performance of our website.`} />

                    <Text
                    marginBottom={10}
                    fontFamily="Inter"
                    fontSize="24px"
                    fontWeight="700"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    fontStyle={'italic'}
                    children={'Other Websites'} />
                    
                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    marginBottom={20}
                    children={`Our website may contain links to other third party sites that are not governed by this privacy policy. Although we endeavor to only link to sites with high privacy standards, our privacy policy will no longer apply once you leave our website. Additionally, we are not responsible for the privacy practices employed by third party websites. Therefore, we suggest that you examine the privacy statements of those sites to learn how your information may be collected, used, shared and disclosed.`} />
                    
                    <Text
                    marginBottom={20}
                    fontFamily="Inter"
                    fontSize="32px"
                    fontWeight="700"
                    color="rgba(13,26,38,1)"
                    lineHeight="40px"
                    textDecoration={'underline'}
                    children={'Section III: Refund Policy'} />
                    
                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    children={'We do not accept refunds for products or services rendered all sales are final. Please contact customer support with any issues.'} />

                    <Text
                    marginTop={20}
                    marginBottom={20}
                    fontFamily="Inter"
                    fontSize="32px"
                    fontWeight="700"
                    color="rgba(13,26,38,1)"
                    lineHeight="40px"
                    textDecoration={'underline'}
                    children={'Section IV: Security Policy'} />
                    
                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    children={'When you place orders on our websites or through our mobile applications, all of your order information, including your credit card number and delivery address, is transmitted through the Internet using Secure Sockets Layer (SSL) technology. SSL technology causes your browser to encrypt your order information before transmitting it to our secure server. SSL technology, an industry standard, is designed to prevent someone other than operators of our websites from capturing and viewing your personal information.'} />
                    
                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    children={'While we use industry standard means to protect our websites and your information, the Internet is not 100% secure. The measures we use are appropriate for the type of information we collect. We cannot promise that your use of our websites or mobile applications will be completely safe. We encourage you to use caution when using the Internet. Online access to your personal information is protected with a password you select. We strongly recommend that you do not share your password and that you use unique passwords for all of your online accounts.'} />
                    
                    <Text
                    marginTop={20}
                    marginBottom={20}
                    fontFamily="Inter"
                    fontSize="32px"
                    fontWeight="700"
                    color="rgba(13,26,38,1)"
                    lineHeight="40px"
                    textDecoration={'underline'}
                    children={'Section V: Delivery Policy'} />
                    
                    <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="24px"
                    letterSpacing="0.01px"
                    children={'Not Applicable'} />
                    
                </View>

            </Flex>

            
        </View>
    )
}
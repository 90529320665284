import _ from 'lodash';

export const buildQueryStringParameterFetch = () => new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
});

export const getQueryStringParameter = (paramName) => _.toString(_.cloneDeep(_.get(buildQueryStringParameterFetch(), paramName, '')));

export const hasQueryStringParameter = (paramName) => getQueryStringParameter(paramName) !== '';

export const getPathParameter = (pathIdentifier) => {
    const pathIndexes = {
        action: 2,
        resource: 0,
        resourceId: 1,
        childAction: 4,
        childResource: 2,
        childResourceId: 3
    };

    const pathIndex = _.get(pathIndexes, pathIdentifier, pathIdentifier);

    const pathSegments = _.compact(window.location.pathname.split('/'));

    return _.get(pathSegments, pathIndex, '');
};

export const hasPathParameter = (pathIdentifier) => getPathParameter(pathIdentifier) !== '';
import _ from 'lodash';
import HmmhkbFooter from './hmmhkb';
import DefaultFooter from './default';
import PmcomfortFooter from './pmcomfort';

const footers = {
    hmmhkb: HmmhkbFooter,
    default: DefaultFooter,
    pmcomfort: PmcomfortFooter
};

const clientSlug = _.get(process.env, 'REACT_APP_STOREFRONT_CLIENT');

export default _.get(footers, clientSlug, _.get(footers, 'default'));
import React from 'react';
import { Flex, useAuthenticator } from '@aws-amplify/ui-react';
import { Route, Routes, BrowserRouter } from "react-router-dom";

import { Screens, ScreenNames } from './constants';
import useCachedResources from './hooks/useCacheResources';
import ErrorBoundary from './components/Errors/ErrorBoundary';

function App() {
  const isLoadingComplete = useCachedResources();
  const { route } = useAuthenticator(context => [context.route]);

  if(!isLoadingComplete) return <Flex />

  return (
    <BrowserRouter>
      <Routes>
        {/* ROUTES */}
          <Route exact path={ScreenNames.HOME} element={<ErrorBoundary><Screens.SubscriptionsStoreScreen/></ErrorBoundary>} />
          <Route exact path={ScreenNames.SIGN_UP} element={<ErrorBoundary><Screens.SignUpScreen /></ErrorBoundary>} />
          <Route exact path={ScreenNames.SIGN_IN} element={<ErrorBoundary><Screens.SignUpScreen /></ErrorBoundary>} />
          <Route exact path={ScreenNames.OVERVIEW} element={route === 'authenticated' ? <ErrorBoundary><Screens.OverviewScreen/></ErrorBoundary> : <ErrorBoundary><Screens.SignUpScreen/></ErrorBoundary>} />
          <Route path={ScreenNames.SUBSCRIPTIONS} element={route === 'authenticated' ? <ErrorBoundary><Screens.SubsciptionsScreen/></ErrorBoundary> : <ErrorBoundary><Screens.SignUpScreen/></ErrorBoundary>} />
          <Route path={ScreenNames.PAYMENT_METHODS} element={route === 'authenticated' ? <ErrorBoundary><Screens.PaymentMethodsScreen/></ErrorBoundary> : <ErrorBoundary><Screens.SignUpScreen/></ErrorBoundary>} />
          <Route path={ScreenNames.BILLING_HISTORY} element={route === 'authenticated' ? <ErrorBoundary><Screens.BillingHistoryScreen/></ErrorBoundary> : <ErrorBoundary><Screens.SignUpScreen/></ErrorBoundary>} />
          <Route path={ScreenNames.SUBSCRIPTIONS_STORE} element={<ErrorBoundary><Screens.SubscriptionsStoreScreen/></ErrorBoundary>} />
          <Route path={ScreenNames.SUBSCRIBE} element={route === 'authenticated' ? <ErrorBoundary><Screens.SubscribeScreen/></ErrorBoundary> : <ErrorBoundary><Screens.SignUpScreen/></ErrorBoundary>} />
          <Route path={ScreenNames.INVOICE} element={<ErrorBoundary><Screens.PayScreen/></ErrorBoundary>} />
          <Route path={ScreenNames.TERMS} element={<ErrorBoundary><Screens.TermsScreen/></ErrorBoundary>} />
          <Route path={ScreenNames.ERROR404} element={<ErrorBoundary><Screens.Error404Screen/></ErrorBoundary>} />

          {/* NO ROUTE*/}
        <Route path="*" element={<Screens.Error404Screen/>} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;

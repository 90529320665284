import _ from 'lodash';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { useWindowDimensions } from 'react-native';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuthenticator, Authenticator, Flex, Image, TextField, useTheme, View, Text, SelectField } from '@aws-amplify/ui-react';

import Error404Screen from '../ErrorScreen/404';
import { getQueryStringParameter } from '../../utils/html';
import { createCustomer, customerExists } from '../../utils/rest';
import { Colors, Strings, Variables, ScreenNames } from '../../constants';

const EMAIL_URL = _.get(process.env, 'EMAIL_URL','https://xc673bghr6.execute-api.us-east-2.amazonaws.com/dev/email');
const EMAIL_API_KEY = _.get(process.env, 'EMAIL_API_KEY','7a60eeef48764a1880557499c4ffeef8');

export default function SignUpScreen() {  
    const navigate = useNavigate(); 
    const location = useLocation();

    const { height } = useWindowDimensions();
    // const [isMobile, setIsMobile] = useState(() => {
    //     if(width < 768) return true
    //     else return false;
    // });
    // useEffect(() => {
    //     if(width < 768) setIsMobile(true)
    //     else return setIsMobile(false);
    // },[width]);
    
    const { user } = useAuthenticator(context => [context.user]);

    const [sbsData, setSbsData] = useState(null);
    const [customer, setCustomer] = useState({});


    const [initialState] = useState(() => {
        if(location.pathname === '/sign-up') return 'signUp'
        if(location.pathname === '/sign-in') return 'signIn'
        if(location.pathname === '/subscribe') return 'signUp'
    });

    const [productId] = useState(getQueryStringParameter("productId"));

    const formFields = {
        signUp: {
            given_name: {
                order: 1,
                isRequired: true,
                labelHidden: false,
                defaultValue: customer.firstName,
                placeholder: Strings.FIRST_NAME,
                label: Strings.PERSONAL_INFO
            },
            family_name: {
                order: 2,
                isRequired: true,
                labelHidden: true,
                placeholder: Strings.LAST_NAME,
                label: Strings.LAST_NAME
            },
            email: {
                order: 3,
                labelHidden: false,
                isRequired: true,
                label: Strings.ACCOUNT_INFORMATION
            },
            phone_number: {
                order: 4,
                isRequired: true,
                labelHidden: true,
                placeholder: Strings.PHONE_NUMBER,
                label: Strings.PHONE_NUMBER
            },
            password: {
                order: 5,                
                labelHidden: true,
                isRequired: true,
                label: Strings.PASSWORD
            },
            confirm_password: {
                order: 6,
                isRequired: true,
                label: Strings.PASSWORD_CONFIRM
            }
        },
    }

    const createSbsCustomer = async (awsResponse) => {
        if(!sbsData){
            try {
                console.log({ message: 'creating customer', customer });
                //SBS Create Customer Call
                const customerCreateResponse = await createCustomer({
                    company: '',
                    metadata: [],
                    country: 'USA',
                    language: 'English',
                    postal: _.get(customer, 'postal'),
                    address: _.get(customer, 'address'),
                    locality: _.get(customer, 'locality'),
                    phone: _.get(customer, 'phone_number'),
                    address2: _.get(customer, 'address2', ''),
                    email: _.toLower(_.get(customer, 'email')),
                    region: _.toUpper(_.get(customer, 'region')),
                    //externalId: awsResponse.awsId,
                    externalId: _.toLower(_.get(customer, 'email')),
                    firstName: _.capitalize(_.get(customer, 'given_name')),
                    lastName: _.capitalize(_.get(customer, 'family_name')),
                });

                setSbsData(customerCreateResponse);
    
                console.log({ customerCreateResponse });
                const options = {
                    headers: { 'x-api-key': EMAIL_API_KEY },
                };
                const params = {
                    subject: 'Welcome Email',
                    type: 'html',
                    data: 'Welcome Email Body',
                    toEmail: _.get(customerCreateResponse, 'email'),
                    fromEmail: 'no-reply@rebartechnology.io'
                };
                const response = await axios.post(EMAIL_URL, params, options);
                
                console.log(response);

                return customerCreateResponse;
            } catch (error) {
                console.log({ error, message: 'Failed to Create Customer in SBS'});
            }
        }
    }

    //ALERTS
    // const successToast = () => toast.success(Strings.SUCCESSFULLY_UPDATED, { position: 'top-center', theme: 'colored'});
    // const errorToast = () => toast.error(Strings.ERROR_OCCURED, { position: 'top-center', theme: 'colored'});

    //ROUTES
    // const goToSubscribe = () => navigate(`${ScreenNames.SUBSCRIBE}?productId=${productId}`);

    useEffect(() => {
        document.title = Strings.CREATE_ACCOUNT
    },[])

    useEffect(() => {
        if(user && productId && customer.id) navigate(`${ScreenNames.SUBSCRIBE}?productId=${productId}`)
    }, [user, productId, customer.id, navigate])

    if(_.get(process.env, 'REACT_APP_ALLOW_SIGN_IN') === 'false') return (<Error404Screen/>);

    return (
        <Flex direction="column" justifyContent="center" alignItems="center" style={{ minHeight: height, backgroundColor: Colors.surface }}>
            
            {user && <View backgroundColor={Colors.secondary} width={'100%'} position={'absolute'} height={'30%'} top={0} />}

            <Authenticator initialState={initialState} formFields={formFields} style={{ marginTop: 1000 }}
                components={{
                    Header() {
                        const { tokens } = useTheme();
                    
                        return (
                            <View textAlign="center" padding={tokens.space.large}>
                                <Image
                                    alt="Company Logo"
                                    src={process.env.REACT_APP_LOGO_URL}
                                />
                            </View>
                        );
                    },
                    SignUp: {
                        FormFields() {
                            const { validationErrors } = useAuthenticator();
                
                            return (
                                    <>
                                        {/* Re-use default `Authenticator.SignUp.FormFields` */}
                                        <Authenticator.SignUp.FormFields />

                                        {/* Append & require Address Section to sign up  */}
                                        <View marginTop={15}>
                                            <Text
                                                fontSize="1rem"                                              
                                                fontFamily="Inter"
                                                lineHeight="10px"
                                                children={Strings.ADDRESS}
                                            />

                                            <TextField 
                                                errorMessage={validationErrors.address}
                                                hasError={!!validationErrors.address}
                                                name="address"
                                                isRequired={true}
                                                marginTop={10}
                                                defaultValue=""
                                                placeholder={Strings.ADDRESS}
                                            />

                                            <TextField 
                                                name="address2"
                                                marginTop={10}
                                                defaultValue=""
                                                placeholder={Strings.ADDRESS_LINE_2}
                                            />
                                            <TextField 
                                                errorMessage={validationErrors.locality}
                                                hasError={!!validationErrors.locality}
                                                name="locality"
                                                isRequired={true}
                                                marginTop={10}
                                                defaultValue=""
                                                placeholder={Strings.CITY}
                                            />
                                            <Flex>
                                                <SelectField
                                                    errorMessage={validationErrors.region}
                                                    hasError={!!validationErrors.region}
                                                    name="region"
                                                    isRequired={true}
                                                    marginTop={10}
                                                    defaultValue="US"
                                                    variation="default"
                                                >
                                                    {Variables.STATES.map(state => {
                                                        return(<option key={state.abbreviation} value={state.abbreviation}>{state.name}</option>)
                                                    })}

                                                </SelectField>
                                                <TextField 
                                                    errorMessage={validationErrors.postal}
                                                    hasError={!!validationErrors.postal}
                                                    name="postal"
                                                    isRequired={true}
                                                    marginTop={10}
                                                    defaultValue=""
                                                    placeholder={Strings.ZIP}
                                                />
                                            </Flex>
                                        </View>

                                        {/* Append & require Terms & Conditions field to sign up  */}
                                        {/* <CheckboxField
                                            errorMessage={validationErrors.acknowledgement}
                                            hasError={!!validationErrors.acknowledgement}
                                            name="acknowledgement"
                                            value="yes"
                                            label="I agree with the Terms & Conditions"
                                        /> */}
                                    </>
                                );
                            }
                        }
                    }
                }
                services={{
                    async validateCustomSignUp(formData) {
                        console.log({ message: 'Validating Form Data ...', formData });
                        
                        const errors = {};

                        if(_.size(formData.address) < 5) _.set(errors, 'address', 'Please enter a valid Address' );
                        // if(_.size(formData.address) === 0) _.set(errors, 'address', 'You must provide an Address' );

                        if(_.size(formData.locality) < 3) _.set(errors, 'locality', 'Please enter a valid City' );
                        // if(_.size(formData.locality) === 0) _.set(errors, 'locality', 'You must provide a City' );

                        if(_.size(formData.region) !== 2) _.set(errors, 'region', 'Please enter a valid State' );
                        // if(_.size(formData.region) === 0) _.set(errors, 'region', 'You must provide a State' );

                        if(_.size(formData.postal) !== 5) _.set(errors, 'postal', 'Please enter a valid Zip Code' );
                        // if(_.size(formData.postal) === 0) _.set(errors, 'postal', 'You must provide a Zip Code' );

                        //if(formData.acknowledgement !== 'yes') _.set(errors, 'acknowledgement', 'You must agree to the Terms & Conditions' );
                        const exists = await customerExists(_.toLower(_.get(formData, 'email')))
                        if(_.get(formData, 'email') && exists) _.set(errors, 'email', 'Email already exists' );

                        if(_.size(errors) === 0){ 
                            setCustomer((prevData) => {
                                if(!_.isEqual(prevData, formData)){
                                    _.forEach(formData, (value, key) => {
                                        if(!_.isEqual(_.get(prevData, key), _.get(formData, key))) _.set(prevData, key, value)
                                    })
                                }
                                
                                return prevData;
                            });
                        }

                        return errors;
                    },
                    async handleSignUp(formData) {
                        console.log({ message: 'Submitted Form Data ...', formData });

                        let { username, password, attributes } = formData;

                        const exists = await customerExists(_.toLower(_.get(customer, 'email')))
                        
                        console.log({ exists })
                        
                        if(exists) return false;

                        const created = await createSbsCustomer();

                        console.log('SBS Customer', created);

                        attributes.profile = created.id;
                        attributes.email = created.email;
                        attributes.name = `${created.firstName} ${created.lastName}`;

                        const { user } = await Auth.signUp({ username, password, attributes });

                        return user;
                    }
                }}
            >
            </Authenticator>

            <ToastContainer />
        </Flex>
    )
}
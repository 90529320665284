import _ from 'lodash';
import Loader from 'react-loading';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Modal, useWindowDimensions } from 'react-native';
import {
  useAuthenticator,
  Flex,
  View,
  Text,
  Link,
  TextField,
  Button,
  Image,
  SelectField
} from '@aws-amplify/ui-react';

import Footer from '../../components/Footer';
import Error404Screen from '../ErrorScreen/404';
import { loadVaultToken, storeVaultToken } from '../../utils/vault';
import PaymentType from '../../components/Modals/CreditCard/PaymentType';
import { AppNavBar, SideBar, BlankOverviewBox } from '../../ui-components';
import {
  Colors,
  Images,
  Strings,
  Variables,
  ScreenNames
} from '../../constants';
import {
  loadCustomer,
  loadCustomerPaymentMethods,
  loadCustomerPaymentMethod,
  updateCustomerPaymentMethod,
  createPaymentMethod,
  loadSubscriptions
} from '../../utils/rest';

export default function PaymentMethodsScreen() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const { user, signOut } = useAuthenticator((context) => [context.user]);

  const [step, setStep] = useState('pay'); //pay, spt, cc, dda, ps, error

  console.log('step:', { step });

  const { height, width } = useWindowDimensions();
  const [isMobile, setIsMobile] = useState(() => {
    if (width < 768) return true;
    else return false;
  });
  useEffect(() => {
    if (width < 768) setIsMobile(true);
    else return setIsMobile(false);
  }, [width]);

  const [customer, setCustomer] = useState({});
  const [modalView, setModalView] = useState(false);
  const [addCardVisible, setAddCardVisible] = useState(false);
  const [addBankVisible, setAddBankVisible] = useState(false);
  const [removePaymentMethodVisible, setRemovePaymentMethodVisible] =
    useState(false);

  const [paymentToken, setPaymentToken] = useState('');

  console.log('paymentToken:', { paymentToken });

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({
    id: '',
    token: '',
    last: '',
    expiration: '',
    brand: '',
    address: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      zip: ''
    }
  });

  const [vaultToken, setVaultToken] = useState(null);
  const [vaultUrl] = useState(_.get(process.env, 'REACT_APP_IFRAME_URL'));

  const [updatePaymentMethodVisible, setUpdatePaymentMethodVisible] =
    useState(false);

  const containerWidth = isMobile ? width / 1.1 : width / 1.2;

  // const toggleSubscriptionItem = (item) => {

  //     if(item.id === showPaymentMethod) setShowSubscription('')
  //     else setShowSubscription(item.id)

  // }

  //PAYMENT METHODS
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [canRemovePaymentMethod] = useState(false);
  //...

  // const removePaymentMethod = (item, canRemove) => {
  //     //PM selected is/not attached to subscription so it can/not be removed.
  //     setCanRemovePaymentMethod(canRemove)

  //     if(item.type === 'bank'){
  //         setSelectedPaymentMethod({
  //             id: '',
  //             type: '',
  //             last4: item.last4,
  //             expMonth: '',
  //             expYear: '',
  //             address: {
  //                 line1: '',
  //                 line2: '',
  //                 city: '',
  //                 state: '',
  //                 zip: ''
  //             },
  //         })
  //     } else {
  //         setSelectedPaymentMethod(item)
  //     }

  //     setModalView(true);
  //     setRemovePaymentMethodVisible(true)
  // }

  const updatePaymentMethod = async (item) => {
    loadCustomerPaymentMethod(customer.id, item.id, (paymentMethod) => {
      if (paymentMethod.success) {
        setSelectedPaymentMethod(paymentMethod);
        setModalView(true);
        setUpdatePaymentMethodVisible(true);
      } else {
        //error
        errorToast();
      }
    });
  };

  const addCard = () => {
    setModalView(true);
    setAddCardVisible(true);
  };

  const addBank = () => {
    setModalView(true);
    setAddBankVisible(true);
  };

  //MODAL FORM SUBMITS
  const [paymentMethodsFormLoading, setPaymentMethodsFormLoading] =
    useState(false);
  const cancelPaymentMethodSubmit = (event) => {
    //Submit form
    setPaymentMethodsFormLoading(true);

    //API
    try {
      setRemovePaymentMethodVisible(false);
      setModalView(false);
      setPaymentMethodsFormLoading(false);
      successToast();
    } catch (e) {
      setPaymentMethodsFormLoading(false);
      errorToast();
    }
  };

  const [cardFormLoading, setCardFormLoading] = useState(false);
  const addCardSubmit = async (event) => {
    event.preventDefault();

    //Submit form
    setCardFormLoading(true);

    let finalizedCard = {};
    //API
    try {
      console.log({ vaultToken });
      finalizedCard = await storeVaultToken(vaultToken);

      console.log({ finalizedCard });
    } catch (vaultError) {
      console.log({ message: 'VAULT ERROR', vaultError });

      return false;
    }

    try {
      let paymentMethod = {
        token: _.get(finalizedCard, 'data.token'),
        last: _.get(finalizedCard, 'data.last'),
        issuer_id: _.get(finalizedCard, 'data.issuer_id'),
        expiration: _.get(finalizedCard, 'data.expiration'),
        brand: _.get(finalizedCard, 'data.brand'),
        customerId: _.get(customer, 'id'),
        primary: true,
        scheme: 'CREDIT',
        category: 'CREDIT',
        billingDetails: {
          address: _.get(finalizedCard, 'data.address'),
          address2: _.get(finalizedCard, 'data.address2'),
          country: _.get(finalizedCard, 'data.country', 'USA'),
          locality: _.get(finalizedCard, 'data.city'),
          region: _.get(finalizedCard, 'data.state'),
          postal: _.get(finalizedCard, 'data.zip')
        }
      };

      paymentMethod = _.omit(paymentMethod, ['scheme', 'type']);

      console.log({ paymentMethod });

      createPaymentMethod(paymentMethod, (pm) => {
        const paymentMethodObject = _.get(pm, 'storePaymentMethod.0');

        if (_.get(paymentMethodObject, 'code') === '432') {
          setCardFormLoading(false);
          errorToast(
            'Failed to Authorize Card (432): Please try another form of payment.'
          );
          console.error('Card Authorization Failed:', paymentMethodObject);
        }

        console.log('createPaymentMethod Results', {
          createResponse: pm,
          original: paymentMethod,
          paymentMethodObject
        });

        _.set(paymentMethod, 'id', _.get(paymentMethodObject, 'id'));

        if (user) {
          loadCustomerPaymentMethods(customer.id, setPaymentMethods);
        } else {
          setPaymentMethods([paymentMethod]);
          setPaymentToken(paymentMethod.token);
        }

        setSelectedPaymentMethod(paymentMethodObject);
        setCardFormLoading(false);
        setStep('pay');
      });

      //successToast()
    } catch (e) {
      console.log(e);
      setCardFormLoading(false);
      errorToast();
    }
  };

  const [bankFormLoading, setBankFormLoading] = useState(false);
  const addBankSubmit = async (event) => {
    event.preventDefault();

    //Submit form
    setBankFormLoading(true);

    let finalizedBank = {};
    //API
    try {
      console.log({ vaultToken });
      finalizedBank = await storeVaultToken(vaultToken);

      console.log({ finalizedBank });
    } catch (vaultError) {
      console.log({ message: 'VAULT ERROR', vaultError });

      return false;
    }

    try {
      let paymentMethod = {
        primary: true,
        customerId: _.get(customer, 'id'),
        last: _.get(finalizedBank, 'data.last'),
        type: _.get(finalizedBank, 'data.type'),
        token: _.get(finalizedBank, 'data.token'),
        brand: _.get(finalizedBank, 'data.brand'),
        routing: _.get(finalizedBank, 'data.routing'),
        scheme: _.toUpper(_.get(finalizedBank, 'data.scheme', 'dda')),
        category: _.toUpper(_.get(finalizedBank, 'data.scheme', 'dda')),
        billingDetails: {
          accountHolder: {
            firstName: _.get(finalizedBank, 'data.firstName'),
            lastName: _.get(finalizedBank, 'data.lastName')
          }
        }
      };

      paymentMethod = _.omit(paymentMethod, ['scheme']);

      console.log({ paymentMethod });

      createPaymentMethod(paymentMethod, (pm) => {
        const paymentMethodObject = _.get(pm, 'storePaymentMethod.0');

        console.log('createPaymentMethod Results', {
          createResponse: pm,
          original: paymentMethod,
          paymentMethodObject
        });

        _.set(paymentMethod, 'id', _.get(paymentMethodObject, 'id'));

        if (user) {
          loadCustomerPaymentMethods(customer.id, setPaymentMethods);
        } else {
          setPaymentMethods([paymentMethod]);
          setPaymentToken(paymentMethod.token);
        }

        setSelectedPaymentMethod(paymentMethodObject);
        setBankFormLoading(false);
        setStep('pay');
      });

      //successToast()
    } catch (error) {
      setBankFormLoading(false);
      console.error(error);
      errorToast(error);
    }
  };

  const [pmExpMonth, setPmExpMonth] = useState('');
  const [pmExpMonthErrorText, setPmExpMonthErrorText] = useState('');
  const [pmExpMonthHasError, setPmExpMonthHasError] = useState(false);
  const [pmExpYear, setPmExpYear] = useState('');
  const [pmExpYearErrorText, setPmExpYearErrorText] = useState('');
  const [pmExpYearHasError, setPmExpYearHasError] = useState(false);
  const [pmAddress, setPmAddress] = useState('');
  const [pmAddressLine2, setPmAddressLine2] = useState('');
  const [pmState, setPmState] = useState('');
  const [pmZip, setPmZip] = useState('');
  const [pmCity, setPmCity] = useState('');
  const [pmFormLoading, setPmFormLoading] = useState(false);
  const updatePaymentMethodFormSubmit = async (event) => {
    event.preventDefault();

    //Submit form

    if (isNaN(pmExpMonth)) {
      setPmExpMonthHasError(true);
      setPmExpMonthErrorText('Must be a number');
      return false;
    }
    setPmExpMonthHasError(false);
    setPmExpMonthErrorText('');

    if (isNaN(pmExpYear)) {
      setPmExpYearHasError(true);
      setPmExpYearErrorText('Must be a number');
      return false;
    }
    setPmExpYearHasError(false);
    setPmExpYearErrorText('');

    //API
    try {
      setPmFormLoading(true);

      let form = {
        customerId: customer.id,
        token: selectedPaymentMethod.token,
        expiration: `${pmExpMonth}${pmExpYear}`,
        billingDetails: {
          address: pmAddress,
          address2: pmAddressLine2,
          country: 'USA',
          locality: pmCity,
          region: pmState,
          postal: pmZip
        }
      };

      await updateCustomerPaymentMethod(form);

      setUpdatePaymentMethodVisible(false);
      setModalView(false);
      setPmFormLoading(false);
      successToast();
    } catch (e) {
      setPmFormLoading(false);
      errorToast();
    }
  };

  //MODALS
  const removePaymentMethodForm = () => {
    return (
      <View alignSelf="center" width={'90%'}>
        <Flex direction={'column'}>
          <Text
            fontFamily="Inter"
            fontSize="32px"
            fontWeight="700"
            color="rgba(13,26,38,1)"
            lineHeight="40px"
            children={Strings.REMOVE_PAYMENT_METHOD}
          />

          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            lineHeight="24px"
            letterSpacing="0.01px"
            children={`${
              canRemovePaymentMethod
                ? Strings.REMOVE_PAYMENT_METHOD_SUBTITLE
                : Strings.CANNOT_REMOVE_PAYMENT_METHOD
            }`}
          />

          <Flex
            direction={'row'}
            marginTop={35}
            justifyContent="flex-end"
            width={'100%'}
          >
            <Button
              variation="link"
              onClick={() => {
                setRemovePaymentMethodVisible(false);
                setModalView(false);
              }}
            >
              {Strings.CLOSE}
            </Button>
            {canRemovePaymentMethod && (
              <Button
                variation="primary"
                onClick={cancelPaymentMethodSubmit}
                isLoading={paymentMethodsFormLoading}
              >
                {Strings.CONFIRM}
              </Button>
            )}
          </Flex>
        </Flex>
      </View>
    );
  };

  const [cardIframeLoaded, setCardIframeLoaded] = useState(false);
  const vaultCardIframe = () => {
    return (
      <>
        {!cardIframeLoaded && (
          <Flex
            direction={'column'}
            width={'100%'}
            justifyContent="center"
            alignItems={'center'}
            height={'600px'}
          >
            <Loader
              type="bubbles"
              color={Colors.loader}
              width={50}
              height={50}
            />
          </Flex>
        )}

        <iframe
          title="card-iframe"
          onLoad={() => setCardIframeLoaded(true)}
          style={{
            width: '100%',
            height: '350px',
            border: 'none'
          }}
          scrolling="no"
          src={`${vaultUrl}/card/${vaultToken}?address=true&firstName=${customer.firstName}&lastName=${customer.lastName}`}
        ></iframe>
      </>
    );
  };

  const [bankIframeLoaded, setBankIframeLoaded] = useState(false);
  const vaultBankIframe = () => {
    return (
      <>
        {!bankIframeLoaded && (
          <Flex
            direction={'column'}
            width={'100%'}
            justifyContent="center"
            alignItems={'center'}
            height={'100px'}
          >
            <Loader
              type="bubbles"
              color={Colors.loader}
              width={50}
              height={50}
            />
          </Flex>
        )}

        <iframe
          title="bank-iframe"
          onLoad={() => setBankIframeLoaded(true)}
          style={{
            width: '100%',
            height: '200px',
            border: 'none'
          }}
          scrolling="no"
          src={`${vaultUrl}/bank/${vaultToken}?firstName=${customer.firstName}&lastName=${customer.lastName}`}
        ></iframe>
      </>
    );
  };

  const addBankForm = () => {
    return (
      <Flex
        as={'form'}
        onSubmit={(e) => addBankSubmit(e)}
        direction={'column'}
        width={'100%'}
        style={{padding: 20}}
      >
        <Text
          fontFamily="Inter"
          fontSize="32px"
          fontWeight="700"
          color="rgba(13,26,38,1)"
          lineHeight="40px"
          children={Strings.BANK_ACCOUNT}
        />

        <View>
          <Link
            onClick={() => setStep('pay')}
            children={Strings.SUBSCRIPTION}
          />{' '}
          /{' '}
          <Link
            onClick={() => setStep('spt')}
            children={Strings.SELECT_PAYMENT_TYPE}
          />{' '}
          / <Link color={'black'} children={Strings.BANK_ACCOUNT} />
        </View>

        <Flex direction={'column'} rowSpan={2} marginTop={30}>
          <View width={'100%'} marginTop={-30}>
            {/* Bank Iframe goes here */}
            {vaultBankIframe()}
          </View>
        </Flex>

        <Flex
          direction={'row'}
          marginTop={35}
          justifyContent="flex-end"
          width={'100%'}
        >
          <Button
            width={120}
            variation="primary"
            type="submit"
            onClick={addBankSubmit}
            disabled={bankFormLoading}
            isLoading={bankFormLoading}
          >
            {!bankFormLoading ? (
              Strings.NEXT
            ) : (
              <Loader
                type="bubbles"
                color={Colors.loader}
                height={25}
                width={25}
              />
            )}
          </Button>
        </Flex>
      </Flex>
    );
  };

  const addCardForm = () => {
    return (
      <Flex
        as={'form'}
        onSubmit={(e) => addCardSubmit(e)}
        direction={'column'}
        width={'100%'}
        style={{padding: 20}}
      >
        <Text
          fontFamily="Inter"
          fontSize="32px"
          fontWeight="700"
          color="rgba(13,26,38,1)"
          lineHeight="40px"
          children={Strings.CREDIT_CARD}
        />

        <View>
          <Link
            onClick={() => setStep('pay')}
            children={Strings.SUBSCRIPTION}
          />{' '}
          /{' '}
          <Link
            onClick={() => setStep('spt')}
            children={Strings.SELECT_PAYMENT_TYPE}
          />{' '}
          / <Link color={'black'} children={Strings.CREDIT_CARD} />
        </View>

        <Image src={Images.CARD_BRANDS} width={width / 9} />

        <Flex direction={'column'} rowSpan={5} marginTop={30}>
          <View width={'100%'} marginTop={-30}>
            {/* Card Iframe goes here */}
            {vaultCardIframe()}
          </View>
        </Flex>

        <Flex
          direction={'row'}
          marginTop={35}
          justifyContent="flex-end"
          width={'100%'}
        >
          <Button
            width={120}
            variation="primary"
            type="submit"
            onClick={addCardSubmit}
            disabled={cardFormLoading}
            isLoading={cardFormLoading}
          >
            {!cardFormLoading ? (
              Strings.NEXT
            ) : (
              <Loader
                type="bubbles"
                color={Colors.loader}
                height={25}
                width={25}
              />
            )}
          </Button>
        </Flex>
      </Flex>
    );
  };

  const updatePaymentMethodModal = () => {
    return (
      <View alignSelf="center" width={'90%'}>
        <Flex
          direction={'column'}
          as={'form'}
          onSubmit={(e) => updatePaymentMethodFormSubmit(e)}
        >
          <Text
            fontFamily="Inter"
            fontSize="32px"
            fontWeight="700"
            color="rgba(13,26,38,1)"
            lineHeight="40px"
            children={Strings.UPDATE_CREDIT_CARD}
          />

          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            lineHeight="24px"
            letterSpacing="0.01px"
            children={Strings.UPDATE_CARD_SUBTITLE}
          />

          <Flex direction={'row'} rowSpan={2} marginTop={30}>
            <View width={'100%'}>
              <PaymentType
                customerId={customer.id}
                paymentMethodId={selectedPaymentMethod.id}
              />
            </View>
          </Flex>

          {/* EXPIRATION */}
          <View marginTop={25}>
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="700"
              lineHeight="20px"
              children={Strings.EXPIRATION}
            />

            <Flex direction={'row'} marginTop={5}>
              <TextField
                isRequired={true}
                label={Strings.MONTH}
                width={isMobile ? '50%' : '25%'}
                placeholder={Strings.MONTH}
                hasError={pmExpMonthHasError}
                errorMessage={pmExpMonthErrorText}
                onChange={(e) => setPmExpMonth(e.currentTarget.value)}
                maxLength={2}
              />
              <TextField
                isRequired={true}
                label={Strings.YEAR}
                width={isMobile ? '50%' : '25%'}
                placeholder={Strings.YEAR}
                onChange={(e) => setPmExpYear(e.currentTarget.value)}
                hasError={pmExpYearHasError}
                errorMessage={pmExpYearErrorText}
                maxLength={2}
                onKeyDown={(e) => console.log(e)}
              />
            </Flex>
          </View>

          {/* ADDRESS INFORMATION */}
          <View marginTop={25}>
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="700"
              lineHeight="20px"
              children={Strings.ADDRESS}
            />

            <TextField
              isRequired={true}
              marginTop={10}
              placeholder={Strings.ADDRESS}
              onChange={(e) => setPmAddress(e.currentTarget.value)}
            />

            <TextField
              marginTop={10}
              placeholder={Strings.ADDRESS_LINE_2}
              onChange={(e) => setPmAddressLine2(e.currentTarget.value)}
            />
            <Flex>
              <TextField
                isRequired={true}
                marginTop={10}
                placeholder={Strings.CITY}
                onChange={(e) => setPmCity(e.currentTarget.value)}
              />
              <SelectField
                isRequired={true}
                marginTop={10}
                variation="default"
                onChange={(e) => setPmState(e.currentTarget.value)}
              >
                {Variables.STATES.map((state) => {
                  return (
                    <option key={state.abbreviation} value={state.abbreviation}>
                      {state.name}
                    </option>
                  );
                })}
              </SelectField>
              <TextField
                isRequired={true}
                marginTop={10}
                placeholder={Strings.ZIP}
                onChange={(e) => setPmZip(e.currentTarget.value)}
              />
            </Flex>
          </View>

          <Flex
            direction={'row'}
            marginTop={35}
            justifyContent="flex-end"
            width={'100%'}
          >
            <Button
              variation="link"
              onClick={() => {
                setUpdatePaymentMethodVisible(false);
                setModalView(false);
              }}
            >
              {Strings.CLOSE}
            </Button>
            <Button variation="primary" type="submit" isLoading={pmFormLoading}>
              {Strings.SAVE}
            </Button>
          </Flex>
        </Flex>
      </View>
    );
  };

  //RENDERS
  const renderPaymentItem = (idx, item) => {
    let lastItem = false;
    if (idx === paymentMethods.length - 1) lastItem = true;
    return (
      <BlankOverviewBox
        key={item.id}
        overrides={{
          BlankOverviewBox: {
            width: '100%',
            borderRadius: lastItem ? '0px 0px 5px 5px' : '0px 0px 0px 0px',
            backgroundColor: Colors.container
          }
        }}
      >
        <Flex direction={'row'} marginLeft={40} marginRight={40} width={'100%'}>
          <Flex
            padding={'15px 0px 15px 0px'}
            direction={'row'}
            height={10}
            width={'100%'}
            alignItems="center"
          >
            <PaymentType customerId={customer.id} paymentMethodId={item.id} />

            {item.brand && (
              <Text
                fontFamily="Inter"
                color={'gray'}
                fontSize="14px"
                fontWeight="700"
                lineHeight="18px"
                letterSpacing="0.41px"
                style={{ cursor: 'pointer' }}
                children={Strings.EDIT}
                onClick={() => updatePaymentMethod(item)}
              ></Text>
            )}
          </Flex>
        </Flex>
      </BlankOverviewBox>
    );
  };

  //ALERTS
  const successToast = () =>
    toast.success(Strings.SUCCESSFULLY_UPDATED, {
      position: 'top-center',
      theme: 'colored'
    });
  const errorToast = () =>
    toast.error(Strings.ERROR_OCCURED, {
      position: 'top-center',
      theme: 'colored'
    });

  //ROUTES
  const goToOverview = () => navigate(ScreenNames.OVERVIEW);
  const gotToSubscriptions = () => navigate(ScreenNames.SUBSCRIPTIONS);
  const goToPaymentMethods = () => navigate(ScreenNames.PAYMENT_METHODS);
  const goToBillingHistory = () => navigate(ScreenNames.BILLING_HISTORY);

  useEffect(() => {
    document.title = Strings.PAYMENT_METHODS;
    loadVaultToken(setVaultToken);
  }, []);

  useEffect(() => {
    loadCustomer(setCustomer);
  }, [user]);

  useEffect(() => {
    const execute = async () => {
      if (!_.has(customer, 'id')) return;
      await loadSubscriptions(customer, setSubscriptions);
      await loadCustomerPaymentMethods(customer.id, setPaymentMethods);
      setLoading(false);
    };

    execute();
  }, [customer]);

  useEffect(() => {
    console.log({ message: 'state updated', vaultToken });
  }, [vaultToken]);

  useEffect(() => {
    console.log({ subscriptions });
  }, [subscriptions]);

  if (_.get(process.env, 'REACT_APP_ALLOW_SIGN_IN') === 'false')
    return <Error404Screen />;

  return (
    <Flex
      direction="column"
      alignItems={'center'}
      height={modalView ? '100vh' : null}
      minHeight="100vh"
      backgroundColor={Colors.surface}
      width={'100%'}
      overflow={'hidden'}
    >
      <AppNavBar
        overrides={{
          AppNavBar: { width: '100%', backgroundColor: Colors.header },
          user: {
            fontFamily: 'Inter-Regular',
            color: Colors.headerText,
            children: `Sign out`,
            onClick: signOut,
            style: { cursor: 'pointer' }
          },
          Logo30873174: { src: process.env.REACT_APP_LOGO_URL },
          image: { src: Images.USER_PLACEHOLDER, style: { display: 'none' } }
        }}
      />

      <Flex
        direction={isMobile ? 'column' : 'row'}
        justifyContent={'space-evenly'}
        width={containerWidth}
        flex={1}
        marginTop={isMobile ? 10 : 25}
        marginBottom={30}
      >
        <Flex direction={'column'} width={isMobile ? '100%' : 313}>
          <SideBar
            overrides={{
              SideBar: { width: '100%', backgroundColor: Colors.container },
              Overview: {},
              OverviewFrame: {
                onClick: goToOverview,
                style: { cursor: 'pointer' }
              },
              Subscriptions: {},
              SubscriptionsFrame: {
                onClick: gotToSubscriptions,
                style: { cursor: 'pointer' }
              },
              'Payment Methods': {
                fontWeight: '600',
                color: Colors.activeText
              },
              PaymentMethodsFrame: {
                onClick: goToPaymentMethods,
                style: { cursor: 'pointer' }
              },
              'Billing History': {},
              BillingHistoryFrame: {
                onClick: goToBillingHistory,
                style: { cursor: 'pointer' }
              }
            }}
          />
        </Flex>
        <Flex
          direction={'column'}
          gap={'0.1rem'}
          width={isMobile ? '100%' : '80%'}
        >
          <BlankOverviewBox
            overrides={{
              BlankOverviewBox: {
                width: '100%',
                borderRadius: '5px 5px 0px 0px',
                marginBottom: 0,
                height: isMobile ? 125 : 100,
                alignItems: 'center',
                backgroundColor: Colors.container
              }
            }}
          >
            <Flex
              direction={isMobile ? 'column' : 'row'}
              marginLeft={40}
              marginRight={40}
              width={'100%'}
              alignItems="center"
            >
              <View width={isMobile ? '100%' : '50%'}>
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="700"
                  lineHeight="20px"
                  letterSpacing="1px"
                  children={Strings.PAYMENT_METHODS.toUpperCase()}
                ></Text>
              </View>
              <Flex
                width={isMobile ? '100%' : '50%'}
                direction="column"
                alignItems={!isMobile && 'flex-end'}
              >
                <Button
                  onClick={addCard}
                  size="small"
                  variation="primary"
                  children={`Add ${Strings.CREDIT_CARD}`}
                ></Button>
                <Button
                  onClick={addBank}
                  size="small"
                  variation="primary"
                  children={`Add ${Strings.BANK_ACCOUNT}`}
                ></Button>
              </Flex>
            </Flex>
          </BlankOverviewBox>

          {/* LOADING TEXT */}
          {loading && (
            <BlankOverviewBox
              overrides={{
                BlankOverviewBox: {
                  width: '100%',
                  borderRadius: '0px 0px 5px 5px',
                  marginTop: 0,
                  backgroundColor: Colors.container
                }
              }}
            >
              <Flex
                direction={'column'}
                width={'100%'}
                justifyContent="center"
                alignItems={'center'}
              >
                <Loader
                  type="bubbles"
                  color={Colors.loader}
                  width={75}
                  height={75}
                />
              </Flex>
            </BlankOverviewBox>
          )}

          {/* NO PAYMENT METHODS TEXT */}
          {!loading && _.size(paymentMethods) === 0 && (
            <BlankOverviewBox
              overrides={{
                BlankOverviewBox: {
                  width: '100%',
                  borderRadius: '0px 0px 5px 5px',
                  marginTop: 0,
                  backgroundColor: Colors.container
                }
              }}
            >
              <Flex marginLeft={40} marginRight={40} width={'100%'}>
                <Text
                  fontFamily="Inter"
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="20px"
                  letterSpacing="1px"
                  children={Strings.NO_PAYMENT_METHODS_LISTED}
                ></Text>
              </Flex>
            </BlankOverviewBox>
          )}

          {/* PAYMENT METHODS */}
          {paymentMethods.map((item, idx) => {
            return renderPaymentItem(idx, item);
          })}
        </Flex>
      </Flex>

      <Modal visible={modalView} animationType="slide" transparent>
        <BlankOverviewBox
          overrides={{
            BlankOverviewBox: {
              width: isMobile ? width / 1.1 : width / 3,
              alignSelf: 'center',
              marginTop: isMobile ? 20 : 50,
              alignContent: 'center',
              justifyContent: 'center'
            }
          }}
        >
          {removePaymentMethodVisible && removePaymentMethodForm()}
          {updatePaymentMethodVisible && updatePaymentMethodModal()}
          {addCardVisible && addCardForm()}
          {addBankVisible && addBankForm()}
        </BlankOverviewBox>
      </Modal>

      {modalView && (
        <View
          height={'100%'}
          width={width}
          backgroundColor={'rgba(0,0,0,0.5)'}
          top={0}
          left={0}
          bottom={0}
          position="absolute"
        />
      )}

      <ToastContainer />

      <Footer />
    </Flex>
  );
}


import { Flex, Text } from '@aws-amplify/ui-react';
import { useWindowDimensions } from 'react-native';

import { AppNavBar } from '../../ui-components';
import { Colors, Images } from '../../constants';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function Error404Screen() {
    const [error, setError] = useState({
        title: 'Oops! Something went wrong.',
        message: 'Please try again later or contact support.'
    });
    // Get the error code from the URL - from useLocation
    const location = useLocation();
    const errorCode = location.pathname.split('/').pop();  
    const { height } = useWindowDimensions();

    useEffect(() => {
        switch (errorCode) {
            case '404':
                setError({
                    title: 'Oops! Something went wrong.',
                    message: 'Please try again later or contact support.'
                });
                break;
            default:
                break;
        };
    }, [errorCode]);

    return (
        <Flex direction="column" justifyContent="center" alignItems="center" style={{ height: height, backgroundColor: Colors.surface }} >
            <AppNavBar overrides={{ 
                'AppNavBar':  { width: '100%', position: 'absolute', top: 0 },
                'Logo30873174':  { src: process.env.REACT_APP_LOGO_URL, },
                'image':  { src: Images.USER_PLACEHOLDER, },
            }}/>
            <Text variant="h1" color={Colors.black}>{error.title}</Text>
            <Text variant="p" color={Colors.black}>{error.message}</Text>
        </Flex>
    );
}
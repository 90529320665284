import _ from 'lodash';

export const Strings = {
  SIGN_UP: 'Sign Up',
  SIGN_IN: 'Sign In',
  EMAIL: 'Email',
  PASSWORD: 'Password',
  PASSWORD_CONFIRM: 'Password Confirmation',
  LETS_CREATE_ACCOUNT: `Let's create your account.`,
  CREATING_ACCOUNT: 'Creating Account',
  ACCOUNT_INFORMATION: 'Account Information',
  SUBSCRIPTIONS: 'Subscriptions',
  VIEW_DETAILS: 'View Details',
  CANCEL_SUBSCRIPTION: 'Cancel Subscription',
  CANCEL_SUBSCRIPTION_SUBTITLE:
    'Are you sure you want to cancel this subscription? You will no longer be billed starting on the next billing cycle',
  PERSONAL_INFO: 'Personal Information',
  CHARGES: 'Charges',
  CLOSE: 'Close',
  SUBMIT: 'Submit',
  LOADING: 'Loading',
  CREDIT_CARD: 'Credit Card',
  UPDATE_CREDIT_CARD: 'Update Credit Card',
  EXPIRATION: 'Expiration',
  CARD_INFORMATION: 'Card Information',
  ADDRESS: 'Address',
  ADDRESS_LINE_2: 'Address Line 2',
  ZIP: 'Zip',
  STATE: 'State',
  CITY: 'City',
  CANCEL: 'Cancel',
  SAVE: 'Save',
  SUCCESSFULLY_UPDATED: 'Successfully Updated',
  ERROR_OCCURED: 'An error occured',
  MONTH: 'Month',
  YEAR: 'Year',
  REQUIRED_FIELD: 'Required field',
  UPDATE_CARD_SUBTITLE: `Information below related to your credit card may be updated here. If you've received a new account number, please add as a new card.`,
  NAME: 'Name',
  FULL_NAME: 'Full Name',
  EMAIL_ADDRESS: 'Email Address',
  PHONE_NUMBER: 'Phone Number',
  PHONE: 'Phone',
  SEND: 'Send',
  SEND_EMAIL_VERIFICATION: 'Send Email Verification',
  PASSWORD_RESET: 'Password Reset',
  PASSWORD_RESET_SUBTITLE: 'Send an email reset verification link.',
  CONFIRM: 'Confirm',
  NO_SUBSCRIPTIONS_USER: `You don't have any subscriptions.`,
  BILLING_FREQUENCY: 'Billing Frequency',
  PAST_CHARGES: 'Past Charges',
  UPCOMING_CHARGES: 'Upcoming Charges',
  UPCOMING_CHARGE: 'Upcoming Charge',
  CHARGE: 'Charge',
  PAYMENT_METHODS: 'Payment Methods',
  NO_PAYMENT_METHODS_LISTED: 'No payment methods listed.',
  EXPIRES_ON: 'Expires on',
  REMOVE: 'Remove',
  EDIT: 'Edit',
  STATUS: 'Status',
  REMOVE_PAYMENT_METHOD: 'Remove Payment Method',
  REMOVE_PAYMENT_METHOD_SUBTITLE:
    'Are you sure you want to remove this payment method?',
  ADD_PAYMENT_METHOD: 'Add Payment Method',
  BANK: 'Bank',
  ACCOUNT_NUMBER: 'Account Number',
  ROUTING_NUMBER: 'Routing Number',
  BANK_ACCOUNT: 'Bank Account',
  CHECKING: 'Checking',
  SAVINGS: 'Savings',
  BUSINESS: 'Business',
  BILLING_HISTORY: 'Billing History',
  NO_BILLING_HISTORY: 'No billing history listed.',
  DATE: 'Date',
  SUBSCRIPTION: 'Subscription',
  ACTIVITY: 'Activity',
  AMOUNT: 'Amount',
  TRANSACTIONS: 'Transactions',
  TRANSACTION: 'Transaction',
  OF: 'of',
  PAY_NOW: 'Pay Now',
  PROCESSING: 'Processing',
  CUSTOMER: 'Customer',
  INVOICE: 'Invoice',
  SUPPORT: 'Support',
  TOTAL: 'Total',
  SUBTOTAL: 'Subtotal',
  SELECT_PAYMENT_METHOD: 'Select payment method',
  SELECT_PAYMENT_TYPE: 'Select Payment Type',
  NEXT: 'Next',
  PAYMENT_SUCCESSFUL: 'Payment Successful',
  THANK_YOU_FOR_YOUR_PAYMENT: 'Thank you for your payment.',
  DONE: 'Done',
  SELECT_TO_PAY: 'Select to pay make payment',
  SUBSCRIPTION_SERVICES: 'Subscription Services',
  SUBSCRIBE_NOW: 'Subscribe Now',
  SIGN_OUT: 'Sign Out',
  SEE_YOU_LATER: 'See you later',
  SEE_YOU_NEXT_TIME: 'See you next time',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  COMPANY: 'Company',
  COMPANY_NAME: 'Company Name',
  ENDING_IN: 'Ending in',
  CARD: 'Card',
  VIEW_CHARGES: 'View Charges',
  VIEW_HISTORY: 'View History',
  UPDATE_PAYMENT: 'Update Payment',
  PAYMENT_METHOD: 'Payment Method',
  UPDATE_INFORMATION: 'Update Information',
  UDPATE_EMAIL: 'Update Email',
  UPDATE_PHONE: 'Update Phone',
  UPDATE_PASSWORD: 'Update Password',
  ENROLL_NOW: 'Enroll Now',
  SELECT_MAINTENANCE_PLAN: _.get(process.env, 'REACT_APP_CLIENT_LANDING_VERBAGE_TITLE', ''),
  SELECT_MAINTENANCE_PLAN_DESCRIPTION: _.get(process.env, 'REACT_APP_CLIENT_LANDING_VERBAGE_CONTENT', ''),
  OLD_PASSWORD: 'Old Password',
  NEW_PASSWORD: 'New Password',
  ACCOUNT_HOLDER: 'Account Holder',
  CARD_HOLDER: 'Card Holder',
  CANNOT_REMOVE_PAYMENT_METHOD:
    'This payment method is currently attached to a subscription and cannot be removed.  Please, change your subscription payment method first.',
  CREATE_ACCOUNT: 'Create Account',
  SUBSCRIBE: 'Subscribe',
  PAY_INVOICE: 'Pay Invoice',
  PAYMENT_FAILED: 'Payment Failed',
  UNABLE_TO_PROCESS_PAYMENT:
    'We were not able to process your payment.  Please provide an alternative method of payment.',
  TRY_AGAIN: 'Try Again',
  ADD_SUBSCRIPTION: 'Add Subscription',
  SELECT_A_SUBSCRIPTION: 'Select a Subscription',
  QUANTITY: 'Quantity',
  ADD_SERVICE_ADDRESS: 'Add Service Address',
  EDIT_SERVICE_ADDRESS: 'Edit Service Address',
  SERVICE_ADDRESS: 'Service Address',
  PLACE: 'Place',
  TERMS_CONDITIONS: 'Terms and Conditions',
  AGREE: 'Agree',
  NICKNAME: 'Nickname',
  REFERENCE_NAME: 'A name for referencing this address.',
  DESCRIPTION: 'Description',
  SALES_TAX: 'Sales Tax',
  UNPAID_BALANCE: 'Unpaid Balance',
  ATTACHMENT: 'Attachment'
};

export default Strings;

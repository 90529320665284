const _ = require('lodash');

/**
 * Asserts that the value of the "variable" argument is a properly formatted JSON String
 * @param {*} variable
 * @returns {boolean}
 */
const isJson = (variable) => {
  try {
    JSON.parse(variable);
  } catch (e) {
    return undefined;
  }

  return true;
};

/**
 * Asserts that the value of the "variable" argument is a properly formatted JSON String
 * Clones: isJson
 * @param {*} variable
 * @returns {boolean}
 */
const isJSON = isJson;

/**
 * Asserts that the value of the "value" argument is an array or (plain) object
 * @param {*} value
 * @returns {boolean}
 */
const isDeep = (value) => _.isPlainObject(value) || _.isArray(value);

/**
 * Parses the provided "value" IF it contains a valid JSON string, otherwise returns value
 * @param {*} value
 * @returns {*}
 */
const jsonParse = (value) => {
  if (isJson(value)) return JSON.parse(value);

  return value;
};

/**
 * Parses the provided "value" IF it contains a valid JSON string, otherwise returns value
 * Clones: jsonParse
 * @param {*} value
 * @returns {*}
 */
const fromJSON = jsonParse;

/**
 * Parses the provided "value" IF it contains a valid JSON string, otherwise returns value
 * Clones: jsonParse
 * @param {*} value
 * @returns {*}
 */
const readJSON = jsonParse;

/**
 * Parses the provided "value" IF it contains a valid JSON string, otherwise returns value
 * Clones: jsonParse
 * @param {*} value
 * @returns {*}
 */
const parseJson = jsonParse;

/**
 * Transforms the provided "value" into a valid JSON string,
 * IF it isDeep (see above), otherwise returns value
 * @param {*} value
 * @returns {*}
 */
const jsonStringify = (value, space, replacer) => {
  if (isDeep(value)) {
    return JSON.stringify(value, replacer, space);
  }

  return JSON.stringify(value);
};

/**
 * Transforms the provided "value" into a valid JSON string,
 * IF it isDeep (see above), otherwise returns value
 * Clones: jsonStringify
 * @param {*} value
 * @returns {*}
 */
const toJSON = jsonStringify;

/**
 * Transforms the provided "value" into a valid JSON string,
 * IF it isDeep (see above), otherwise returns value
 * Clones: jsonStringify
 * @param {*} value
 * @returns {*}
 */
const writeJSON = jsonStringify;

/**
 * Transforms the provided "value" into a valid JSON string,
 * IF it isDeep (see above), otherwise returns value
 * Clones: jsonStringify
 * @param {*} value
 * @returns {*}
 */
const smashJson = jsonStringify;

/**
 * Transforms the keys within the provided "object" into their coresponding camelCase values
 * Providing "deep" as false will only perform key transformations within the object root
 * @param {*} object
 * @param {boolean} deep
 * @returns {*}
 */
const camelKeys = (object, deep = true) => _.reduce(
  object,
  (output, value, key) => {
    if (deep === true && isDeep(value)) {
      return _.set(output, _.camelCase(key), camelKeys(value, deep));
    }

    return _.set(output, _.camelCase(key), value);
  },
  {}
);

/**
 * Transforms the keys within the provided "object" into their coresponding kebab-case values
 * Providing "deep" as false will only perform key transformations within the object root
 * @param {*} object
 * @param {boolean} deep
 * @returns {*}
 */
const kebabKeys = (object, deep = true) => _.reduce(
  object,
  (output, value, key) => {
    if (deep === true && isDeep(value)) {
      return _.set(output, _.kebabCase(key), kebabKeys(value, deep));
    }

    return _.set(output, _.kebabCase(key), value);
  },
  {}
);

/**
 * Transforms the keys within the provided "object" into their coresponding lower case values
 * Providing "deep" as false will only perform key transformations within the object root
 * @param {*} object
 * @param {boolean} deep
 * @returns {*}
 */
const lowerKeys = (object, deep = true) => _.reduce(
  object,
  (output, value, key) => {
    if (deep === true && isDeep(value)) {
      return _.set(output, _.lowerCase(key), lowerKeys(value, deep));
    }

    return _.set(output, _.lowerCase(key), value);
  },
  {}
);

/**
 * Transforms the keys within the provided "object" into their coresponding snake_case values
 * Providing "deep" as false will only perform key transformations within the object root
 * @param {*} object
 * @param {boolean} deep
 * @returns {*}
 */
const snakeKeys = (object, deep = true) => _.reduce(
  object,
  (output, value, key) => {
    if (deep === true && isDeep(value)) {
      return _.set(output, _.snakeCase(key), snakeKeys(value, deep));
    }

    return _.set(output, _.snakeCase(key), value);
  },
  {}
);

/**
 * Transforms the keys within the provided "object" into their coresponding Start Case values
 * Providing "deep" as false will only perform key transformations within the object root
 * @param {*} object
 * @param {boolean} deep
 * @returns {*}
 */
const startKeys = (object, deep = true) => _.reduce(
  object,
  (output, value, key) => {
    if (deep === true && isDeep(value)) {
      return _.set(output, _.startCase(key), startKeys(value, deep));
    }

    return _.set(output, _.startCase(key), value);
  },
  {}
);

/**
 * Transforms the keys within the provided "object" into their coresponding UPPER CASE values
 * Providing "deep" as false will only perform key transformations within the object root
 * @param {*} object
 * @param {boolean} deep
 * @returns {*}
 */
const upperKeys = (object, deep = true) => _.reduce(
  object,
  (output, value, key) => {
    if (deep === true && isDeep(value)) {
      return _.set(output, _.upperCase(key), upperKeys(value, deep));
    }

    return _.set(output, _.upperCase(key), value);
  },
  {}
);

/**
 * Transforms the keys within the provided "object" into their coresponding Title Case values
 * Providing "deep" as false will only perform key transformations within the object root
 * Clones: startKeys
 * @param {*} object
 * @param {boolean} deep
 * @returns {*}
 */
const titleKeys = startKeys;

/**
 * Transforms the provided "string" into its coresponding Title Case value
 * Clones: startCase
 * @param {*} string
 * @returns {*}
 */
const titleCase = _.startCase;

/**
 * Iterates through the given "array" building a list of promises,
 * then enacts Promise.allSettled and returns the results
 * @param {*} string
 * @returns {Promise|[]}
 */
const forEachPromise = async (array, promise, context = {}) => {
  const mapPromises = _.reduce(array, (promises, value, key) => [
    ...promises,
    promise(value, key, context)
  ], []);

  return Promise.allSettled(mapPromises);
};

const mixins = {
  isJson,
  isJSON,
  isDeep,
  toJSON,
  fromJSON,
  readJSON,
  writeJSON,
  camelKeys,
  kebabKeys,
  lowerKeys,
  snakeKeys,
  startKeys,
  upperKeys,
  titleKeys,
  titleCase,
  parseJson,
  smashJson,
  jsonParse,
  jsonStringify,
  forEachPromise
};

// Add custom methods as lodash "mixins"
_.mixin(mixins, { chain: false });

// Export lodash including custom mixins
export default _;

import _ from 'lodash';
import * as dayjs from 'dayjs';
import Loader from 'react-loading';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Modal, useWindowDimensions } from 'react-native';
import {
  useAuthenticator,
  Flex,
  Text,
  View,
  Button,
  TextField,
  SelectField
} from '@aws-amplify/ui-react';

import Footer from '../../components/Footer';
import Error404Screen from '../ErrorScreen/404';
import { loadVaultTokenDetails } from '../../utils/vault';
import PaymentType from '../../components/Modals/CreditCard/PaymentType';
import {
  Colors,
  Images,
  Strings,
  Variables,
  ScreenNames
} from '../../constants';
import {
  loadCustomer,
  updateCustomer,
  loadSubscriptions,
  loadCustomerPaymentMethods,
  cancelCustomerSubscription
} from '../../utils/rest';
import {
  AppNavBar,
  SideBar,
  BlankOverviewBox,
  SubscriptionLineItem,
  SubscriptionLineItemInset,
  Title
} from '../../ui-components';

export default function OverviewScreen() {
  const { height, width } = useWindowDimensions();
  const [isMobile, setIsMobile] = useState(() => {
    if (width < 768) return true;
    return false;
  });
  useEffect(() => {
    if (width < 768) setIsMobile(true);
    else return setIsMobile(false);
  }, [width]);

  const navigate = useNavigate();
  const { user } = useAuthenticator((context) => [context.user]);

  const signOut = async () => {
    try {
      await Auth.signOut({ global: true });
    } catch (error) {
      console.log({ message: 'Error when signing out: ', error });
    }
  };

  const [chargesModalVisible, setChargesModalVisible] = useState(false);
  const [updatePaymentMethodVisible, setUpdatePaymentMethodVisible] =
    useState(false);
  const [
    updatePersonalInformationVisible,
    setUpdatePersonalInformationVisible
  ] = useState(false);
  const [updateEmailVisible, setUpdateEmailVisible] = useState(false);
  const [updatePhoneVisible, setUpdatePhoneVisible] = useState(false);
  const [updatePasswordVisible, setUpdatePasswordVisible] = useState(false);
  const [cancelSubscriptionVisible, setCancelSubscriptionVisible] =
    useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);

  const [modalView, setModalView] = useState(false);

  // UPCOMING CHARGES
  const [paymentMethod, setPaymentMethod] = useState({
    id: 1,
    type: 'card',
    last: '4568',
    expMonth: '03',
    expYear: '2028',
    address: {
      line1: '456 Main St.',
      line2: '',
      city: 'Chicago',
      state: 'IL',
      zip: '60201'
    }
  });
  const [upcomingCharges] = useState([
    {
      id: 2,
      title: 'Lawn Care +',
      description: 'Trim bushes, cut lawn, and tree cutting',
      amount: 79.99,
      products: [
        {
          id: 1,
          title: 'Bush Trims',
          description: 'Grass, bushes and weeds',
          amount: 39.99
        },
        {
          id: 2,
          title: 'Tree Trims',
          description: 'Grass, bushes and weeds',
          amount: 49.99
        }
      ]
    }
  ]);
  // ...

  // MY ACCOUNT
  const [customer, setCustomer] = useState({});
  const [originalCustomer, setOriginalCustomer] = useState({});
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [passwordResetError, setPasswordResetError] = useState('');

  // SUBSCRIPTIONS
  const [subscriptions, setSubscriptions] = useState([]);
  const [subscription, setSubscription] = useState({});

  const containerWidth = isMobile ? width / 1.1 : width / 1.2;

  const viewCharges = () => {
    // setModalView(true);
    // setChargesModalVisible(true);
    goToBillingHistory();
  };

  const updatePayment = () => {
    setModalView(true);
    setUpdatePaymentMethodVisible(true);
  };

  const updatePersonalInformation = () => {
    setCustomer(originalCustomer);
    setModalView(true);
    setUpdatePersonalInformationVisible(true);
  };

  const updateEmail = () => {
    setCustomer(originalCustomer);
    setModalView(true);
    setUpdateEmailVisible(true);
  };

  const updatePhone = () => {
    setCustomer(originalCustomer);
    setModalView(true);
    setUpdatePhoneVisible(true);
  };

  const updatePassword = () => {
    setModalView(true);
    setUpdatePasswordVisible(true);
  };
  const cancelSubscription = (subscription) => {
    setSubscription(subscription);
    setModalView(true);
    setCancelSubscriptionVisible(true);
  };

  // MODAL FORMS
  const charges = (charges) => (
    <View alignSelf="center" width={'90%'}>
      <Title
        overrides={{
          Title: { width: 0, height: 30 },
          Titlefxi: { position: 'relative', children: Strings.CHARGES }
        }}
      />

      <View marginTop={25}>
        {charges.map((i, idx) => {
          // Get product items
          const productItems = (products) =>
            products.map((i, idx) => {
              if (isMobile) {
                return (
                  <Flex key={idx} direction={'row'} justifyContent={'flex-end'}>
                    <SubscriptionLineItemInset
                      marginBottom={15}
                      right={0}
                      width={'90%'}
                      overrides={{
                        Title: { children: i.title },
                        Description: { children: i.description, width: '100%' },
                        Amount: {
                          children: `$${i.amount}`,
                          right: 0,
                          textAlign: 'left'
                        }
                      }}
                    />
                  </Flex>
                );
              }
              return (
                <Flex key={idx} direction={'row'} justifyContent={'flex-end'}>
                  <SubscriptionLineItemInset
                    marginBottom={25}
                    right={0}
                    width={'90%'}
                    overrides={{
                      Title: { children: i.title },
                      Description: { children: i.description },
                      Amount: { children: `$${i.amount}` }
                    }}
                  />
                </Flex>
              );
            });

          if (isMobile) {
            return (
              <View key={idx} marginBottom={15}>
                <SubscriptionLineItem
                  marginBottom={0}
                  overrides={{
                    Title: { children: i.description },
                    Subtitle: { children: i.description, width: '100%' },
                    Amount: {
                      children: `$${i.amount}`,
                      right: width / 1,
                      textAlign: 'right'
                    },
                    Action: { children: '' },
                    Action2: { children: '' }
                  }}
                />
                {productItems(i.products)}
              </View>
            );
          }
          return (
            <View key={idx} marginBottom={15}>
              <SubscriptionLineItem
                marginBottom={0}
                overrides={{
                  SubscriptionLineItem: { width: '100%' },
                  Title: { children: i.description },
                  Subtitle: { children: i.description, width: '100%' },
                  Amount: {
                    children: `$${i.amount}`,
                    right: 0,
                    position: 'absolute'
                  },
                  Action: { children: null },
                  Action2: { children: null }
                }}
              />
              {productItems(i.products)}
            </View>
          );
        })}
      </View>

      <Flex
        direction={'row'}
        marginTop={200}
        justifyContent="flex-end"
        width={'100%'}
      >
        <Button
          variation="link"
          onClick={() => {
            setChargesModalVisible(false);
            setModalView(false);
          }}
        >
          {Strings.CLOSE}
        </Button>
      </Flex>
    </View>
  );

  const updatePaymentMethod = () => (
    <View alignSelf="center" width={'90%'}>
      <Flex
        direction={'column'}
        as={'form'}
        onSubmit={(e) => updatePaymentMethodFormSubmit(e)}
      >
        <Text
          fontFamily="Inter"
          fontSize="32px"
          fontWeight="700"
          color="rgba(13,26,38,1)"
          lineHeight="40px"
          children={Strings.UPDATE_CREDIT_CARD}
        />

        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="400"
          lineHeight="24px"
          letterSpacing="0.01px"
          children={Strings.UPDATE_CARD_SUBTITLE}
        />

        <Flex direction={'row'} rowSpan={2} marginTop={30}>
          <View width={'50%'}>
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="700"
              lineHeight="20px"
              children={Strings.CARD_INFORMATION}
            />
            <Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              lineHeight="24px"
              children={`${
                paymentMethod.type.charAt(0).toUpperCase() +
                paymentMethod.type.slice(1)
              } ending in ${paymentMethod.last}`}
              marginTop={10}
            />
          </View>
          <View width={'50%'}>
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="700"
              lineHeight="20px"
              children={Strings.EXPIRATION}
            ></Text>
            <Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="400"
              lineHeight="24px"
              children={`${paymentMethod.expMonth}/${paymentMethod.expYear}`}
              marginTop={10}
            />
          </View>
        </Flex>

        {/* EXPIRATION */}
        <View marginTop={25}>
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="700"
            lineHeight="20px"
            children={Strings.EXPIRATION}
          />

          <Flex direction={'row'} marginTop={5}>
            <TextField
              isRequired={true}
              label={Strings.MONTH}
              width={isMobile ? '50%' : '25%'}
              placeholder={Strings.MONTH}
              onChange={(e) => setPmExpMonth(e.currentTarget.value)}
              type="number"
              maxLength={2}
            />
            <TextField
              isRequired={true}
              label={Strings.YEAR}
              width={isMobile ? '50%' : '25%'}
              placeholder={Strings.YEAR}
              onChange={(e) => setPmExpYear(e.currentTarget.value)}
              type="number"
              minLength={4}
            />
          </Flex>
        </View>

        {/* ADDRESS INFORMATION */}
        <View marginTop={25}>
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="700"
            lineHeight="20px"
            children={Strings.ADDRESS}
          />

          <TextField
            isRequired={true}
            marginTop={10}
            placeholder={Strings.ADDRESS}
            defaultValue={paymentMethod.address.line1}
            onChange={(e) => setPmAddress(e.currentTarget.value)}
          />

          <TextField
            marginTop={10}
            placeholder={Strings.ADDRESS_LINE_2}
            defaultValue={paymentMethod.address.line2}
            onChange={(e) => setPmAddressLine2(e.currentTarget.value)}
          />
          <Flex>
            <TextField
              isRequired={true}
              marginTop={10}
              placeholder={Strings.CITY}
              defaultValue={paymentMethod.address.city}
              onChange={(e) => setPmCity(e.currentTarget.value)}
            />
            <SelectField
              isRequired={true}
              marginTop={10}
              variation="default"
              defaultValue={paymentMethod.address.state}
              onChange={(e) => setPmState(e.currentTarget.value)}
            >
              {Variables.STATES.map((state) => (
                <option key={state.abbreviation} value={state.abbreviation}>
                  {state.name}
                </option>
              ))}
            </SelectField>
            <TextField
              isRequired={true}
              marginTop={10}
              placeholder={Strings.ZIP}
              defaultValue={paymentMethod.address.zip}
              onChange={(e) => setPmZip(e.currentTarget.value)}
            />
          </Flex>
        </View>

        <Flex
          direction={'row'}
          marginTop={35}
          justifyContent="flex-end"
          width={'100%'}
        >
          <Button
            variation="link"
            onClick={() => {
              setUpdatePaymentMethodVisible(false);
              setModalView(false);
            }}
          >
            {Strings.CLOSE}
          </Button>
          <Button variation="primary" type="submit" isLoading={pmFormLoading}>
            {Strings.SAVE}
          </Button>
        </Flex>
      </Flex>
    </View>
  );
  const updatePersonalInformationForm = () => (
    <View alignSelf="center" width={'90%'}>
      <Flex
        direction={'column'}
        as={'form'}
        onSubmit={(e) => updatePersonalInformationSubmit(e)}
      >
        <Text
          fontFamily="Inter"
          fontSize="32px"
          fontWeight="700"
          color="rgba(13,26,38,1)"
          lineHeight="40px"
          children={Strings.PERSONAL_INFO}
        />

        {/* FIRST NAME */}
        <View marginTop={20}>
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="700"
            lineHeight="20px"
            children={Strings.FIRST_NAME}
          />

          <TextField
            name="firstName"
            isRequired={true}
            width={isMobile ? '100%' : '50%'}
            placeholder={Strings.FIRST_NAME}
            value={customer.firstName}
            onChange={(e) =>
              setCustomer({ ...customer, firstName: e.currentTarget.value })
            }
          />
        </View>
        {/* LAST NAME */}
        <View>
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="700"
            lineHeight="20px"
            children={Strings.LAST_NAME}
          />

          <TextField
            name="lastName"
            isRequired={true}
            width={isMobile ? '100%' : '50%'}
            placeholder={Strings.LAST_NAME}
            value={customer.lastName}
            onChange={(e) =>
              setCustomer({ ...customer, lastName: e.currentTarget.value })
            }
          />
        </View>

        {/* ADDRESS INFORMATION */}
        <View marginTop={25}>
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="700"
            lineHeight="20px"
            children={Strings.ADDRESS}
          />

          <TextField
            name="address"
            isRequired={true}
            marginTop={10}
            placeholder={Strings.ADDRESS}
            onChange={(e) =>
              setCustomer({ ...customer, address: e.currentTarget.value })
            }
            value={customer.address}
          />

          <Flex direction={'row'}>
            <TextField
              name="address2"
              marginTop={10}
              placeholder={Strings.ADDRESS_LINE_2}
              onChange={(e) =>
                setCustomer({ ...customer, address2: e.currentTarget.value })
              }
              value={customer.address2}
              width={'100%'}
            />
            <TextField
              name="locality"
              isRequired={true}
              marginTop={10}
              placeholder={Strings.CITY}
              onChange={(e) =>
                setCustomer({ ...customer, locality: e.currentTarget.value })
              }
              value={customer.locality}
              width={'100%'}
            />
          </Flex>
          <Flex direction={'row'}>
            <SelectField
              name="region"
              isRequired={true}
              marginTop={10}
              defaultValue={''}
              variation="default"
              // value={customer.region}
              onChange={(e) =>
                setCustomer({ ...customer, region: e.currentTarget.value })
              }
              width={'33%'}
            >
              <option key={'none'} value={''}>
                {'State'}
              </option>
              {Variables.STATES.map((state) => (
                <option key={state.abbreviation} value={state.abbreviation}>
                  {state.abbreviation}
                </option>
              ))}
            </SelectField>
            <TextField
              name="postal"
              isRequired={true}
              marginTop={10}
              value={customer.postal}
              placeholder={Strings.ZIP}
              onChange={(e) =>
                setCustomer({ ...customer, postal: e.currentTarget.value })
              }
              width={'33%'}
            />
            <SelectField
              name="region"
              isRequired={true}
              marginTop={10}
              variation="default"
              defaultValue={''}
              value={customer.country}
              onChange={(e) =>
                setCustomer({ ...customer, country: e.currentTarget.value })
              }
              width={'33%'}
            >
              <option key={'none'} value={''}>
                {'Country'}
              </option>
              {Variables.COUNTRIES.map((country) => (
                <option key={country.code} value={country.code}>
                  {country.code}
                </option>
              ))}
            </SelectField>
          </Flex>
        </View>

        <Flex
          direction={'row'}
          marginTop={35}
          justifyContent="flex-end"
          width={'100%'}
        >
          <Button
            variation="link"
            onClick={() => {
              setUpdatePersonalInformationVisible(false);
              setModalView(false);
            }}
          >
            {Strings.CLOSE}
          </Button>
          <Button
            variation="primary"
            type="submit"
            isLoading={customerFormLoading}
          >
            {Strings.SAVE}
          </Button>
        </Flex>
      </Flex>
    </View>
  );
  const updateEmailForm = () => (
    <View alignSelf="center" width={'90%'}>
      <Flex
        direction={'column'}
        as={'form'}
        onSubmit={(e) => updateEmailSubmit(e)}
      >
        <Text
          fontFamily="Inter"
          fontSize="32px"
          fontWeight="700"
          color="rgba(13,26,38,1)"
          lineHeight="40px"
          children={Strings.EMAIL_ADDRESS}
        />

        {/* FULL NAME */}
        <View marginTop={25}>
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="700"
            lineHeight="20px"
            children={Strings.EMAIL}
          />

          <TextField
            marginTop={5}
            isRequired={true}
            width={'100%'}
            placeholder={Strings.EMAIL_ADDRESS}
            defaultValue={customer.email}
            onChange={(e) =>
              setCustomer({ ...customer, email: e.currentTarget.value })
            }
          />
        </View>

        <Flex
          direction={'row'}
          marginTop={35}
          justifyContent="flex-end"
          width={'100%'}
        >
          <Button
            variation="link"
            onClick={() => {
              setUpdateEmailVisible(false);
              setModalView(false);
            }}
          >
            {Strings.CLOSE}
          </Button>
          <Button
            variation="primary"
            type="submit"
            isLoading={emailFormLoading}
          >
            {Strings.SAVE}
          </Button>
        </Flex>
      </Flex>
    </View>
  );
  const updatePhoneForm = () => (
    <View alignSelf="center" width={'90%'}>
      <Flex
        direction={'column'}
        as={'form'}
        onSubmit={(e) => updatePhoneSubmit(e)}
      >
        <Text
          fontFamily="Inter"
          fontSize="32px"
          fontWeight="700"
          color="rgba(13,26,38,1)"
          lineHeight="40px"
          children={Strings.PHONE_NUMBER}
        />

        {/* PHONE NUMBER */}
        <View marginTop={25}>
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="700"
            lineHeight="20px"
            children={Strings.PHONE}
          />

          <TextField
            marginTop={5}
            isRequired={true}
            width={'100%'}
            placeholder={Strings.PHONE_NUMBER}
            defaultValue={customer.phone}
            maxLength={10}
            onChange={(e) =>
              setCustomer({ ...customer, phone: e.currentTarget.value })
            }
          />
        </View>

        <Flex
          direction={'row'}
          marginTop={35}
          justifyContent="flex-end"
          width={'100%'}
        >
          <Button
            variation="link"
            onClick={() => {
              setUpdatePhoneVisible(false);
              setModalView(false);
            }}
          >
            {Strings.CLOSE}
          </Button>
          <Button
            variation="primary"
            type="submit"
            isLoading={phoneFormLoading}
          >
            {Strings.SAVE}
          </Button>
        </Flex>
      </Flex>
    </View>
  );
  const cancelSubscriptionForm = () => (
    <View alignSelf="center" width={'90%'}>
      <Flex direction={'column'}>
        <Text
          fontFamily="Inter"
          fontSize="32px"
          fontWeight="700"
          color="rgba(13,26,38,1)"
          lineHeight="40px"
          children={Strings.CANCEL_SUBSCRIPTION}
        />

        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="400"
          lineHeight="24px"
          letterSpacing="0.01px"
          children={Strings.CANCEL_SUBSCRIPTION_SUBTITLE}
        />

        <Flex
          direction={'row'}
          marginTop={35}
          justifyContent="flex-end"
          width={'100%'}
        >
          <Button
            variation="link"
            onClick={() => {
              setCancelSubscriptionVisible(false);
              setModalView(false);
            }}
          >
            {Strings.CLOSE}
          </Button>
          <Button
            variation="primary"
            onClick={cancelSubscriptionSubmit}
            isLoading={subscriptionFormLoading}
          >
            {Strings.CONFIRM}
          </Button>
        </Flex>
      </Flex>
    </View>
  );
  const updatePasswordForm = () => (
    <View alignSelf="center" width={'90%'}>
      <Flex
        as={'form'}
        direction={'column'}
        onSubmit={(e) => updatePasswordSubmit(e)}
      >
        <Text
          fontFamily="Inter"
          fontSize="32px"
          fontWeight="700"
          color="rgba(13,26,38,1)"
          lineHeight="40px"
          children={Strings.PASSWORD_RESET}
        />

        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="400"
          lineHeight="24px"
          letterSpacing="0.01px"
          children={Strings.PASSWORD_RESET_SUBTITLE}
        />

        {passwordResetError && (
          <Text
            fontFamily="Inter"
            color={'red'}
            fontSize="16px"
            fontWeight="400"
            lineHeight="24px"
            letterSpacing="0.01px"
            marginTop={0}
            children={passwordResetError}
          />
        )}

        {/* PASSWORD RESET */}
        <View marginTop={5}>
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="700"
            lineHeight="20px"
            children={Strings.OLD_PASSWORD}
          />

          <TextField
            type={'password'}
            marginTop={5}
            isRequired={true}
            width={'100%'}
            name={'oldPassword'}
            placeholder={Strings.OLD_PASSWORD}
            onChange={(e) => setPassword(e.currentTarget.value)}
          />
        </View>
        <View marginTop={0}>
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="700"
            lineHeight="20px"
            children={Strings.NEW_PASSWORD}
          />

          <TextField
            type={'password'}
            marginTop={5}
            isRequired={true}
            width={'100%'}
            name={'newPassword'}
            placeholder={Strings.NEW_PASSWORD}
            onChange={(e) => setPassword2(e.currentTarget.value)}
          />
        </View>

        <Flex
          direction={'row'}
          marginTop={35}
          justifyContent="flex-end"
          width={'100%'}
        >
          <Button
            variation="link"
            onClick={() => {
              setUpdatePasswordVisible(false);
              setModalView(false);
            }}
          >
            {Strings.CLOSE}
          </Button>
          <Button
            variation="primary"
            type="submit"
            isLoading={passwordFormLoading}
          >
            {Strings.SUBMIT}
          </Button>
        </Flex>
      </Flex>
    </View>
  );
  // ...

  // MODAL FORM VALIDATIONS
  const [pmExpMonth, setPmExpMonth] = useState('');
  const [pmExpYear, setPmExpYear] = useState('');
  const [pmAddress, setPmAddress] = useState(paymentMethod?.address?.line1);
  const [pmAddressLine2, setPmAddressLine2] = useState(
    paymentMethod?.address?.line2
  );
  const [pmState, setPmState] = useState(paymentMethod?.address?.state);
  const [pmZip, setPmZip] = useState(paymentMethod?.address?.zip);
  const [pmCity, setPmCity] = useState(paymentMethod?.address?.city);
  const [pmFormLoading, setPmFormLoading] = useState(false);

  console.log(pmAddressLine2);

  const updatePaymentMethodFormSubmit = (event) => {
    if (
      !pmExpYear ||
      !pmExpMonth ||
      !pmAddress ||
      !pmCity ||
      !pmState ||
      !pmZip
    )
      return false;

    // Submit form
    setPmFormLoading(true);

    // API
    try {
      setUpdatePaymentMethodVisible(false);
      setModalView(false);
      setPmFormLoading(false);
      successToast();
    } catch (e) {
      setPmFormLoading(false);
      errorToast();
    }
  };

  const [customerFormLoading, setCustomerFormLoading] = useState(false);
  const updatePersonalInformationSubmit = async (event) => {
    event.preventDefault();

    // Submit form

    if (!_.isEqual(customer, originalCustomer)) {
      setCustomerFormLoading(true);

      // API
      try {
        console.log({
          method: 'updatePersonalInformationSubmit',
          originalCustomer,
          customer
        });

        await updateCustomer(_.omit(customer, 'created'), setOriginalCustomer);

        const user = await Auth.currentAuthenticatedUser();

        const result = await Auth.updateUserAttributes(user, {
          name: `${_.get(customer, 'firstName')} ${_.get(
            customer,
            'lastName'
          )}`,
          given_name: _.get(customer, 'firstName'),
          family_name: _.get(customer, 'lastName')
        });

        console.log({ result });

        setUpdatePersonalInformationVisible(false);
        setModalView(false);
        setCustomerFormLoading(false);
        successToast();
      } catch (e) {
        setCustomerFormLoading(false);
        errorToast();
      }
    }
  };

  const [emailFormLoading, setEmailFormLoading] = useState(false);
  const updateEmailSubmit = async (event) => {
    event.preventDefault();
    // Submit form

    if (!_.isEqual(customer.email, originalCustomer.email)) {
      setPhoneFormLoading(true);
      // API
      try {
        console.log({
          method: 'updateEmailSubmit',
          customer,
          originalCustomer
        });

        // We don't want to actually do anything here since updating the email will break the customer (for now)
        console.log({
          method: 'updatePersonalInformationSubmit',
          originalCustomer,
          customer
        });

        await updateCustomer(_.omit(customer, 'created'), setOriginalCustomer);

        const user = await Auth.currentAuthenticatedUser();

        const result = await Auth.updateUserAttributes(user, {
          email: _.get(customer, 'email')
        });

        console.log({ result });

        setUpdateEmailVisible(false);
        setModalView(false);
        setEmailFormLoading(false);
        successToast();
      } catch (e) {
        setEmailFormLoading(false);
        errorToast();
      }
    }
  };

  const [subscriptionFormLoading, setSubscriptionFormLoading] = useState(false);
  const cancelSubscriptionSubmit = (event) => {
    // Submit form
    setSubscriptionFormLoading(true);

    // API
    try {
      cancelCustomerSubscription(subscription.id);

      setSubscriptions(() =>
        subscriptions.filter((i) => i.id !== subscription.id)
      );

      setCancelSubscriptionVisible(false);
      setModalView(false);
      setSubscriptionFormLoading(false);
      successToast();
    } catch (e) {
      setSubscriptionFormLoading(false);
      errorToast();
    }
  };
  const [passwordFormLoading, setPasswordFormLoading] = useState(false);
  const updatePasswordSubmit = async (event) => {
    event.preventDefault();
    // Submit form
    setPasswordFormLoading(true);

    // API
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, password, password2);

      setUpdatePasswordVisible(false);
      setModalView(false);
      setPasswordFormLoading(false);
      successToast();
      setPasswordResetError('');
    } catch (e) {
      setPasswordResetError(e.message);
      setPasswordFormLoading(false);
      errorToast();
    }
  };

  const [phoneFormLoading, setPhoneFormLoading] = useState(false);
  const updatePhoneSubmit = async (event) => {
    event.preventDefault();
    // Submit form

    if (!_.isEqual(customer.phone, originalCustomer.phone)) {
      setPhoneFormLoading(true);
      // API
      try {
        console.log({
          method: 'updatePersonalInformationSubmit',
          originalCustomer,
          customer
        });

        await updateCustomer(_.omit(customer, 'created'), setOriginalCustomer);

        const user = await Auth.currentAuthenticatedUser();

        const result = await Auth.updateUserAttributes(user, {
          phone_number: `+1${_.get(customer, 'phone')}`
        });

        console.log({ result });
        setUpdatePhoneVisible(false);
        setModalView(false);
        setPhoneFormLoading(false);
        successToast();
      } catch (e) {
        setPhoneFormLoading(false);
        errorToast();
      }
    }
  };

  // ...

  // ALERTS
  const successToast = () =>
    toast.success(Strings.SUCCESSFULLY_UPDATED, {
      position: 'top-center',
      theme: 'colored'
    });
  const errorToast = () =>
    toast.error(Strings.ERROR_OCCURED, {
      position: 'top-center',
      theme: 'colored'
    });

  // ROUTES
  const goToOverview = () => navigate(ScreenNames.OVERVIEW);
  const gotToSubscriptions = () => navigate(ScreenNames.SUBSCRIPTIONS);
  const goToPaymentMethods = () => navigate(ScreenNames.PAYMENT_METHODS);
  const goToBillingHistory = () => navigate(ScreenNames.BILLING_HISTORY);

  // // HELPERS
  // const getFriendlyAddress = (cust) => {
  //   const segments = [
  //     _.get(cust, 'address', ''),
  //     _.get(cust, 'address2', ''),
  //     _.get(cust, 'locality', ''),
  //     _.get(cust, 'region', ''),
  //     _.get(cust, 'postal', ''),
  //   ];

  //   return segments.join(', ').replace(/, ,/g, ', ');
  // };

  useEffect(() => {
    document.title = Strings.ACCOUNT_INFORMATION;
  }, []);

  useEffect(() => {
    async function loadData() {
      loadCustomer(setOriginalCustomer);
    }
    loadData();
  }, [user]);

  useEffect(() => {
    setCustomer(originalCustomer);
  }, [originalCustomer]);

  const [loadingSubscriptions, setLoadingSubscriptions] = useState(true);

  useEffect(() => {
    (async () => {
      if (_.size(subscriptions) === 0) {
        await loadSubscriptions(customer, (_subscriptions) => {
          setSubscriptions(_subscriptions);
          setTimeout(() => {
            setLoadingSubscriptions(false);
          }, 1500);
        });
      }

      if (_.size(paymentMethods) === 0)
        await loadCustomerPaymentMethods(customer.id, setPaymentMethods);
    })();
  }, [customer, paymentMethods, subscriptions]);

  useEffect(() => {
    async function loadData() {
      const paymentToken = _.get(subscriptions, '0.paymentMethodIds.0', null);
      if (!_.isNull(paymentToken))
        await loadVaultTokenDetails(paymentToken, setPaymentMethod);
    }
    loadData();
  }, [subscriptions]);

  const renderSubscriptionBoxes = (subscriptions) =>
    _.map(subscriptions, (i, idx) => {
      let title = _.get(i, 'products.0.description', null);
      console.log(i.metadata);
      if (_.size(i?.metadata) > 0) {
        const service_place = _.find(
          i.metadata,
          (i) => i.key === 'service_place'
        );
        const service_address = _.find(
          i.metadata,
          (i) => i.key === 'service_address'
        );
        const service_address2 = _.find(
          i.metadata,
          (i) => i.key === 'service_address2'
        );
        const service_locality = _.find(
          i.metadata,
          (i) => i.key === 'service_locality'
        );
        const service_region = _.find(
          i.metadata,
          (i) => i.key === 'service_region'
        );
        const service_postal = _.find(
          i.metadata,
          (i) => i.key === 'service_postal'
        );

        if (service_place) title = service_place.value;
        else if (service_address2)
          title = `${service_address.value} ${service_address2.value}, ${service_locality.value}, ${service_region.value}, ${service_postal.value}`;
        else
          title = `${service_address?.value}, ${service_locality?.value}, ${service_region?.value}, ${service_postal?.value}`;
      }

      return (
        <BlankOverviewBox
          key={idx}
          overrides={{
            BlankOverviewBox: {
              width: '100%',
              padding: '32px 32px 32px 32px',
              backgroundColor: Colors.container
            }
          }}
        >
          <View width={'100%'}>
            <View>
              <Flex width={'100%'} direction="column">
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="700"
                  textAlign="left"
                  letterSpacing="0.49px"
                  children={title}
                />
                <Text
                  fontFamily="Inter"
                  fontSize="14px"
                  fontWeight="400"
                  textAlign="left"
                  letterSpacing="0.49px"
                  marginTop={-12}
                  children={`Billed ${_.capitalize(i.frequency)}`}
                />

                <Flex
                  direction={'row'}
                  width={'100%'}
                  marginTop={10}
                  justifyContent="space-between"
                >
                  <View>
                    <Text
                      fontFamily="Inter"
                      fontSize="14px"
                      fontWeight="700"
                      lineHeight="18px"
                      letterSpacing="0.41px"
                      children={`${Strings.UPCOMING_CHARGE} on ${
                        i?.nextBill?.date
                          ? dayjs(i.nextBill.date).format('MM/DD/YYYY')
                          : 'N/a'
                      }`}
                    ></Text>
                    <Text
                      fontFamily="Inter-Bold"
                      fontSize="40px"
                      fontWeight="700"
                      lineHeight="48px"
                      marginTop={10}
                      children={`${i.currency.symbol}${i.total}`}
                    ></Text>
                  </View>
                  <Text
                    fontFamily="Inter"
                    fontSize="14px"
                    color={Colors.activeText}
                    fontWeight="700"
                    letterSpacing="0.41px"
                    height={'100%'}
                    onClick={viewCharges}
                    style={{ cursor: 'pointer' }}
                    children="View Charges"
                  ></Text>
                </Flex>

                <View
                  backgroundColor={'#e5e5e5'}
                  height={1}
                  opacity={0.5}
                  marginTop={5}
                />

                <Flex
                  direction={'row'}
                  width={'100%'}
                  marginTop={10}
                  justifyContent="space-between"
                >
                  <View>
                    <Text
                      fontFamily="Inter"
                      fontSize="14px"
                      fontWeight="700"
                      lineHeight="18px"
                      letterSpacing="0.41px"
                      marginBottom={15}
                      children={Strings.PAYMENT_METHOD}
                    ></Text>
                  </View>
                  <Text
                    fontFamily="Inter"
                    fontSize="14px"
                    fontWeight="700"
                    color={Colors.activeText}
                    lineHeight="18px"
                    letterSpacing="0.41px"
                    onClick={() => updatePayment()}
                    style={{ cursor: 'pointer' }}
                    children={Strings.UPDATE_PAYMENT}
                  ></Text>
                </Flex>

                <PaymentType
                  customerId={customer.id}
                  paymentMethodId={i.paymentMethodIds[0]}
                />

                <View
                  backgroundColor={'#e5e5e5'}
                  height={1}
                  opacity={0.5}
                  marginTop={5}
                />

                <Text
                  fontFamily="Inter"
                  fontSize="14px"
                  fontWeight="700"
                  color={Colors.activeText}
                  lineHeight="18px"
                  letterSpacing="0.41px"
                  onClick={() => cancelSubscription(i)}
                  style={{ cursor: 'pointer' }}
                  marginTop={10}
                  children={Strings.CANCEL_SUBSCRIPTION}
                ></Text>
              </Flex>
            </View>
          </View>
        </BlankOverviewBox>
      );
    });

  if (_.get(process.env, 'REACT_APP_ALLOW_SIGN_IN') === 'false')
    return <Error404Screen />;

  return (
    <Flex
      direction="column"
      alignItems={'center'}
      height={modalView ? '100vh' : null}
      minHeight="100vh"
      backgroundColor={Colors.surface}
      width={'100%'}
      overflow={'hidden'}
    >
      <AppNavBar
        overrides={{
          AppNavBar: { width: '100%', backgroundColor: Colors.header },
          user: {
            fontFamily: 'Inter-Regular',
            color: Colors.headerText,
            children: 'Sign out',
            onClick: signOut,
            style: { cursor: 'pointer' }
          },
          Logo30873174: { src: process.env.REACT_APP_LOGO_URL },
          image: { src: Images.USER_PLACEHOLDER, style: { display: 'none' } }
        }}
      />

      <Flex
        direction={isMobile ? 'column' : 'row'}
        jjustifyContent={isMobile ? null : 'space-evenly'}
        width={containerWidth}
        flex={1}
        marginTop={isMobile ? 10 : 25}
        marginBottom={30}
      >
        <Flex direction={'column'} width={isMobile ? '100%' : 317}>
          <SideBar
            overrides={{
              SideBar: { width: '100%', backgroundColor: Colors.container },
              Overview: { fontWeight: '600', color: Colors.activeText },
              OverviewFrame: {
                onClick: goToOverview,
                style: { cursor: 'pointer' }
              },
              Subscriptions: {},
              SubscriptionsFrame: {
                onClick: gotToSubscriptions,
                style: { cursor: 'pointer' }
              },
              'Payment Methods': {},
              PaymentMethodsFrame: {
                onClick: goToPaymentMethods,
                style: { cursor: 'pointer' }
              },
              'Billing History': {},
              BillingHistoryFrame: {
                onClick: goToBillingHistory,
                style: { cursor: 'pointer' }
              }
            }}
          />
        </Flex>

        <Flex direction={'column'} width={isMobile ? '100%' : '40%'}>
          {loadingSubscriptions ? (
            <BlankOverviewBox
              overrides={{
                BlankOverviewBox: {
                  width: '100%',
                  padding: '32px 32px 32px 32px'
                }
              }}
            >
              <Flex
                direction={'column'}
                width={'100%'}
                justifyContent="center"
                alignItems={'center'}
              >
                <Loader
                  type="bubbles"
                  color={Colors.loader}
                  width={75}
                  height={75}
                />
              </Flex>
            </BlankOverviewBox>
          ) : (
            <>
              {_.size(subscriptions) > 0 ? (
                renderSubscriptionBoxes(subscriptions)
              ) : (
                <BlankOverviewBox
                  overrides={{
                    BlankOverviewBox: {
                      width: '100%',
                      padding: '32px 32px 32px 32px'
                    }
                  }}
                >
                  <Flex
                    direction={'column'}
                    width={'100%'}
                    justifyContent="center"
                    alignItems={'center'}
                  >
                    {'No current enrollments/subscriptions'}
                  </Flex>
                </BlankOverviewBox>
              )}
            </>
          )}
        </Flex>

        <Flex direction={'column'} width={isMobile ? '100%' : '40%'}>
          <BlankOverviewBox
            overrides={{
              BlankOverviewBox: {
                width: '100%',
                padding: '32px 32px 32px 32px',
                backgroundColor: Colors.container
              }
            }}
          >
            {!customer.id ? (
              <Flex
                direction={'column'}
                width={'100%'}
                justifyContent="center"
                alignItems={'center'}
              >
                <Loader
                  type="bubbles"
                  color={Colors.loader}
                  width={75}
                  height={75}
                />
              </Flex>
            ) : (
              <Flex width={'100%'} direction="column">
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="700"
                  lineHeight="20px"
                  textAlign="left"
                  letterSpacing="0.49px"
                  children={Strings.ACCOUNT_INFORMATION.toUpperCase()}
                ></Text>

                <Flex
                  direction={'row'}
                  width={'100%'}
                  marginTop={10}
                  justifyContent="space-between"
                >
                  <View>
                    <Text
                      fontFamily="Inter"
                      fontSize="14px"
                      fontWeight="700"
                      lineHeight="18px"
                      letterSpacing="0.41px"
                      children={Strings.PERSONAL_INFO}
                    ></Text>
                    <Text
                      fontFamily="Inter"
                      fontSize="14px"
                      fontWeight="400"
                      lineHeight="24px"
                      marginTop={10}
                      children={`${originalCustomer.firstName} ${originalCustomer.lastName}`}
                    ></Text>
                    <Text
                      fontFamily="Inter"
                      fontSize="14px"
                      fontWeight="400"
                      lineHeight="24px"
                      marginTop={15}
                      children={`${originalCustomer.address}`}
                    ></Text>
                    {customer.address2 && (
                      <Text
                        fontFamily="Inter"
                        fontSize="14px"
                        fontWeight="400"
                        lineHeight="24px"
                        children={originalCustomer.address2}
                      ></Text>
                    )}
                    <Text
                      fontFamily="Inter"
                      fontSize="14px"
                      fontWeight="400"
                      lineHeight="24px"
                      marginTop={0}
                      children={`${originalCustomer.locality}, ${originalCustomer.region}, ${originalCustomer.country}, ${originalCustomer.postal}`}
                    ></Text>
                  </View>
                  <Text
                    fontFamily="Inter"
                    fontSize="14px"
                    fontWeight="700"
                    color={Colors.activeText}
                    lineHeight="18px"
                    letterSpacing="0.41px"
                    onClick={updatePersonalInformation}
                    style={{ cursor: 'pointer' }}
                    children={Strings.UPDATE_INFORMATION}
                  ></Text>
                </Flex>

                <View
                  backgroundColor={'#e5e5e5'}
                  height={1}
                  opacity={0.5}
                  marginTop={5}
                />

                <Flex
                  direction={'row'}
                  width={'100%'}
                  marginTop={10}
                  justifyContent="space-between"
                >
                  <View>
                    <Text
                      fontFamily="Inter"
                      fontSize="14px"
                      fontWeight="700"
                      lineHeight="18px"
                      letterSpacing="0.41px"
                      children={Strings.EMAIL_ADDRESS}
                    ></Text>
                    <Text
                      fontFamily="Inter"
                      fontSize="14px"
                      fontWeight="400"
                      lineHeight="24px"
                      marginTop={5}
                      children={originalCustomer.email}
                    ></Text>
                  </View>
                  <Text
                    fontFamily="Inter"
                    fontSize="14px"
                    fontWeight="700"
                    color={Colors.activeText}
                    lineHeight="18px"
                    letterSpacing="0.41px"
                    onClick={updateEmail}
                    style={{ cursor: 'pointer' }}
                    children={Strings.UDPATE_EMAIL}
                  ></Text>
                </Flex>

                <View
                  backgroundColor={'#e5e5e5'}
                  height={1}
                  opacity={0.5}
                  marginTop={5}
                />

                <Flex
                  direction={'row'}
                  width={'100%'}
                  marginTop={10}
                  justifyContent="space-between"
                >
                  <View>
                    <Text
                      fontFamily="Inter"
                      fontSize="14px"
                      fontWeight="700"
                      lineHeight="18px"
                      letterSpacing="0.41px"
                      children={Strings.PHONE_NUMBER}
                    ></Text>
                    <Text
                      fontFamily="Inter"
                      fontSize="14px"
                      fontWeight="400"
                      lineHeight="24px"
                      marginTop={5}
                      children={originalCustomer.phone}
                    ></Text>
                  </View>
                  <Text
                    fontFamily="Inter"
                    fontSize="14px"
                    fontWeight="700"
                    color={Colors.activeText}
                    lineHeight="18px"
                    letterSpacing="0.41px"
                    onClick={updatePhone}
                    style={{ cursor: 'pointer' }}
                    children={Strings.UPDATE_PHONE}
                  ></Text>
                </Flex>

                <View
                  backgroundColor={'#e5e5e5'}
                  height={1}
                  opacity={0.5}
                  marginTop={5}
                />

                <Flex
                  direction={'row'}
                  width={'100%'}
                  marginTop={10}
                  justifyContent="space-between"
                >
                  <View width={'50%'}>
                    <Text
                      fontFamily="Inter"
                      fontSize="14px"
                      fontWeight="700"
                      lineHeight="18px"
                      letterSpacing="0.41px"
                      children={Strings.PASSWORD}
                    ></Text>
                    <Text
                      fontFamily="Inter"
                      fontSize="14px"
                      fontWeight="400"
                      lineHeight="24px"
                      marginTop={5}
                      children={
                        'Set a unique password to protect your personal account.'
                      }
                    ></Text>
                  </View>
                  <Text
                    fontFamily="Inter"
                    fontSize="14px"
                    fontWeight="700"
                    color={Colors.activeText}
                    lineHeight="18px"
                    letterSpacing="0.41px"
                    onClick={updatePassword}
                    style={{ cursor: 'pointer' }}
                    children={Strings.UPDATE_PASSWORD}
                  ></Text>
                </Flex>
              </Flex>
            )}
          </BlankOverviewBox>
        </Flex>
      </Flex>

      <Modal visible={modalView} animationType="slide" transparent>
        <BlankOverviewBox
          overrides={{
            BlankOverviewBox: {
              width: isMobile ? width / 1.1 : width / 3,
              alignSelf: 'center',
              marginTop: isMobile ? 20 : 50,
              alignContent: 'center',
              justifyContent: 'center'
            }
          }}
        >
          {chargesModalVisible && charges(upcomingCharges)}
          {updatePaymentMethodVisible && updatePaymentMethod()}
          {updatePersonalInformationVisible && updatePersonalInformationForm()}
          {updateEmailVisible && updateEmailForm()}
          {updatePhoneVisible && updatePhoneForm()}
          {updatePasswordVisible && updatePasswordForm()}
          {cancelSubscriptionVisible && cancelSubscriptionForm()}
        </BlankOverviewBox>
      </Modal>

      {modalView && (
        <View
          height={'100%'}
          width={width}
          backgroundColor={'rgba(0,0,0,0.5)'}
          top={0}
          left={0}
          bottom={0}
          position="absolute"
        />
      )}

      <ToastContainer />

      <Footer />
    </Flex>
  );
}

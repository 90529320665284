import * as Font from 'expo-font';
import { useEffect, useState } from 'react';

export default function useCachedResources() {
  const [isLoadingComplete, setLoadingComplete] = useState(false);

  // Load any resources or data that we need prior to rendering the app
  useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        // Load fonts
        await Font.loadAsync({
          //...FontAwesome.font,
          //Inter
          'Inter-Black': require('../assets/fonts/Inter/Inter-Black.ttf'),
          'Inter-Bold': require('../assets/fonts/Inter/Inter-Bold.ttf'),
          'Inter-ExtraBold': require('../assets/fonts/Inter/Inter-ExtraBold.ttf'),
          'Inter-ExtraLight': require('../assets/fonts/Inter/Inter-ExtraLight.ttf'),
          'Inter-Light': require('../assets/fonts/Inter/Inter-Light.ttf'),
          'Inter-Medium': require('../assets/fonts/Inter/Inter-Medium.ttf'),
          'Inter-Regular': require('../assets/fonts/Inter/Inter-Regular.ttf'),
          'Inter': require('../assets/fonts/Inter/Inter-Regular.ttf'),
          'Inter-SemiBold': require('../assets/fonts/Inter/Inter-SemiBold.ttf'),
          'Inter-Thin': require('../assets/fonts/Inter/Inter-Thin.ttf'),
        });
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e);
      } finally {
        setLoadingComplete(true);
      }
    }

    loadResourcesAndDataAsync();
  }, []);

  return isLoadingComplete;
}

import _ from 'lodash';
import * as dayjs from 'dayjs';
import Loader from 'react-loading';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import currencyFormatter from 'currency-formatter';
import { ToastContainer, toast } from 'react-toastify';
import { IoCheckmarkDoneOutline } from 'react-icons/io5';
import { Modal, useWindowDimensions } from 'react-native';
import {
  Flex,
  View,
  Text,
  TextField,
  Link,
  RadioGroupField,
  Radio,
  ToggleButton,
  SelectField,
  Button,
  IconError,
  IconAdd,
  Image,
  ScrollView
} from '@aws-amplify/ui-react';

import Footer from '../../components/Footer';
import Error404Screen from '../ErrorScreen/404';
import { getQueryStringParameter } from '../../utils/html';
import TermsConditions from '../../components/TermsConditions';
import { loadVaultToken, storeVaultToken } from '../../utils/vault';
import PaymentType from '../../components/Modals/CreditCard/PaymentType';
import {
  Colors,
  Images,
  Strings,
  Variables,
  ScreenNames
} from '../../constants';
import {
  AppNavBar,
  BlankOverviewBox,
  SubscriptionLineItemInset
} from '../../ui-components';
import {
  loadCustomer,
  loadProduct,
  loadProducts,
  createSubscription,
  createPaymentMethod,
  loadCustomerPaymentMethods
} from '../../utils/rest';

export default function SubscribeScreen() {
  const navigate = useNavigate();

  const { height, width } = useWindowDimensions();
  const [isMobile, setIsMobile] = useState(() => {
    if (width < 768) return true;
    else return false;
  });
  useEffect(() => {
    if (width < 768) setIsMobile(true);
    else return setIsMobile(false);
  }, [width]);

  const [loading, setLoading] = useState(true);
  const [user] = useState({
    email: 'joe@example.com',
    phone: '(773) 444-5555',
    name: 'John Doe'
  });

  const [customer, setCustomer] = useState({});
  const [modalView, setModalView] = useState(false);
  const [agree, setAgree] = useState(false);

  const [vaultToken, setVaultToken] = useState(null);
  const [vaultUrl] = useState(_.get(process.env, 'REACT_APP_IFRAME_URL'));
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({});

  const [step, setStep] = useState('pay'); //pay, spt, cc, dda, ps, sa, cp
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [paymentToken, setPaymentToken] = useState('');

  const [productId, setProductId] = useState('');

  //PRODUCT
  const [product, setProduct] = useState({});
  const [products, setProducts] = useState([]);

  // PAYMENT METHODS
  const [ddaPressed, setDDAPressed] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [creditPressed, setCreditPressed] = useState(false);

  const [cardFormLoading, setCardFormLoading] = useState(false);
  const addCardSubmit = async (event) => {
    event.preventDefault();

    //Submit form
    setCardFormLoading(true);

    let finalizedCard = {};
    //API
    try {
      console.log({ vaultToken });
      finalizedCard = await storeVaultToken(vaultToken);

      console.log({ finalizedCard });
    } catch (vaultError) {
      console.log({ message: 'VAULT ERROR', vaultError });

      return false;
    }

    try {
      let paymentMethod = {
        token: _.get(finalizedCard, 'data.token'),
        last: _.get(finalizedCard, 'data.last'),
        issuer_id: _.get(finalizedCard, 'data.issuer_id'),
        expiration: _.get(finalizedCard, 'data.expiration'),
        brand: _.get(finalizedCard, 'data.brand'),
        customerId: _.get(customer, 'id'),
        primary: true,
        scheme: 'CREDIT',
        category: 'CREDIT',
        billingDetails: {
          address: _.get(finalizedCard, 'data.address'),
          address2: _.get(finalizedCard, 'data.address2'),
          country: _.get(finalizedCard, 'data.country', 'USA'),
          locality: _.get(finalizedCard, 'data.city'),
          region: _.get(finalizedCard, 'data.state'),
          postal: _.get(finalizedCard, 'data.zip')
        }
      };

      paymentMethod = _.omit(paymentMethod, ['scheme', 'type']);

      console.log({ paymentMethod });

      createPaymentMethod(paymentMethod, (pm) => {
        const paymentMethodObject = _.get(pm, 'storePaymentMethod.0');

        if (_.get(paymentMethodObject, 'code') === '432') {
          setCardFormLoading(false);
          errorToast(
            'Failed to Authorize Card (432): Please try another form of payment.'
          );
          console.error('Card Authorization Failed:', paymentMethodObject);
        }

        console.log('createPaymentMethod Results', {
          createResponse: pm,
          original: paymentMethod,
          paymentMethodObject
        });

        _.set(paymentMethod, 'id', _.get(paymentMethodObject, 'id'));

        if (user) {
          loadCustomerPaymentMethods(customer.id, setPaymentMethods);
        } else {
          setPaymentMethods([paymentMethod]);
          setPaymentToken(paymentMethod.token);
        }

        setSelectedPaymentMethod(paymentMethodObject);
        setCardFormLoading(false);
        setStep('pay');
      });

      //successToast()
    } catch (e) {
      console.log(e);
      setCardFormLoading(false);
      errorToast();
    }
  };

  const [bankFormLoading, setBankFormLoading] = useState(false);
  const addBankSubmit = async (event) => {
    event.preventDefault();

    //Submit form
    setBankFormLoading(true);

    let finalizedBank = {};
    //API
    try {
      console.log({ vaultToken });
      finalizedBank = await storeVaultToken(vaultToken);

      console.log({ finalizedBank });
    } catch (vaultError) {
      console.log({ message: 'VAULT ERROR', vaultError });

      return false;
    }

    try {
      let paymentMethod = {
        primary: true,
        customerId: _.get(customer, 'id'),
        last: _.get(finalizedBank, 'data.last'),
        type: _.get(finalizedBank, 'data.type'),
        token: _.get(finalizedBank, 'data.token'),
        brand: _.get(finalizedBank, 'data.brand'),
        routing: _.get(finalizedBank, 'data.routing'),
        scheme: _.toUpper(_.get(finalizedBank, 'data.scheme', 'dda')),
        category: _.toUpper(_.get(finalizedBank, 'data.scheme', 'dda')),
        billingDetails: {
          accountHolder: {
            firstName: _.get(finalizedBank, 'data.firstName'),
            lastName: _.get(finalizedBank, 'data.lastName')
          }
        }
      };

      paymentMethod = _.omit(paymentMethod, ['scheme']);

      console.log({ paymentMethod });

      createPaymentMethod(paymentMethod, (pm) => {
        const paymentMethodObject = _.get(pm, 'storePaymentMethod.0');

        console.log('createPaymentMethod Results', {
          createResponse: pm,
          original: paymentMethod,
          paymentMethodObject
        });

        _.set(paymentMethod, 'id', _.get(paymentMethodObject, 'id'));

        if (user) {
          loadCustomerPaymentMethods(customer.id, setPaymentMethods);
        } else {
          setPaymentMethods([paymentMethod]);
          setPaymentToken(paymentMethod.token);
        }

        setSelectedPaymentMethod(paymentMethodObject);
        setBankFormLoading(false);
        setStep('pay');
      });

      //successToast()
    } catch (error) {
      setBankFormLoading(false);
      console.error(error);
      errorToast(error);
    }
  };

  const [servicePlace, setServicePlace] = useState('');
  const [serviceAddress, setServiceAddress] = useState('');
  const [serviceAddress2, setServiceAddress2] = useState('');
  const [serviceCity, setServiceCity] = useState('');
  const [serviceZip, setServiceZip] = useState('');
  const [serviceState, setServiceState] = useState('');
  const [serviceAddressFormLoading] = useState(false);
  const [serviceMeta, setServiceMeta] = useState({
    service_place: '',
    service_address: '',
    service_address2: '',
    service_locality: '',
    service_region: '',
    service_postal: ''
  });

  const addServiceAddress = (event) => {
    event.preventDefault();
    //setServiceAddressFormLoading(true)

    setServiceMeta({
      service_place: servicePlace,
      service_address: serviceAddress,
      service_address2: serviceAddress2,
      service_locality: serviceCity,
      service_region: serviceState,
      service_postal: serviceZip
    });

    setStep('pay');
  };

  const containerWidth = isMobile ? width / 1.1 : width / 1.2;

  // const addSelectedInvoice = (val, invoice) => {
  //     //if true, add invoice to selected
  //     if(val){
  //         let invoices = [...selectedInvoices]
  //         invoices.push(invoice)
  //         setSelectedInvoices(invoices)
  //     } else {
  //         //if false, remove invoice from selected
  //         let found = selectedInvoices.filter((i) => i.id !== invoice.id)
  //         setSelectedInvoices(found)
  //     }
  // }

  const [payFormLoading, setPayFormLoading] = useState(false);

  const payNow = async () => {
    if (!agree) {
      setModalView(true);
      return false;
    }
    try {
      setPayFormLoading(true);
      const subscriptionProduct = {
        currency: {
          name: 'USD',
          symbol: '$'
        },
        id: _.get(product, 'id'),
        sku: _.get(product, 'sku'),
        type: _.get(product, 'type'),
        description: _.get(product, 'description'),
        frequency: _.get(product, 'prices.0.frequency'),
        amount: _.get(product, 'prices.0.amount'),
        metadata: _.get(product, 'metadata'),
        merchantId: '134713457',
        merchantProductId: '457145'
      };

      let submitProducts = [];
      if (productId) submitProducts = [subscriptionProduct];
      else {
        subscriptionProducts.map((i) => {
          for (let p = 0; p < i.quantity; p++) {
            submitProducts.push({
              currency: {
                name: 'USD',
                symbol: '$'
              },
              id: _.get(i, 'product.id'),
              sku: _.get(i, 'product.sku'),
              type: _.get(i, 'product.type'),
              description: _.get(i, 'product.description'),
              frequency: _.get(i, 'product.prices.0.frequency'),
              amount: _.get(i, 'product.prices.0.amount'),
              metadata: _.get(i, 'product.metadata'),
              merchantId: '134713457',
              merchantProductId: '457145'
            });
          }

          return true;
        });
      }

      //let metadata = {}

      let subscription = {
        collectionMethod: 'AUTH_CAPTURE',
        customer: {
          id: _.get(customer, 'id'),
          email: _.get(customer, 'email')
        },
        merchantSubscriptionId: '35653498',
        start: dayjs().toISOString(),
        total: _.toNumber(_.get(subscriptionProduct, 'amount')),
        paymentMethodIds: [paymentToken],
        products: submitProducts
      };

      if (serviceMeta.service_address) {
        subscription.metadata = [
          {
            key: 'service_place',
            value: serviceMeta.service_place
          },
          {
            key: 'service_address',
            value: serviceMeta.service_address
          },
          {
            key: 'service_address2',
            value: serviceMeta.service_address2
          },
          {
            key: 'service_locality',
            value: serviceMeta.service_locality
          },
          {
            key: 'service_region',
            value: serviceMeta.service_region
          },
          {
            key: 'service_postal',
            value: serviceMeta.service_postal
          }
        ];
      }

      const subscriptionCreated = await createSubscription(subscription);

      console.log({ subscriptionCreated });

      if (subscriptionCreated.success) {
        setStep('ps');
      } else {
        errorToast();
      }
      setPayFormLoading(false);
    } catch (e) {
      setPayFormLoading(false);
    }
  };

  // const productItems = (products) => {
  //     return (
  //         products.map((i, idx) => {
  //             if(isMobile)
  //             return (
  //                 <Flex key={idx} direction={'row'} justifyContent={'flex-end'}>
  //                     <SubscriptionLineItemInset marginBottom={5} right={0} width={'90%'} overrides={{
  //                         'Title': { children: i.product.title },
  //                         'Description': { children: i.product.description, width: '100%'},
  //                         'Amount': { children: `$${i.amount}`, right: 0, textAlign: 'left', fontFamily: 'Inter-Light'},
  //                     }} />
  //                 </Flex>
  //             )
  //             else
  //             return (
  //                 <Flex key={idx} direction={'row'} justifyContent={'flex-end'}>
  //                     <SubscriptionLineItemInset marginBottom={15} right={0} width={'90%'} overrides={{
  //                         'Title': { children: i.product.title},
  //                         'Description': { children: i.product.description},
  //                         'Amount': { children: `$${i.amount}`,  fontFamily: 'Inter-Light'},
  //                     }} />
  //                 </Flex>
  //             )
  //         })
  //     )
  // }

  const [addProductDisabled, setAddProductDisabled] = useState(true);
  const [selectedProductId, setSelectedProductId] = useState('');
  const [selectedQuanity, setSelectedQuanity] = useState('');
  const [quantityMax] = useState(5);
  const [subscriptionProducts, setSubscriptionProducts] = useState([]);
  const [quantityMaxError, setQuantityMaxError] = useState('');
  const [subscriptionTotal, setSubscriptionTotal] = useState(0);

  const addProduct = () => {
    const found = _.find(products, (i) => i.id === selectedProductId);
    const arry = [...subscriptionProducts];

    //Check if in subscription products list, if yes, add the new product quantity for this item
    const foundInSubscriptionProductsIndex = _.findIndex(
      subscriptionProducts,
      (i) => i.product.id === selectedProductId
    );
    console.log({ foundInSubscriptionProductsIndex });
    if (foundInSubscriptionProductsIndex !== -1) {
      const qty = arry[foundInSubscriptionProductsIndex].quantity;
      arry[foundInSubscriptionProductsIndex].quantity =
        _.parseInt(qty) + _.parseInt(selectedQuanity);

      //if new quantity is more than the max, reject
      if (arry[foundInSubscriptionProductsIndex].quantity > quantityMax) {
        alert(
          'Quantity must be less than ' +
            quantityMax +
            ' for each product. Enter a new quantity.'
        );
        return false;
      }
    } else {
      arry.push({ product: found, quantity: _.parseInt(selectedQuanity) });
    }

    setSubscriptionProducts(arry);
    setSelectedQuanity('');
    setSelectedProductId('');
    calculateTotal(arry);
  };

  const removeProduct = (id) => {
    const idx = _.findIndex(products, (i) => i.id === id);
    let arry = [...subscriptionProducts];
    arry.splice(idx, 1);
    console.log(arry);
    setSubscriptionProducts(arry);
    calculateTotal(arry);
    console.log({ arry });
  };

  const calculateTotal = (items) => {
    setSubscriptionTotal(
      _.sumBy(items, (i) => {
        return i.product.prices[0].amount * i.quantity;
      })
    );
  };

  useEffect(() => {
    if (selectedQuanity > quantityMax)
      setQuantityMaxError('Quantity must be less than ' + quantityMax);
    else setQuantityMaxError('');

    if (
      selectedQuanity &&
      selectedQuanity > 0 &&
      selectedQuanity < quantityMax &&
      selectedProductId
    )
      setAddProductDisabled(false);
    else setAddProductDisabled(true);
  }, [selectedProductId, selectedQuanity, quantityMax]);

  //ALERTS
  // const successToast = () => toast.success(Strings.SUCCESSFULLY_UPDATED, { position: 'top-center', theme: 'colored'});
  const errorToast = () =>
    toast.error(Strings.ERROR_OCCURED, {
      position: 'top-center',
      theme: 'colored'
    });

  //ROUTES
  const goToOverview = () => navigate(ScreenNames.OVERVIEW);
  // const goToSubscriptionsStore = () => navigate(ScreenNames.SUBSCRIPTIONS_STORE)
  // const gotToSubscriptions = () => navigate(ScreenNames.SUBSCRIPTIONS)
  // const goToPaymentMethods = () => navigate(ScreenNames.PAYMENT_METHODS)
  // const goToBillingHistory = () => navigate(ScreenNames.BILLING_HISTORY)

  //HELPERS
  const getFriendlyAddress = (cust) => {
    const segments = [
      _.get(cust, 'address', ''),
      _.get(cust, 'address2', ''),
      _.get(cust, 'locality', ''),
      _.get(cust, 'region', ''),
      _.get(cust, 'postal', '')
    ];

    return segments.join(', ').replace(/, ,/g, ', ');
  };

  const getFriendlyAddressMeta = (meta) => {
    const segments = [
      _.get(meta, 'service_address', ''),
      _.get(meta, 'service_address2', ''),
      _.get(meta, 'service_locality', ''),
      _.get(meta, 'service_region', ''),
      _.get(meta, 'service_postal', '')
    ];
    console.log(segments.join(', ').replace(/, ,/g, ', '));
    return segments.join(', ').replace(/, ,/g, ', ');
  };

  const [cardIframeLoaded, setCardIframeLoaded] = useState(false);
  const vaultCardIframe = () => {
    return (
      <>
        {!cardIframeLoaded && (
          <Flex
            direction={'column'}
            width={'100%'}
            justifyContent="center"
            alignItems={'center'}
            height={'600px'}
          >
            <Loader
              type="bubbles"
              color={Colors.loader}
              width={50}
              height={50}
            />
          </Flex>
        )}

        <iframe
          title="card-iframe"
          onLoad={() => setCardIframeLoaded(true)}
          style={{
            width: '100%',
            height: '350px',
            marginLeft: '-15px',
            border: 'none'
          }}
          scrolling="no"
          src={`${vaultUrl}/card/${vaultToken}?address=true&firstName=${customer.firstName}&lastName=${customer.lastName}`}
        ></iframe>
      </>
    );
  };

  const [bankIframeLoaded, setBankIframeLoaded] = useState(false);
  const vaultBankIframe = () => {
    return (
      <>
        {!bankIframeLoaded && (
          <Flex
            direction={'column'}
            width={'100%'}
            justifyContent="center"
            alignItems={'center'}
            height={'100px'}
          >
            <Loader
              type="bubbles"
              color={Colors.loader}
              width={50}
              height={50}
            />
          </Flex>
        )}

        <iframe
          title="bank-iframe"
          onLoad={() => setBankIframeLoaded(true)}
          style={{
            width: '100%',
            height: '200px',
            marginLeft: '-15px',
            border: 'none'
          }}
          scrolling="no"
          src={`${vaultUrl}/bank/${vaultToken}?firstName=${customer.firstName}&lastName=${customer.lastName}`}
        ></iframe>
      </>
    );
  };

  const isPaymentMethodValid = (category) => {
    const { REACT_APP_THRESHOLD_ACH_MIN, REACT_APP_THRESHOLD_ACH_MAX } =
      process.env;
    const { REACT_APP_THRESHOLD_CC_MIN, REACT_APP_THRESHOLD_CC_MAX } =
      process.env;
    if (category === 'CREDIT') {
      return (
        (!REACT_APP_THRESHOLD_CC_MIN ||
          Number(REACT_APP_THRESHOLD_CC_MIN) <= subscriptionTotal) &&
        (!REACT_APP_THRESHOLD_CC_MAX ||
          Number(REACT_APP_THRESHOLD_CC_MAX) >= subscriptionTotal)
      );
    } else if (category === 'DDA') {
      return (
        (!REACT_APP_THRESHOLD_ACH_MIN ||
          Number(REACT_APP_THRESHOLD_ACH_MIN) <= subscriptionTotal) &&
        (!REACT_APP_THRESHOLD_ACH_MAX ||
          Number(REACT_APP_THRESHOLD_ACH_MAX) >= subscriptionTotal)
      );
    } else {
      return false;
    }
  };

  const isFormValid = () => {
    if (!paymentToken || (!_.size(subscriptionProducts) && !productId)) {
      return false;
    }
    const paymentMethod = _.find(paymentMethods, { id: paymentToken });
    if (paymentMethod) {
      return isPaymentMethodValid(paymentMethod.category);
    }
    return true;
  };

  useEffect(() => {
    document.title = Strings.SUBSCRIBE;
    loadVaultToken(setVaultToken);
  }, []);

  useEffect(() => {
    //if(agree) payNow()
  }, [agree]);

  useEffect(() => {
    (async () => {
      await loadCustomer(setCustomer);
    })();
  }, [user]);

  useEffect(() => {
    (async () => {
      if (customer) {
        if (_.has(customer, 'paymentMethods.0.token'))
          setPaymentToken(_.get(customer, 'paymentMethods.0.token'));

        setAddress(getFriendlyAddress(customer));
        setName(
          `${_.get(customer, 'firstName', '')} ${_.get(
            customer,
            'lastName',
            ''
          )}`
        );

        setProductId(_.cloneDeep(getQueryStringParameter('productId')));
        if (productId) await loadProduct(productId, setProduct);
        else await loadProducts(setProducts);
        await loadCustomerPaymentMethods(customer.id, setPaymentMethods);
      }
    })();
  }, [customer, productId]);

  useEffect(() => {
    console.log({ selectedPaymentMethod });
  }, [selectedPaymentMethod]);
  useEffect(() => {
    console.log({ paymentMethods });
  }, [paymentMethods]);

  useEffect(() => {
    console.log({ product });
    //if(!customer?.id) goToSubscriptionsStore()
    //if(customer?.id && product?.id) setLoading(false)
    //setLoading(false)
  }, [customer, product]);

  useEffect(() => {}, [paymentMethods]);

  useEffect(() => {
    if (product?.id) setLoading(false);
  }, [product]);

  useEffect(() => {
    (async () => {
      console.log({ products });
      if (_.size(products) > 0) await loadProduct(products[0].id, setProduct);
    })();
  }, [products]);

  if (_.get(process.env, 'REACT_APP_ALLOW_SIGN_IN') === 'false')
    return <Error404Screen />;

  return (
    <Flex
      direction="column"
      alignItems={'center'}
      height={modalView ? '100vh' : null}
      minHeight="100vh"
      backgroundColor={Colors.surface}
      width={'100%'}
      overflow={'hidden'}
    >
      <View
        backgroundColor={Colors.background}
        width={'100%'}
        position={'absolute'}
        height={'30%'}
      >
        {/* <Text
                    fontFamily="Inter-ExtraBold"
                    fontSize="32px"
                    fontWeight="700"
                    color="#fff"
                    position='absolute'
                    bottom={100}
                    left={30}
                    lineHeight="40px"
                    children={`${Strings.INVOICE} ${invoices[0].id}`} /> */}
      </View>
      <AppNavBar
        overrides={{
          AppNavBar: { width: '100%', backgroundColor: Colors.secondary },
          user: {
            fontFamily: 'Inter-Regular',
            color: Colors.headerText,
            children: Strings.SUPPORT
          },
          Logo30873174: { src: process.env.REACT_APP_LOGO_URL },
          image: { src: Images.USER_PLACEHOLDER, style: { display: 'none' } }
        }}
      />

      <Flex
        direction={'column'}
        width={isMobile ? containerWidth : containerWidth / 2}
        flex={1}
        marginTop={isMobile ? 30 : 30}
        marginBottom={30}
      >
        {/* PAY NOW */}
        {step === 'pay' && (
          <BlankOverviewBox
            overrides={{
              BlankOverviewBox: {
                width: '100%',
                borderRadius: '5px 5px 5px 5px',
                padding: '40px 40px 40px 40px',
                marginTop: 0,
                backgroundColor: Colors.container
              }
            }}
          >
            {loading ? (
              <Flex
                direction={'column'}
                width={'100%'}
                justifyContent="center"
                alignItems={'center'}
              >
                <Loader
                  type="bubbles"
                  color={Colors.loader}
                  width={75}
                  height={75}
                />
              </Flex>
            ) : (
              <Flex direction={'column'} width={'100%'} justifyContent="center">
                <Text
                  fontFamily="Inter"
                  fontSize="32px"
                  fontWeight="700"
                  color="rgba(13,26,38,1)"
                  lineHeight="40px"
                  children={'Subscription Purchase'}
                />

                <Flex direction={'row'} rowSpan={2} marginTop={30}>
                  <View width={'50%'}>
                    <Text
                      fontFamily="Inter"
                      fontSize="16px"
                      fontWeight="700"
                      lineHeight="20px"
                      children={Strings.NAME}
                    />
                    <Text
                      fontFamily="Inter"
                      fontSize="14px"
                      fontWeight="400"
                      lineHeight="24px"
                      children={name}
                      marginTop={10}
                    />
                  </View>
                  <View width={'50%'}>
                    <Text
                      fontFamily="Inter"
                      fontSize="16px"
                      fontWeight="700"
                      lineHeight="20px"
                      children={Strings.EMAIL}
                    />
                    <Text
                      fontFamily="Inter"
                      fontSize="14px"
                      fontWeight="400"
                      lineHeight="24px"
                      children={customer?.email}
                      marginTop={10}
                    />
                  </View>
                </Flex>
                <Flex direction={'row'} rowSpan={2} marginTop={10}>
                  <View width={'50%'}>
                    <Text
                      fontFamily="Inter"
                      fontSize="16px"
                      fontWeight="700"
                      lineHeight="20px"
                      children={Strings.ADDRESS}
                    />
                    <Text
                      fontFamily="Inter"
                      fontSize="14px"
                      fontWeight="400"
                      lineHeight="24px"
                      children={address}
                      marginTop={10}
                    />
                  </View>
                  {/* <View width={'50%'}>
                                <Text
                                fontFamily="Inter"
                                fontSize="16px"
                                fontWeight="700"
                                lineHeight="20px"
                                children={`${Strings.INVOICE} #`} />
                                <Text
                                fontFamily="Inter"
                                fontSize="14px"
                                fontWeight="400"
                                lineHeight="24px"
                                children={invoices[0].id}
                                marginTop={10} />
                            </View> */}
                </Flex>
                <Flex direction={'column'} marginTop={40}>
                  {productId ? (
                    <Text
                      fontFamily="Inter"
                      fontSize="24px"
                      fontWeight="300"
                      lineHeight="20px"
                      children={`Selected Product`}
                    />
                  ) : (
                    <Text
                      fontFamily="Inter"
                      fontSize="24px"
                      fontWeight="300"
                      lineHeight="20px"
                      children={`Product Selection`}
                    />
                  )}
                </Flex>
                {productId ? (
                  <Flex
                    direction={'column'}
                    width={'100%'}
                    marginTop={10}
                    marginBottom={15}
                  >
                    <Flex direction={'column'}>
                      <SubscriptionLineItemInset
                        right={0}
                        width={'100%'}
                        marginBottom={20}
                        overrides={{
                          SubscriptionLineItem: { width: '10px' },
                          Title: {
                            children: _.get(product, 'description', ''),
                            fontSize: '18px',
                            lineHeight: '25px'
                          },
                          Description: {
                            children: _.get(product, '', ''),
                            width: '100%'
                          },
                          Amount: {
                            children: `$${_.get(
                              product,
                              'prices.0.amount'
                            )} / ${_.get(product, 'prices.0.frequency')}`,
                            left: 220,
                            textAlign: 'right',
                            fontSize: '18px'
                          }
                        }}
                      />
                    </Flex>
                  </Flex>
                ) : (
                  <Flex
                    direction={'column'}
                    width={'100%'}
                    marginTop={10}
                    marginBottom={15}
                  >
                    <Flex
                      direction={'row'}
                      alignItems="center"
                      justifyContent={'space-between'}
                    >
                      <SelectField
                        width={isMobile ? '100%' : '60%'}
                        placeholder={Strings.SELECT_A_SUBSCRIPTION}
                        value={selectedProductId}
                        onChange={(e) => setSelectedProductId(e.target.value)}
                      >
                        {_.map(products, (i) => (
                          <option
                            key={i.id}
                            value={_.get(i, 'id', '')}
                          >{`${_.get(i, 'description', '')} $${_.get(
                            i,
                            'prices.0.amount'
                          )} / ${_.get(i, 'prices.0.frequency')}`}</option>
                        ))}
                      </SelectField>
                      <TextField
                        placeholder={Strings.QUANTITY}
                        value={selectedQuanity}
                        onChange={(e) =>
                          setSelectedQuanity(e.currentTarget.value)
                        }
                        type="number"
                      />
                      <Button
                        disabled={addProductDisabled}
                        variation="primary"
                        size="small"
                        width={42}
                        height={42}
                        children={<IconAdd />}
                        alignSelf="flex-end"
                        onClick={addProduct}
                      />
                    </Flex>

                    {quantityMaxError && (
                      <Text
                        children={quantityMaxError}
                        color="red"
                        marginTop={-10}
                      />
                    )}

                    <Flex direction={'column'} marginTop={-7}>
                      <Link
                        width={150}
                        fontSize={'14px'}
                        color={Colors.activeText}
                        children={
                          serviceMeta.service_address ||
                          serviceMeta.service_place
                            ? Strings.EDIT_SERVICE_ADDRESS
                            : Strings.ADD_SERVICE_ADDRESS
                        }
                        onClick={() => setStep('sa')}
                      />
                      {serviceMeta.service_address && (
                        <Text
                          marginTop={-5}
                          children={
                            'Service Address: ' +
                            getFriendlyAddressMeta(serviceMeta)
                          }
                        />
                      )}
                    </Flex>

                    <Flex direction={'column'} marginTop={15} marginBottom={20}>
                      {_.map(subscriptionProducts, (i) => (
                        <SubscriptionLineItemInset
                          key={i.product.id}
                          right={0}
                          width={'100%'}
                          marginBottom={-15}
                          overrides={{
                            SubscriptionLineItem: { width: '10px' },
                            Title: {
                              children: `(${_.get(i, 'quantity', '')}x) ${_.get(
                                i.product,
                                'description',
                                ''
                              )}`,
                              fontSize: '16px',
                              lineHeight: '25px',
                              width: '100%'
                            },
                            Description: {
                              children: 'Remove',
                              onClick: () => removeProduct(i.product.id),
                              style: { cursor: 'pointer' },
                              color: 'red',
                              width: 150
                            },
                            Amount: {
                              children: `$${_.get(
                                i.product,
                                'prices.0.amount'
                              )} / ${_.get(i.product, 'prices.0.frequency')}`,
                              left: 220,
                              textAlign: 'right',
                              fontSize: '18px'
                            }
                          }}
                        />
                      ))}
                    </Flex>
                  </Flex>
                )}
                <Flex
                  justifyContent={'space-between'}
                  direction={'row'}
                  width={'100%'}
                  marginTop={10}
                  marginBottom={15}
                >
                  <Text
                    fontFamily="Inter-ExtraBold"
                    fontSize="28px"
                    fontWeight="300"
                    lineHeight="20px"
                    children={`${Strings.TOTAL} (USD)`}
                  />
                  {productId ? (
                    <Text
                      fontFamily="Inter-ExtraBold"
                      fontSize="28px"
                      fontWeight="300"
                      lineHeight="20px"
                      alignSelf={'flex-end'}
                      children={`${currencyFormatter.format(
                        _.get(product, 'prices.0.amount'),
                        { code: 'USD' }
                      )}`}
                    />
                  ) : (
                    <Text
                      fontFamily="Inter-ExtraBold"
                      fontSize="28px"
                      fontWeight="300"
                      lineHeight="20px"
                      alignSelf={'flex-end'}
                      children={`${currencyFormatter.format(subscriptionTotal, {
                        code: 'USD'
                      })}`}
                    />
                  )}
                </Flex>
                <View marginBottom={20}>
                  {_.size(paymentMethods) > 0 &&
                    _.filter(paymentMethods, (i) =>
                      isPaymentMethodValid(i.category)
                    ).length > 0 && (
                      <RadioGroupField
                        label={Strings.SELECT_PAYMENT_METHOD}
                        value={paymentToken}
                        onChange={(e) => setPaymentToken(e.target.value)}
                      >
                        {_.map(
                          paymentMethods,
                          (i) =>
                            isPaymentMethodValid(i.category) && (
                              <Radio
                                key={_.get(i, 'token', '')}
                                value={_.get(i, 'id', '')}
                                onChange={(e) =>
                                  setPaymentToken(e.target.value)
                                }
                              >
                                {i.brand && (
                                  <View style={{ marginLeft: 15 }}>
                                    <PaymentType
                                      customerId={customer.id}
                                      paymentMethodId={i.id}
                                    />
                                  </View>
                                )}
                                {/* {_.get(i, 'category', '') === 'DDA' && <Text children={`${Strings.BANK} ${Strings.ENDING_IN.toLowerCase()} ${_.get(i, 'last', 'XXXX')}`} />} */}
                              </Radio>
                            )
                        )}
                      </RadioGroupField>
                    )}

                  <Flex direction={'column'} marginTop={15}>
                    <Link
                      fontSize={'14px'}
                      color={Colors.activeText}
                      children={Strings.ADD_PAYMENT_METHOD}
                      onClick={() => setStep('spt')}
                    />
                  </Flex>
                </View>

                <Button
                  variation="primary"
                  hidden={payFormLoading || !isFormValid()}
                  isDisabled={payFormLoading || !isFormValid()}
                  onClick={() =>
                    paymentToken &&
                    (_.size(subscriptionProducts) > 0 || product)
                      ? setStep('cp')
                      : null
                  }
                  isLoading={payFormLoading}
                  children={Strings.NEXT}
                  width={'100%'}
                />
              </Flex>
            )}
          </BlankOverviewBox>
        )}
        {/* PAY CONFIRMATION */}
        {step === 'cp' && (
          <BlankOverviewBox
            overrides={{
              BlankOverviewBox: {
                width: '100%',
                borderRadius: '5px 5px 5px 5px',
                padding: '40px 40px 40px 40px',
                marginTop: 0,
                backgroundColor: Colors.container
              }
            }}
          >
            {loading ? (
              <Flex
                direction={'column'}
                width={'100%'}
                justifyContent="center"
                alignItems={'center'}
              >
                <Loader
                  type="bubbles"
                  color={Colors.loader}
                  width={75}
                  height={75}
                />
              </Flex>
            ) : (
              <Flex direction={'column'} width={'100%'} justifyContent="center">
                <Text
                  fontFamily="Inter"
                  fontSize="32px"
                  fontWeight="700"
                  color="rgba(13,26,38,1)"
                  lineHeight="40px"
                  children={'Payment Confirmation'}
                />

                <View>
                  <Link
                    onClick={() => setStep('pay')}
                    children={'Product Selection'}
                  />{' '}
                  / <Link color={'black'} children={'Payment Confirmation'} />
                </View>

                <Flex direction={'row'} rowSpan={2} marginTop={30}>
                  <View width={'50%'}>
                    <Text
                      fontFamily="Inter"
                      fontSize="16px"
                      fontWeight="700"
                      lineHeight="20px"
                      children={Strings.NAME}
                    />
                    <Text
                      fontFamily="Inter"
                      fontSize="14px"
                      fontWeight="400"
                      lineHeight="24px"
                      children={name}
                      marginTop={10}
                    />
                  </View>
                  <View width={'50%'}>
                    <Text
                      fontFamily="Inter"
                      fontSize="16px"
                      fontWeight="700"
                      lineHeight="20px"
                      children={Strings.EMAIL}
                    />
                    <Text
                      fontFamily="Inter"
                      fontSize="14px"
                      fontWeight="400"
                      lineHeight="24px"
                      children={customer?.email}
                      marginTop={10}
                    />
                  </View>
                </Flex>
                <Flex direction={'row'} rowSpan={2} marginTop={10}>
                  <View width={'50%'}>
                    <Text
                      fontFamily="Inter"
                      fontSize="16px"
                      fontWeight="700"
                      lineHeight="20px"
                      children={Strings.ADDRESS}
                    />
                    <Text
                      fontFamily="Inter"
                      fontSize="14px"
                      fontWeight="400"
                      lineHeight="24px"
                      children={address}
                      marginTop={10}
                    />
                  </View>
                  {/* <View width={'50%'}>
                                <Text
                                fontFamily="Inter"
                                fontSize="16px"
                                fontWeight="700"
                                lineHeight="20px"
                                children={`${Strings.INVOICE} #`} />
                                <Text
                                fontFamily="Inter"
                                fontSize="14px"
                                fontWeight="400"
                                lineHeight="24px"
                                children={invoices[0].id}
                                marginTop={10} />
                            </View> */}
                </Flex>
                <Flex direction={'column'} marginTop={40}>
                  <Text
                    fontFamily="Inter"
                    fontSize="24px"
                    fontWeight="300"
                    lineHeight="20px"
                    children={`Selected Products`}
                  />
                </Flex>
                {productId ? (
                  <Flex
                    direction={'column'}
                    width={'100%'}
                    marginTop={10}
                    marginBottom={15}
                  >
                    <Flex direction={'column'}>
                      <SubscriptionLineItemInset
                        right={0}
                        width={'100%'}
                        marginBottom={20}
                        overrides={{
                          SubscriptionLineItem: { width: '10px' },
                          Title: {
                            children: _.get(product, 'description', ''),
                            fontSize: '18px',
                            lineHeight: '25px'
                          },
                          Description: {
                            children: _.get(product, '', ''),
                            width: '100%'
                          },
                          Amount: {
                            children: `$${_.get(
                              product,
                              'prices.0.amount'
                            )} / ${_.get(product, 'prices.0.frequency')}`,
                            left: 220,
                            textAlign: 'right',
                            fontSize: '18px'
                          }
                        }}
                      />
                    </Flex>
                  </Flex>
                ) : (
                  <Flex
                    direction={'column'}
                    width={'100%'}
                    marginTop={0}
                    marginBottom={15}
                  >
                    <Flex direction={'column'} marginTop={0}>
                      {serviceMeta.service_address && (
                        <Text
                          marginTop={-5}
                          children={
                            'Service Address: ' +
                            getFriendlyAddressMeta(serviceMeta)
                          }
                        />
                      )}
                    </Flex>

                    <Flex direction={'column'} marginTop={10} marginBottom={20}>
                      {_.map(subscriptionProducts, (i) => (
                        <SubscriptionLineItemInset
                          key={i.product.id}
                          right={0}
                          width={'100%'}
                          marginBottom={-15}
                          overrides={{
                            SubscriptionLineItem: { width: '10px' },
                            Title: {
                              children: `(${_.get(i, 'quantity', '')}x) ${_.get(
                                i.product,
                                'description',
                                ''
                              )}`,
                              fontSize: '16px',
                              lineHeight: '25px',
                              width: '100%'
                            },
                            //'Description': { children: 'Remove', onClick: () => removeProduct(i.product.id), style: { cursor: 'pointer' }, color: 'red', width: 150},
                            Description: {
                              children: '',
                              style: { cursor: 'pointer' },
                              color: 'red',
                              width: 150
                            },
                            Amount: {
                              children: `$${_.get(
                                i.product,
                                'prices.0.amount'
                              )} / ${_.get(i.product, 'prices.0.frequency')}`,
                              left: 220,
                              textAlign: 'right',
                              fontSize: '18px'
                            }
                          }}
                        />
                      ))}
                    </Flex>
                  </Flex>
                )}
                <Link
                  style={{ marginTop: -50 }}
                  onClick={() => setStep('pay')}
                  children={'Edit Products'}
                />
                <Flex
                  justifyContent={'space-between'}
                  direction={'row'}
                  width={'100%'}
                  marginTop={10}
                  marginBottom={15}
                >
                  <Text
                    fontFamily="Inter-ExtraBold"
                    fontSize="28px"
                    fontWeight="300"
                    lineHeight="20px"
                    children={`${Strings.TOTAL} (USD)`}
                  />
                  {productId ? (
                    <Text
                      fontFamily="Inter-ExtraBold"
                      fontSize="28px"
                      fontWeight="300"
                      lineHeight="20px"
                      alignSelf={'flex-end'}
                      children={`${currencyFormatter.format(
                        _.get(product, 'prices.0.amount'),
                        { code: 'USD' }
                      )}`}
                    />
                  ) : (
                    <Text
                      fontFamily="Inter-ExtraBold"
                      fontSize="28px"
                      fontWeight="300"
                      lineHeight="20px"
                      alignSelf={'flex-end'}
                      children={`${currencyFormatter.format(subscriptionTotal, {
                        code: 'USD'
                      })}`}
                    />
                  )}
                </Flex>
                <View marginBottom={20}>
                  {_.size(paymentMethods) > 0 && (
                    <RadioGroupField
                      label={Strings.SELECT_PAYMENT_METHOD}
                      value={paymentToken}
                      onChange={(e) => setPaymentToken(e.target.value)}
                    >
                      {_.map(paymentMethods, (i) => (
                        <Radio
                          key={_.get(i, 'token', '')}
                          value={_.get(i, 'id', '')}
                          onChange={(e) => setPaymentToken(e.target.value)}
                        >
                          {i.brand && (
                            <View style={{ marginLeft: 15 }}>
                              <PaymentType
                                customerId={customer.id}
                                paymentMethodId={i.id}
                              />
                            </View>
                          )}
                          {/* {_.get(i, 'category', '') === 'DDA' && <Text children={`${Strings.BANK} ${Strings.ENDING_IN.toLowerCase()} ${_.get(i, 'last', 'XXXX')}`} />} */}
                        </Radio>
                      ))}
                    </RadioGroupField>
                  )}

                  {/* <View marginTop={15}>
                                <Link fontSize={'14px'} color={Colors.primary} children={Strings.ADD_PAYMENT_METHOD} onClick={() => setStep('spt')} />
                            </View> */}
                </View>

                <Button
                  variation={
                    paymentToken && _.size(subscriptionProducts) > 0
                      ? 'primary'
                      : ''
                  }
                  disabled={payFormLoading}
                  onClick={() =>
                    paymentToken &&
                    (_.size(subscriptionProducts) > 0 || product)
                      ? payNow()
                      : null
                  }
                  isLoading={payFormLoading}
                  children={Strings.SUBSCRIBE_NOW}
                  width={'100%'}
                />
              </Flex>
            )}
          </BlankOverviewBox>
        )}

        {/* SELECT PAYMENT TYPE */}
        {step === 'spt' && (
          <BlankOverviewBox
            overrides={{
              BlankOverviewBox: {
                width: '100%',
                borderRadius: '5px 5px 5px 5px',
                padding: '40px 40px 40px 40px',
                marginTop: 0,
                backgroundColor: Colors.container
              }
            }}
          >
            <Flex direction={'column'} width={'100%'}>
              <Text
                fontFamily="Inter"
                fontSize="32px"
                fontWeight="700"
                color="rgba(13,26,38,1)"
                lineHeight="40px"
                children={Strings.SELECT_PAYMENT_TYPE}
              />

              <View>
                <Link
                  onClick={() => setStep('pay')}
                  children={Strings.SUBSCRIPTION}
                />{' '}
                /{' '}
                <Link color={'black'} children={Strings.SELECT_PAYMENT_TYPE} />
              </View>

              <Flex direction={'row'} rowSpan={2} marginTop={30}>
                {isPaymentMethodValid('CREDIT') && (
                  <ToggleButton
                    width={'50%'}
                    variation="primary"
                    size="large"
                    value="credit"
                    children={Strings.CREDIT_CARD}
                    onChange={(value) => {
                      setCreditPressed(true);
                      setDDAPressed(false);
                      setStep('cc');
                    }}
                    isPressed={creditPressed}
                  />
                )}
                {isPaymentMethodValid('DDA') && (
                  <ToggleButton
                    width={'50%'}
                    variation="primary"
                    size="large"
                    value="dda"
                    children={Strings.BANK_ACCOUNT}
                    onChange={(value) => {
                      setCreditPressed(false);
                      setDDAPressed(true);
                      setStep('dda');
                    }}
                    isPressed={ddaPressed}
                  />
                )}
              </Flex>
            </Flex>
          </BlankOverviewBox>
        )}

        {/* SERVICE ADDRESS */}
        {step === 'sa' && (
          <BlankOverviewBox
            overrides={{
              BlankOverviewBox: {
                width: '100%',
                borderRadius: '5px 5px 5px 5px',
                padding: '40px 40px 40px 40px',
                marginTop: 0,
                backgroundColor: Colors.container
              }
            }}
          >
            <Flex
              as={'form'}
              onSubmit={(e) => addServiceAddress(e)}
              direction={'column'}
              width={'100%'}
            >
              <Text
                fontFamily="Inter"
                fontSize="32px"
                fontWeight="700"
                color="rgba(13,26,38,1)"
                lineHeight="40px"
                children={Strings.SERVICE_ADDRESS}
              />

              <View>
                <Link
                  onClick={() => setStep('pay')}
                  children={Strings.SUBSCRIPTION}
                />{' '}
                / <Link color={'black'} children={Strings.SERVICE_ADDRESS} />
              </View>

              <View marginTop={30}>
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="700"
                  lineHeight="20px"
                  children={Strings.DESCRIPTION}
                />

                <TextField
                  isRequired={true}
                  marginTop={10}
                  placeholder={Strings.DESCRIPTION}
                  value={servicePlace}
                  onChange={(e) => setServicePlace(e.currentTarget.value)}
                />
              </View>

              {/* ADDRESS INFORMATION */}
              <View marginTop={0}>
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="700"
                  lineHeight="20px"
                  children={Strings.ADDRESS}
                />

                <TextField
                  isRequired={true}
                  marginTop={10}
                  placeholder={Strings.ADDRESS}
                  value={serviceAddress}
                  onChange={(e) => setServiceAddress(e.currentTarget.value)}
                />

                <TextField
                  marginTop={10}
                  placeholder={Strings.ADDRESS_LINE_2}
                  value={serviceAddress2}
                  onChange={(e) => setServiceAddress2(e.currentTarget.value)}
                />
                <Flex>
                  <TextField
                    isRequired={true}
                    marginTop={10}
                    placeholder={Strings.CITY}
                    value={serviceCity}
                    onChange={(e) => setServiceCity(e.currentTarget.value)}
                  />
                  <SelectField
                    isRequired={true}
                    marginTop={10}
                    variation="default"
                    value={serviceState}
                    onChange={(e) => setServiceState(e.currentTarget.value)}
                  >
                    {Variables.STATES.map((state) => {
                      return (
                        <option
                          key={state.abbreviation}
                          value={state.abbreviation}
                        >
                          {state.name}
                        </option>
                      );
                    })}
                  </SelectField>
                  <TextField
                    isRequired={true}
                    marginTop={10}
                    placeholder={Strings.ZIP}
                    value={serviceZip}
                    onChange={(e) => setServiceZip(e.currentTarget.value)}
                  />
                </Flex>
              </View>

              <Flex
                direction={'row'}
                marginTop={35}
                justifyContent="flex-end"
                width={'100%'}
              >
                <Button
                  width={120}
                  variation="link"
                  onClick={() => setStep('pay')}
                  isLoading={serviceAddressFormLoading}
                >
                  {Strings.CANCEL}
                </Button>
                <Button
                  width={120}
                  variation="primary"
                  type="submit"
                  onClick={addServiceAddress}
                  isLoading={serviceAddressFormLoading}
                >
                  {Strings.NEXT}
                </Button>
              </Flex>
            </Flex>
          </BlankOverviewBox>
        )}

        {/* CREDIT CARD */}
        {step === 'cc' && (
          <BlankOverviewBox
            overrides={{
              BlankOverviewBox: {
                width: '100%',
                borderRadius: '5px 5px 5px 5px',
                padding: '40px 40px 40px 40px',
                marginTop: 0,
                backgroundColor: Colors.container
              }
            }}
          >
            <Flex
              as={'form'}
              onSubmit={(e) => addCardSubmit(e)}
              direction={'column'}
              width={'100%'}
            >
              <Text
                fontFamily="Inter"
                fontSize="32px"
                fontWeight="700"
                color="rgba(13,26,38,1)"
                lineHeight="40px"
                children={Strings.CREDIT_CARD}
              />

              <View>
                <Link
                  onClick={() => setStep('pay')}
                  children={Strings.SUBSCRIPTION}
                />{' '}
                /{' '}
                <Link
                  onClick={() => setStep('spt')}
                  children={Strings.SELECT_PAYMENT_TYPE}
                />{' '}
                / <Link color={'black'} children={Strings.CREDIT_CARD} />
              </View>

              <Image src={Images.CARD_BRANDS} width={width / 9} />

              <Flex direction={'column'} rowSpan={5} marginTop={30}>
                <View width={'100%'} marginTop={-30}>
                  {/* Card Iframe goes here */}
                  {vaultCardIframe()}
                </View>
              </Flex>

              <Flex
                direction={'row'}
                marginTop={35}
                justifyContent="flex-end"
                width={'100%'}
              >
                <Button
                  width={120}
                  variation="primary"
                  type="submit"
                  onClick={addCardSubmit}
                  disabled={cardFormLoading}
                  isLoading={cardFormLoading}
                >
                  {!cardFormLoading ? (
                    Strings.NEXT
                  ) : (
                    <Loader
                      type="bubbles"
                      color={Colors.loader}
                      height={25}
                      width={25}
                    />
                  )}
                </Button>
              </Flex>
            </Flex>
          </BlankOverviewBox>
        )}

        {/* BANK ACCOUNT */}
        {step === 'dda' && (
          <BlankOverviewBox
            overrides={{
              BlankOverviewBox: {
                width: '100%',
                borderRadius: '5px 5px 5px 5px',
                padding: '40px 40px 40px 40px',
                marginTop: 0,
                backgroundColor: Colors.container
              }
            }}
          >
            <Flex
              as={'form'}
              onSubmit={(e) => addBankSubmit(e)}
              direction={'column'}
              width={'100%'}
            >
              <Text
                fontFamily="Inter"
                fontSize="32px"
                fontWeight="700"
                color="rgba(13,26,38,1)"
                lineHeight="40px"
                children={Strings.BANK_ACCOUNT}
              />
              <View>
                <Link
                  onClick={() => setStep('pay')}
                  children={Strings.SUBSCRIPTION}
                />{' '}
                /{' '}
                <Link
                  onClick={() => setStep('spt')}
                  children={Strings.SELECT_PAYMENT_TYPE}
                />{' '}
                / <Link color={'black'} children={Strings.BANK_ACCOUNT} />
              </View>
              <Flex direction={'column'} rowSpan={2} marginTop={30}>
                <View width={'100%'} marginTop={-30}>
                  {/* Bank Iframe goes here */}
                  {vaultBankIframe()}
                </View>
              </Flex>
              <Flex
                direction={'row'}
                marginTop={35}
                justifyContent="flex-end"
                width={'100%'}
              >
                <Button
                  width={120}
                  variation="primary"
                  type="submit"
                  onClick={addBankSubmit}
                  disabled={bankFormLoading}
                  isLoading={bankFormLoading}
                >
                  {!bankFormLoading ? (
                    Strings.NEXT
                  ) : (
                    <Loader
                      type="bubbles"
                      color={Colors.loader}
                      height={25}
                      width={25}
                    />
                  )}
                </Button>
              </Flex>
            </Flex>
          </BlankOverviewBox>
        )}

        {/* PAYMENT SUCCESSFUL */}
        {step === 'ps' && (
          <BlankOverviewBox
            overrides={{
              BlankOverviewBox: {
                width: '100%',
                borderRadius: '5px 5px 5px 5px',
                padding: '40px 40px 40px 40px',
                marginTop: 0,
                backgroundColor: Colors.container
              }
            }}
          >
            <Flex
              as={'form'}
              onSubmit={(e) => addBankSubmit(e)}
              direction={'column'}
              width={'100%'}
            >
              <Text
                fontFamily="Inter"
                fontSize="32px"
                fontWeight="700"
                color="rgba(13,26,38,1)"
                lineHeight="40px"
                children={Strings.PAYMENT_SUCCESSFUL}
              />

              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                lineHeight="24px"
                letterSpacing="0.01px"
                children={Strings.THANK_YOU_FOR_YOUR_PAYMENT}
              />

              {/* PAYMENT SUCCESSFUL */}
              <Flex
                direction={'row'}
                rowSpan={2}
                marginTop={30}
                justifyContent="center"
                alignItems={'center'}
              >
                <IoCheckmarkDoneOutline color="green" size={125} />
              </Flex>

              <Flex
                direction={'row'}
                marginTop={35}
                justifyContent="flex-end"
                width={'100%'}
              >
                <Button
                  width={120}
                  variation="primary"
                  onClick={() => goToOverview()}
                >
                  {Strings.DONE}
                </Button>
              </Flex>
            </Flex>
          </BlankOverviewBox>
        )}

        {/* PAYMENT FAILED */}
        {step === 'error' && (
          <BlankOverviewBox
            overrides={{
              BlankOverviewBox: {
                width: '100%',
                borderRadius: '5px 5px 5px 5px',
                padding: '40px 40px 40px 40px',
                marginTop: 0,
                backgroundColor: Colors.container
              }
            }}
          >
            <Flex
              as={'form'}
              onSubmit={(e) => addBankSubmit(e)}
              direction={'column'}
              width={'100%'}
            >
              <Text
                fontFamily="Inter"
                fontSize="32px"
                fontWeight="700"
                color="rgba(13,26,38,1)"
                lineHeight="40px"
                children={Strings.PAYMENT_FAILED}
              />

              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                lineHeight="24px"
                letterSpacing="0.01px"
                children={Strings.UNABLE_TO_PROCESS_PAYMENT}
              />

              {/* PAYMENT SUCCESSFUL */}
              <Flex
                direction={'row'}
                rowSpan={2}
                marginTop={30}
                justifyContent="center"
                alignItems={'center'}
              >
                <IconError color="red" size={125} />
              </Flex>

              <Flex
                direction={'row'}
                marginTop={35}
                justifyContent="flex-end"
                width={'100%'}
              >
                <Button
                  width={120}
                  variation="primary"
                  onClick={() => setStep('pay')}
                >
                  {Strings.TRY_AGAIN}
                </Button>
              </Flex>
            </Flex>
          </BlankOverviewBox>
        )}
      </Flex>

      <Modal visible={modalView} animationType="slide" transparent>
        <BlankOverviewBox
          overrides={{
            BlankOverviewBox: {
              width: isMobile ? width / 1.1 : width / 2.5,
              alignSelf: 'center',
              marginTop: isMobile ? 20 : 50,
              alignContent: 'center',
              justifyContent: 'center'
            }
          }}
        >
          <Flex direction={'column'} paddingLeft={30} paddingRight={30}>
            <ScrollView height={700}>
              <TermsConditions />
            </ScrollView>
            <Flex
              direction={'row'}
              marginTop={35}
              justifyContent="flex-end"
              width={'100%'}
            >
              <Button
                variation="link"
                onClick={() => {
                  setModalView(false);
                }}
              >
                {Strings.CLOSE}
              </Button>
              <Button
                variation="primary"
                onClick={() => {
                  setAgree(true);
                  setModalView(false);
                }}
              >
                {Strings.AGREE}
              </Button>
            </Flex>
          </Flex>
        </BlankOverviewBox>
      </Modal>

      {modalView && (
        <View
          height={'100%'}
          width={width}
          backgroundColor={'rgba(0,0,0,0.5)'}
          top={0}
          left={0}
          bottom={0}
          position="absolute"
        />
      )}

      <ToastContainer />

      <Footer />
    </Flex>
  );
}

import SignUpScreen from '../screens/SignUpScreen';
import OverviewScreen from '../screens/OverviewScreen';
import PaymentMethodsScreen from '../screens/PaymentMethodsScreen';
import SubscribeScreen from '../screens/SubscribeScreen';
import PayScreen from '../screens/PayScreen';
import SubsciptionsScreen from '../screens/SubscriptionsScreen';
import BillingHistoryScreen from '../screens/BillingHistoryScreen';
import SubscriptionsStoreScreen from '../screens/SubscriptionsStoreScreen';
import TermsScreen from '../screens/TermsScreen';
import Error404Screen from '../screens/ErrorScreen/404';

export const Screens = {
  SignUpScreen,
  OverviewScreen,
  PaymentMethodsScreen,
  PayScreen,
  SubsciptionsScreen,
  BillingHistoryScreen,
  SubscriptionsStoreScreen,
  Error404Screen,
  SubscribeScreen,
  TermsScreen
}

export default Screens;
import React from 'react';
import ReactDOM from 'react-dom';
import { Amplify } from 'aws-amplify';
import { AmplifyProvider, Authenticator } from '@aws-amplify/ui-react';

import App from './App';
import { Colors } from './constants';
import awsconfig from './aws-exports';
import reportWebVitals from './reportWebVitals';

import './index.css';
import '@aws-amplify/ui-react/styles.css';
import 'react-toastify/dist/ReactToastify.css';

Amplify.configure(awsconfig);

const theme = {
  name: 'my-theme',
  tokens: {
    fonts: {
      default: {
        variable: { value: 'Inter-Regular, sans-serif' },
        static: { value: 'Inter-Regular, sans-serif' },
      },
    },
    components: {
      text: {
        color: { value: Colors.black }
      },
      tabs: {
        item: {
          active: {
            color: { value: Colors.primary },
            border: {
              color: { value: Colors.secondary }
            },
          },
          color:{ value: Colors.secondary },
          focus: {
            color:{ value: Colors.primary }
          },
          //color:{ value: Colors.secondary }
        }
      },
      button: {
        // this will affect the font weight of all button variants
        fontWeight: { value: '{fontWeights.black.value}' },
        // style the primary variation
        primary: {
          backgroundColor: { value: Colors.secondary },
          _hover: {
            backgroundColor: { value: Colors.secondaryHover },
          },
          _focus: {
            backgroundColor: { value: Colors.secondaryHover },
          },
        },
      },
      fieldcontrol: {
        _focus: {
          borderColor: { value: Colors.primary }
        }
      }
      
    },
  },
};

ReactDOM.render(
  <React.StrictMode>    
    <AmplifyProvider theme={theme}>
      <Authenticator.Provider>
        <App />
      </Authenticator.Provider>      
    </AmplifyProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

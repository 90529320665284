export const ScreenNames = {
  HOME: '/',
  TERMS: `/terms`,
  ERROR404: `/404`,
  SIGN_UP: `/sign-up`,
  SIGN_IN: `/sign-in`,
  OVERVIEW: `/overview`,
  INVOICE: `/invoices/pay`,
  SUBSCRIBE: `/subscribe`,
  BILLING_HISTORY: `/billing`,
  SUBSCRIPTIONS: `/subscriptions`,
  PAYMENT_METHODS: `/payment-methods`,
  SUBSCRIPTIONS_STORE: `/subscriptions-storefront`
};

export default ScreenNames;

/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Text, View } from "@aws-amplify/ui-react";
export default function SubscriptionLineItem(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="474px"
      height="82px"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "SubscriptionLineItem")}
    >
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="400"
        color="rgba(13,26,38,1)"
        lineHeight="24px"
        textAlign="left"
        display="flex"
        direction="column"
        justifyContent="flex-start"
        position="absolute"
        top="37.8%"
        bottom="32.93%"
        left="0%"
        right="48.52%"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Subtitle"
        {...getOverrideProps(overrides, "Subtitle")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="700"
        color="rgba(13,26,38,1)"
        lineHeight="18px"
        textAlign="right"
        display="flex"
        direction="column"
        justifyContent="flex-start"
        letterSpacing="0.41px"
        position="absolute"
        top="4.88%"
        bottom="73.17%"
        left="58.44%"
        right="0%"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Amount"
        {...getOverrideProps(overrides, "Amount")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="700"
        color="rgba(238,49,57,1)"
        lineHeight="18px"
        textAlign="left"
        display="flex"
        direction="column"
        justifyContent="flex-start"
        letterSpacing="0.41px"
        position="absolute"
        top="78.05%"
        bottom="0%"
        left="0.21%"
        right="48.52%"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Action"
        {...getOverrideProps(overrides, "Action")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="700"
        color="rgba(13,26,38,1)"
        lineHeight="18px"
        textAlign="left"
        display="flex"
        direction="column"
        justifyContent="flex-start"
        letterSpacing="0.41px"
        position="absolute"
        top="4.88%"
        bottom="73.17%"
        left="0.21%"
        right="48.52%"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Title"
        {...getOverrideProps(overrides, "Title")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="700"
        color="rgba(238,49,57,1)"
        lineHeight="18px"
        textAlign="right"
        display="flex"
        direction="column"
        justifyContent="flex-start"
        letterSpacing="0.41px"
        position="absolute"
        top="78.05%"
        bottom="0%"
        left="56.54%"
        right="0%"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Action2"
        {...getOverrideProps(overrides, "Action2")}
      ></Text>
    </View>
  );
}

import colors from './Colors';
import images from './Images';
import screens from './Screens';
import strings from './Strings';
import variables from './Variables';
import screenNames from './ScreenNames';

export const Colors = colors;
export const Images = images;
export const ScreenNames = screenNames;
export const Screens = screens;
export const Strings = strings;
export const Variables = variables;

const constants = {
  Colors,
  Images,
  Strings,
  Screens,
  Variables,
  ScreenNames
};

export default constants;
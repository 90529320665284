/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Flex, View, Text, Grid,  Link, Image } from '@aws-amplify/ui-react';
import { MdPhone, MdEmail } from 'react-icons/md';
import { useWindowDimensions } from "react-native";

export default function Footer(props) {

    const { height, width } = useWindowDimensions();
    const [isMobile, setIsMobile] = useState(() => {
        if(width < 768) return true
        else return false;
    });
    useEffect(() => {
        if(width < 768) setIsMobile(true)
        else return setIsMobile(false);
    },[width]);

    return (
        <View backgroundColor={'#2E3138'} >
            <Grid
                justifyContent={'center'}
                minHeight={height / 3.1}
                paddingTop={60}
                //paddingBottom={30}
                paddingLeft={170}
                paddingRight={170}                
                width={width}
                templateColumns={{ base: '1fr', large: '2fr 2fr 2fr 1fr'}}
                templateRows={{ large: '2rem'}}
                gap="var(--amplify-space-small)">

                    <View>
                        <Flex direction={'column'}>
                            <Text
                                fontFamily="Inter-Regular"
                                fontSize={isMobile ? '17px' : '17px'}
                                fontWeight="700"                                    
                                color="white"
                                left={30}
                                marginBottom={0}
                                children={`Contact Info`} />
                            <Text
                                fontFamily="Inter-Regular"
                                fontSize={isMobile ? '17px' : '17px'}
                                fontWeight="500"                                    
                                color="white"
                                left={30}
                                lineHeight="10px"
                                children={`2400 S. Arlington Heights Rd.`} />
                            <Text
                                fontFamily="Inter-Regular"
                                fontSize={isMobile ? '17px' : '17px'}
                                fontWeight="500"                                    
                                color="white"
                                left={30}
                                lineHeight="10px"
                                children={`Arlington Heights, IL 60005`} />
                            <Flex direction={'row'} alignItems={'center'}>
                                <MdPhone color='white' size={15} />
                                <Link
                                    target={'_blank'}
                                    href='tel:847-321-9428'
                                    fontFamily="Inter-Regular"
                                    fontSize={isMobile ? '17px' : '17px'}
                                    fontWeight="500"                                    
                                    color="white"
                                    left={30}
                                    lineHeight="10px"
                                    children={`(847) 321-9428)`} />
                            </Flex>
                            <Flex direction={'row'} alignItems={'center'}>
                                <MdEmail color='white' size={15} />
                                <Link
                                    target={'_blank'}
                                    href='mailto:customerservice@pmcomfort.com'
                                    fontFamily="Inter-Regular"
                                    fontSize={isMobile ? '17px' : '17px'}
                                    fontWeight="500"                                    
                                    color="white"
                                    left={30}
                                    lineHeight="10px"
                                    children={`customerservice@pmcomfort.com`} />
                            </Flex>
                        </Flex>
                    </View>
                    <View>
                        <Flex direction={'column'}>
                            <Text
                                fontFamily="Inter-Regular"
                                fontSize={isMobile ? '17px' : '17px'}
                                fontWeight="700"                                    
                                color="white"
                                left={30}
                                marginBottom={0}
                                children={`Showroom Hours`} />
                            <Text
                                fontFamily="Inter-Regular"
                                fontSize={isMobile ? '17px' : '17px'}
                                fontWeight="500"                                    
                                color="white"
                                left={30}
                                lineHeight="10px"
                                children={`Showroom hours are`} />
                            <Text
                                fontFamily="Inter-Regular"
                                fontSize={isMobile ? '17px' : '17px'}
                                fontWeight="500"                                    
                                color="white"
                                left={30}
                                lineHeight="10px"
                                children={`8:00 AM - 6:00PM`} />
                            <Text
                                fontFamily="Inter-Regular"
                                fontSize={isMobile ? '17px' : '17px'}
                                fontWeight="500"                                    
                                color="white"
                                left={30}
                                lineHeight="10px"
                                children={`by appointment only`} />
                        </Flex>
                    </View>
                    <View>
                        <Flex direction={'column'}>
                            <Text
                                fontFamily="Inter-Regular"
                                fontSize={isMobile ? '17px' : '17px'}
                                fontWeight="700"                                    
                                color="white"
                                left={30}
                                marginBottom={0}
                                children={`Site Links`} />
                            <Link
                            href='https://pmcomfort.com/'
                                fontFamily="Inter-Regular"
                                fontSize={isMobile ? '17px' : '17px'}
                                fontWeight="500"                                    
                                color="white"
                                left={30}
                                lineHeight="10px"
                                children={`Home`} />
                            <Link
                                href='https://pmcomfort.com/residential/'
                                fontFamily="Inter-Regular"
                                fontSize={isMobile ? '17px' : '17px'}
                                fontWeight="500"                                    
                                color="white"
                                left={30}
                                lineHeight="10px"
                                children={`Residential`} />
                            <Link
                                href='https://pmcomfort.com/commercial/'
                                fontFamily="Inter-Regular"
                                fontSize={isMobile ? '17px' : '17px'}
                                fontWeight="500"                                    
                                color="white"
                                left={30}
                                lineHeight="10px"
                                children={`Commercial`} />
                            <Link
                            href='https://pmcomfort.com/reviews/'
                                fontFamily="Inter-Regular"
                                fontSize={isMobile ? '17px' : '17px'}
                                fontWeight="500"                                    
                                color="white"
                                left={30}
                                lineHeight="10px"
                                children={`Reviews`} />
                            <Link
                            href='https://pmcomfort.com/product-ratings/'
                                fontFamily="Inter-Regular"
                                fontSize={isMobile ? '17px' : '17px'}
                                fontWeight="500"                                    
                                color="white"
                                left={30}
                                lineHeight="10px"
                                children={`Product Ratings`} />
                            <Link
                            href='https://pmcomfort.com/contact-us/'
                                fontFamily="Inter-Regular"
                                fontSize={isMobile ? '17px' : '17px'}
                                fontWeight="500"                                    
                                color="white"
                                left={30}
                                lineHeight="10px"
                                children={`Contact Us`} />
                        </Flex>
                    </View>
                    <View>
                        <Link href='https://pmcomfort.com'>
                            <Image width={300} src='https://pmcomfort.com/wp-content/uploads/2018/11/Paragon-No-Border_500x150.png' />
                        </Link>
                    </View>

            </Grid>
            {/* <Flex paddingBottom={10} paddingLeft={170} paddingRight={200} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                <Text
                    fontFamily="Inter-Regular"
                    fontSize={isMobile ? '17px' : '17px'}
                    fontWeight="500"                                    
                    color="white"
                    left={30}
                    lineHeight="10px"
                    children={`@ Paragon Mechanical`} />
                
            </Flex> */}

        </View>
    );
}

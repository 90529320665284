import _ from 'lodash';
import * as dayjs from 'dayjs';
import Loader from 'react-loading';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useWindowDimensions } from 'react-native';
import {
  useAuthenticator,
  Flex,
  View,
  Text,
  SelectField,
  Grid,
  IconChevronLeft,
  IconChevronRight,
  Button
} from '@aws-amplify/ui-react';
import {
  loadCustomerInvoices,
  loadCustomer,
  loadInvoiceTransactions,
  loadSubscriptions
} from '../../utils/rest';

import Footer from '../../components/Footer';
import Error404Screen from '../ErrorScreen/404';
import { Colors, Images, Strings, ScreenNames } from '../../constants';
import PaymentType from '../../components/Modals/CreditCard/PaymentType';
import {
  AppNavBar,
  SideBar,
  BlankOverviewBox,
  ChevronDown,
  ChevronUp
} from '../../ui-components';

export default function BillingHistoryScreen() {
  const navigate = useNavigate();

  const { height, width } = useWindowDimensions();
  const [isMobile, setIsMobile] = useState(() => {
    if (width < 768) return true;
    else return false;
  });
  useEffect(() => {
    if (width < 768) setIsMobile(true);
    else return setIsMobile(false);
  }, [width]);

  const [loading, setLoading] = useState(true);
  const { user, signOut } = useAuthenticator((context) => [context.user]);

  const [showInvoices, setShowInvoices] = useState({});
  const [customer, setCustomer] = useState({});
  const [subscriptions, setSubscriptions] = useState([]);

  const containerWidth = isMobile ? width / 1.1 : width / 1.2;

  const toggleInvoiceItem = (item) => {
    if (item.id === showInvoices) setShowInvoices('');
    else setShowInvoices(item.id);
  };

  //INVOICES
  const [invoices, setInvoices] = useState([]);

  //PAGINATION
  const [pagination, setPagination] = useState({
    pages: 10,
    page: 0,
    items: 20
  });
  const [paginationArry, setPaginationArry] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [prevPage, setPrevPage] = useState(0);

  //RENDERS
  // const getPaginationPageNumbers = () => {
  //     let arry = []
  //     for(let i = 0; i < pagination.pages; i++){
  //         arry.push(i)
  //     }
  //     setPaginationArry(arry)
  // }

  const [itemsPerPage] = useState(2);

  // const generatePagination = () => {
  //     const totalItems = _.size(invoices)
  //     const pages = Math.ceil(totalItems / itemsPerPage)
  //     let page = 0
  //     let items = 0
  //     if(pages >= (page++)) items = itemsPerPage

  //     let arry = []
  //     for(let i = 0; i < pages; i++){
  //         arry.push(i)
  //     }
  //     setPagination({ pages, page, items})
  //     console.log({ pages, page, items})
  //     setPaginationArry(arry)

  //     console.log({arry})
  // }

  const [pagX, setPagX] = useState(0);
  const [pagY, setPagY] = useState(itemsPerPage);

  const renderSubscriptionItem = (idx, item) => {
    let lastItem = false;
    if (idx === invoices.length - 1) lastItem = true;
    return (
      <BlankOverviewBox
        key={item.id}
        overrides={{
          BlankOverviewBox: {
            width: '100%',
            borderRadius: lastItem ? '0px 0px 0px 0px' : '0px 0px 0px 0px',
            marginTop: 0,
            backgroundColor: Colors.container
          }
        }}
      >
        <Flex
          direction={'column'}
          marginLeft={40}
          marginRight={40}
          width={'100%'}
        >
          <Grid
            onClick={() => toggleInvoiceItem(item)}
            style={{ cursor: 'pointer' }}
            templateColumns={{ base: '1fr', large: '1fr 1fr 1fr 1fr 1fr' }}
            templateRows={{ base: '2rem', large: '2rem' }}
            gap="var(--amplify-space-small)"
            alignItems={'center'}
          >
            <View>
              <Text
                fontFamily="Inter"
                fontSize="15px"
                fontWeight="600"
                lineHeight="20px"
                letterSpacing="1px"
                textAlign="left"
                children={dayjs(item.created).format('MM/DD/YYYY')}
              ></Text>
            </View>
            <View>
              <Text
                fontFamily="Inter"
                fontSize="15px"
                fontWeight="600"
                lineHeight="20px"
                letterSpacing="1px"
                textAlign="left"
                children={_.get(item, 'lineItems.0.productDescription')}
              ></Text>
            </View>
            <View>
              <Text
                fontFamily="Inter"
                fontSize="15px"
                fontWeight="600"
                lineHeight="20px"
                letterSpacing="1px"
                textAlign="left"
                children={
                  item.status.charAt(0).toUpperCase() + item.status.slice(1)
                }
              ></Text>
            </View>
            <View>
              <Flex
                direction={'row'}
                justifyContent="space-between"
                alignItems={'center'}
              >
                <Text
                  fontFamily="Inter"
                  fontSize="15px"
                  fontWeight="600"
                  lineHeight="20px"
                  letterSpacing="1px"
                  textAlign="left"
                  children={`$${item.total}`}
                ></Text>
                <View>
                  {
                    item.status === 'due' ? (
                      <Button
                        onClick={() => goToPay(item.id)}
                        size="small"
                        fontFamily="Inter"
                        fontSize="12px"
                        fontWeight="600"
                        lineHeight="20px"
                        letterSpacing="1px"
                        textAlign="center"
                      >
                        {Strings.PAY_NOW}
                      </Button>
                    ) : null
                    // <PaymentType customerId={customer.id} paymentMethodId={item.id} />
                  }
                </View>
              </Flex>
            </View>
            {showInvoices === item.id ? (
              <ChevronUp
                overrides={{
                  ChevronUp: { marginTop: 15, right: 50, position: 'absolute' }
                }}
              />
            ) : (
              <ChevronDown
                overrides={{
                  ChevronDown: {
                    marginTop: 15,
                    right: 50,
                    position: 'absolute'
                  }
                }}
              />
            )}
          </Grid>

          {showInvoices === item.id && (
            <Flex direction={'column'} rowSpan={3} marginTop={15}>
              <View
                width={'100%'}
                height={1}
                style={{ backgroundColor: '#ededed' }}
              />

              {item.subscriptionId ? (
                <>
                  <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="700"
                    lineHeight="20px"
                    marginTop={20}
                    children={Strings.PAYMENT_METHOD}
                  />
                  <PaymentType
                    customerId={customer.id}
                    paymentMethodId={
                      _.find(subscriptions, (i) => i.id === item.subscriptionId)
                        .paymentMethodIds[0]
                    }
                  />
                </>
              ) : (
                <Text
                  fontFamily="Inter"
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="20px"
                  marginTop={20}
                  children={'No payment method attached'}
                />
              )}

              {_.size(_.get(item, 'transactions', [])) > 0 ? (
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="700"
                  lineHeight="20px"
                  marginTop={20}
                  children={Strings.TRANSACTIONS}
                />
              ) : (
                <Text
                  fontFamily="Inter"
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="20px"
                  marginTop={20}
                  children={'No transactions available'}
                />
              )}

              {renderTransactions(_.get(item, 'transactions', []))}
            </Flex>
          )}

          {/* {showInvoices === item.id && <Flex marginTop={20} direction={'column'} width={'100%'} alignItems={'flex-end'}>
                        {
                            item.transactions.map(i => {
                                return(
                                    <SubscriptionLineItemInset key={i.id} overrides={{
                                        'SubscriptionLineItemInset': { width: '90%', marginBottom: 15,  },
                                        'Title': { children: `${Strings.TRANSACTION} #${i.id}`},
                                        'Description': { children: i.timestamp, width: '100%'},
                                        'Amount': { children: `$${i.amount}`, right: 0, textAlign: 'right'},
                                    }} /> 
                                )
                            })
                        }
                    </Flex>} */}
        </Flex>
      </BlankOverviewBox>
    );
  };

  const renderTransactions = (transactions) => {
    console.log('trans', transactions);
    return _.map(transactions, (i) => (
      <Flex
        key={i.id}
        direction={'row'}
        justifyContent="space-between"
        marginTop={15}
      >
        <View width={'33%'}>
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="700"
            lineHeight="20px"
            children={Strings.AMOUNT}
          />
          <Text
            fontFamily="Inter"
            fontSize="14px"
            fontWeight="400"
            lineHeight="24px"
            children={`$${i.amount}`}
            marginTop={10}
          />
        </View>
        <View width={'33%'}>
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="700"
            lineHeight="20px"
            children={Strings.DATE}
          />
          <Text
            fontFamily="Inter"
            fontSize="14px"
            fontWeight="400"
            lineHeight="24px"
            children={i.date ? dayjs(i.date).format('MM/DD/YYYY') : 'N/a'}
            marginTop={10}
          />
        </View>
        <View width={'33%'}>
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="700"
            lineHeight="20px"
            children={Strings.STATUS}
          />
          <Text
            fontFamily="Inter"
            fontSize="14px"
            fontWeight="400"
            lineHeight="24px"
            children={_.upperCase(i.status)}
            marginTop={10}
          />
        </View>
      </Flex>
    ));
  };

  //ALERTS
  // const successToast = () => toast.success(Strings.SUCCESSFULLY_UPDATED, { position: 'top-center', theme: 'colored'});
  // const errorToast = () => toast.error(Strings.ERROR_OCCURED, { position: 'top-center', theme: 'colored'});

  //ROUTES
  const goToOverview = () => navigate(ScreenNames.OVERVIEW);
  const gotToSubscriptions = () => navigate(ScreenNames.SUBSCRIPTIONS);
  const goToPaymentMethods = () => navigate(ScreenNames.PAYMENT_METHODS);
  const goToBillingHistory = () => navigate(ScreenNames.BILLING_HISTORY);
  const goToPay = (invoiceId, customerId) =>
    navigate(
      `${ScreenNames.INVOICE}?invoiceId=${invoiceId}&customerId=${customer.id}`
    );

  useEffect(() => {
    document.title = Strings.BILLING_HISTORY;
  }, []);

  useEffect(() => {
    (async () => {
      await loadCustomer(setCustomer);
    })();
  }, [user]);

  useEffect(() => {
    const execute = async () => {
      const customerId = _.get(customer, 'id', null);
      if (!_.isNull(customerId)) {
        await loadCustomerInvoices(customerId, (items) => {
          setInvoices(() => {
            return items.sort(function (a, b) {
              return new Date(b.created) - new Date(a.created);
            });
          });
        });
        setLoading(false);
        await loadSubscriptions({ id: customerId }, setSubscriptions);
      }
    };

    execute();
  }, [customer]);

  useEffect(() => {
    console.log({ invoices });

    const generatePagination = () => {
      const totalItems = _.size(invoices);
      const pages = Math.ceil(totalItems / itemsPerPage);
      let page = 0;
      let items = 0;
      if (pages >= page++) items = itemsPerPage;

      let arry = [];
      for (let i = 0; i < pages; i++) {
        arry.push(i);
      }
      setPagination({ pages, page, items });
      console.log({ pages, page, items });
      setPaginationArry(arry);

      console.log({ arry });
    };

    generatePagination();
  }, [invoices, itemsPerPage]);

  useEffect(() => {
    console.log({ subscriptions });
  }, [subscriptions]);

  useEffect(() => {
    (async () => {
      for await (let invoice of invoices) {
        const transactions = await loadInvoiceTransactions(invoice.id);
        const idx = _.findIndex(invoices, (i) => i.id === invoice.id);
        invoices[idx].transactions = transactions;
      }
    })();
  }, [invoices]);

  useEffect(() => {
    const getPaginationPageNumbers = () => {
      let arry = [];
      for (let i = 0; i < pagination.pages; i++) {
        arry.push(i);
      }
      setPaginationArry(arry);
    };

    const generatePaginationItems = (currentPage) => {
      //if(currentPage)
      if (currentPage === 0) {
        const oldX = 0;
        const oldY = itemsPerPage;
        setPagX(oldX);
        setPagY(oldY);
      }
      if (currentPage !== 0) {
        //if prev page is less than currentpage, go up
        if (prevPage < currentPage) {
          const oldX = pagX + itemsPerPage;
          const oldY = pagY + itemsPerPage;
          setPagX(oldX);
          setPagY(oldY);
        } else {
          const oldX = pagX - itemsPerPage;
          const oldY = pagY - itemsPerPage;
          setPagX(oldX);
          setPagY(oldY);
        }
      }
    };

    getPaginationPageNumbers();
    generatePaginationItems(currentPage);
  }, [
    currentPage,
    itemsPerPage,
    paginationArry,
    pagX,
    pagY,
    pagination.pages,
    prevPage
  ]);

  if (_.get(process.env, 'REACT_APP_ALLOW_SIGN_IN') === 'false')
    return <Error404Screen />;

  return (
    <Flex
      direction="column"
      alignItems={'center'}
      backgroundColor={Colors.surface}
      width={'100%'}
      minHeight="100vh"
      overflow={'hidden'}
    >
      <AppNavBar
        overrides={{
          AppNavBar: { width: '100%', backgroundColor: Colors.header },
          user: {
            fontFamily: 'Inter-Regular',
            color: Colors.headerText,
            children: `Sign out`,
            onClick: signOut,
            style: { cursor: 'pointer' }
          },
          Logo30873174: { src: process.env.REACT_APP_LOGO_URL },
          image: { src: Images.USER_PLACEHOLDER, style: { display: 'none' } }
        }}
      />

      <Flex
        direction={isMobile ? 'column' : 'row'}
        justifyContent={'space-evenly'}
        width={containerWidth}
        flex={1}
        marginTop={isMobile ? 10 : 25}
        marginBottom={30}
      >
        <Flex direction={'column'} width={isMobile ? '100%' : 313}>
          <SideBar
            overrides={{
              SideBar: { width: '100%', backgroundColor: Colors.container },
              Overview: {},
              OverviewFrame: {
                onClick: goToOverview,
                style: { cursor: 'pointer' }
              },
              Subscriptions: {},
              SubscriptionsFrame: {
                onClick: gotToSubscriptions,
                style: { cursor: 'pointer' }
              },
              'Payment Methods': {},
              PaymentMethodsFrame: {
                onClick: goToPaymentMethods,
                style: { cursor: 'pointer' }
              },
              'Billing History': {
                fontWeight: '600',
                color: Colors.activeText
              },
              BillingHistoryFrame: {
                onClick: goToBillingHistory,
                style: { cursor: 'pointer' }
              }
            }}
          />
        </Flex>
        <Flex
          direction={'column'}
          gap={'0.1rem'}
          width={isMobile ? '100%' : '80%'}
        >
          <BlankOverviewBox
            overrides={{
              BlankOverviewBox: {
                width: '100%',
                borderRadius: '5px 5px 0px 0px',
                marginBottom: 0,
                height: 100,
                alignItems: 'center',
                backgroundColor: Colors.container
              }
            }}
          >
            <Flex marginLeft={40} marginRight={40} width={'100%'}>
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="700"
                lineHeight="20px"
                letterSpacing="1px"
                children={Strings.BILLING_HISTORY.toUpperCase()}
              ></Text>
            </Flex>
          </BlankOverviewBox>

          {/* LOADING TEXT */}
          {loading && (
            <BlankOverviewBox
              overrides={{
                BlankOverviewBox: {
                  width: '100%',
                  borderRadius: '0px 0px 5px 5px',
                  marginTop: 0,
                  backgroundColor: Colors.container
                }
              }}
            >
              <Flex
                direction={'column'}
                width={'100%'}
                justifyContent="center"
                alignItems={'center'}
              >
                <Loader
                  type="bubbles"
                  color={Colors.loader}
                  width={75}
                  height={75}
                />
              </Flex>
            </BlankOverviewBox>
          )}

          {/* NO BILLING TEXT */}
          {!loading && _.size(invoices) === 0 && (
            <BlankOverviewBox
              overrides={{
                BlankOverviewBox: {
                  width: '100%',
                  borderRadius: '0px 0px 5px 5px',
                  marginTop: 0,
                  backgroundColor: Colors.container
                }
              }}
            >
              <Flex marginLeft={40} marginRight={40} width={'100%'}>
                <Text
                  fontFamily="Inter"
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="20px"
                  letterSpacing="1px"
                  children={Strings.NO_BILLING_HISTORY}
                ></Text>
              </Flex>
            </BlankOverviewBox>
          )}

          {/* BILLING HISTORY */}
          {!loading && !isMobile && (
            <BlankOverviewBox
              overrides={{
                BlankOverviewBox: {
                  width: '100%',
                  height: 50,
                  padding: '0px 0px 0px 0px',
                  borderRadius: '0px 0px 0px 0px',
                  marginTop: 0,
                  alignItems: 'center',
                  backgroundColor: Colors.container
                }
              }}
            >
              <Flex
                direction={'column'}
                marginLeft={40}
                marginRight={40}
                width={'100%'}
              >
                <Grid
                  templateColumns={{
                    base: '1fr',
                    large: '1fr 1fr 1fr 1fr 1fr'
                  }}
                  templateRows={{ base: '2rem', large: '2rem' }}
                  gap="var(--amplify-space-small)"
                  alignItems={'center'}
                >
                  <View>
                    <Text
                      fontFamily="Inter-ExtraBold"
                      fontSize="15px"
                      fontWeight="600"
                      lineHeight="20px"
                      letterSpacing="1px"
                      children={Strings.DATE}
                    ></Text>
                  </View>
                  <View>
                    <Text
                      fontFamily="Inter-ExtraBold"
                      fontSize="15px"
                      fontWeight="600"
                      lineHeight="20px"
                      letterSpacing="1px"
                      children={Strings.SUBSCRIPTION}
                    ></Text>
                  </View>
                  <View>
                    <Text
                      fontFamily="Inter-ExtraBold"
                      fontSize="15px"
                      fontWeight="600"
                      lineHeight="20px"
                      letterSpacing="1px"
                      children={Strings.STATUS}
                    ></Text>
                  </View>
                  <View>
                    <Text
                      fontFamily="Inter-ExtraBold"
                      fontSize="15px"
                      fontWeight="600"
                      lineHeight="20px"
                      letterSpacing="1px"
                      children={Strings.AMOUNT}
                    ></Text>
                  </View>
                </Grid>
              </Flex>
            </BlankOverviewBox>
          )}
          {_.map(invoices.slice(pagX, pagY), (item, idx) => {
            return renderSubscriptionItem(idx, item);
          })}

          {invoices.length > 0 && (
            <BlankOverviewBox
              overrides={{
                BlankOverviewBox: {
                  width: '100%',
                  borderRadius: '0px 0px 5px 5px',
                  marginTop: 0
                }
              }}
            >
              <Flex
                direction={'row'}
                justifyContent="center"
                alignItems={'center'}
                width={'100%'}
              >
                <Button
                  variation="link"
                  marginTop={5}
                  onClick={() => {
                    if (currentPage !== 0) {
                      setPrevPage(currentPage);
                      setCurrentPage(currentPage - 1);
                    }
                  }}
                >
                  <IconChevronLeft />
                </Button>
                <SelectField
                  variation="default"
                  value={currentPage}
                  onChange={(e) => {
                    setPrevPage(currentPage);
                    setCurrentPage(e.currentTarget.value);
                  }}
                >
                  {paginationArry.map((i) => {
                    return (
                      <option key={i} value={i}>
                        {i + 1}
                      </option>
                    );
                  })}
                </SelectField>
                <Text
                  fontFamily="Inter"
                  fontSize="16px"
                  fontWeight="300"
                  lineHeight="20px"
                  letterSpacing="1px"
                  marginTop={5}
                  children={` ${Strings.OF} ${pagination.pages}`}
                ></Text>
                <Button
                  variation="link"
                  marginTop={5}
                  onClick={() => {
                    if (currentPage !== pagination.pages - 1) {
                      setPrevPage(currentPage);
                      setCurrentPage(currentPage + 1);
                    }
                  }}
                >
                  <IconChevronRight />
                </Button>
              </Flex>
            </BlankOverviewBox>
          )}
        </Flex>
      </Flex>

      <ToastContainer />

      <Footer />
    </Flex>
  );
}
